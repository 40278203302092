

















































































































import mixins from 'vue-typed-mixins';
import { BaseMap } from '../../mixins/base-map';
import { GeneralBodyParts } from '@/state/x-ray/body-parts';

export default mixins(BaseMap).extend({
  data () {
    return { GeneralBodyParts };
  }
});
