









import Vue from 'vue';
import 'swiper/swiper-bundle.css';
import { commit, get } from 'vuex-pathify';
import { IImageModel } from '@/api/Models';
import ThumbnailList from '@/components/swiper/ThumbnailList.vue';

export default Vue.extend({
  components: {
    ThumbnailList
  },
  computed: {
    images: get<IImageModel[]>('study/currentModalityImages'),
    numOfPlaceholders (): number {
      return Math.max(4 - this.images.length, 0);
    }
  },
  methods: {
    onSelection (image: IImageModel): void {
      commit('study/setSelectedImage', image);
    }
  }
});
