







import Vue from 'vue';
import { get } from 'vuex-pathify';
import eventBus from '@/eventBus';
import rejectionReasonModal from './RejectionReasonModal.vue';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default Vue.extend({
  data () {
    return {
      rejectIcon: faTimes
    };
  },
  computed: {
    hasImage: get('study/selectedImage')
  },
  methods: {
    onClick () {
      eventBus.launchModal(rejectionReasonModal);
    }
  }
});
