




















import Vue from 'vue';
import jQuery from 'jquery';
import ImageMap from 'image-map';
import 'maphilight';
import DogMap from './DogMap.vue';
import CatMap from './CatMap.vue';
import HorseMap from './HorseMap.vue';
import CatTeethMap from './CatTeethMap.vue';
import DogTeethMap from './DogTeethMap.vue';
import HorseTeethMap from './HorseTeethMap.vue';

interface IHighlightJQuery extends JQuery<HTMLImageElement> {
  maphilight(options?: any): void;
}

const mapComponents = {
  CatMap,
  DogMap,
  HorseMap,
  DogTeethMap,
  CatTeethMap,
  HorseTeethMap
};

export default Vue.extend({
  components: mapComponents,
  props: {
    mapName: {
      type: String,
      required: true,
      validator: (name: string): boolean => {
        return Object.keys(mapComponents).indexOf(name) !== -1;
      }
    },
    onPartSelected: {
      type: Function,
      required: true
    },
    highlightTarget: {
      type: [String, Array],
      default: undefined
    }
  },
  data () {
    return {
      toolTipContent: 'Placeholder',
      showToolTip: false,
      mapHighlightOptions: {
        strokeColor: '0a7afa'
      }
    };
  },
  watch: {
    highlightTarget (target: string | number[]) {
      this.resetHighlights();
      if (target) {
        this.highlight();
      }
    }
  },
  mounted () {
    if (this.highlightTarget) {
      this.resetHighlights();
      this.highlight();
    }
    const image = jQuery('img[usemap]') as IHighlightJQuery;
    if (image.hasClass('maphilighted')) return;

    if (image.prop('complete')) {
      ImageMap('img[usemap]');
      image.maphilight(this.mapHighlightOptions);
    }
    // map resizing and map highlighting
    image.on('load', () => {
      if (image.hasClass('maphilighted')) return;

      ImageMap('img[usemap]');
      image.maphilight(this.mapHighlightOptions);
    });
  },
  methods: {
    highlight (): void {
      if (Array.isArray(this.highlightTarget)) {
        const targets = this.highlightTarget.map(target => jQuery(`.color-area[name="${target}"]`));
        targets.forEach(target => this.triggerHighlight(target));
      } else {
        const target = jQuery(`.color-area[name="${this.highlightTarget}"]`);
        this.triggerHighlight(target);
      }
    },
    triggerHighlight (target: JQuery<HTMLElement>) {
      const data = target.data('maphilight') || {};
      data.alwaysOn = true;
      target.data('maphilight', data).trigger('alwaysOn.maphilight');
    },
    resetHighlights (): void {
      jQuery('.color-area').each(function () {
        const data = jQuery(this).data('maphilight') || {};
        data.alwaysOn = false;
        jQuery(this).data('maphilight', data).trigger('alwaysOn.maphilight');
      });
    },
    onPartMouseOver (content: string): void {
      this.toolTipContent = content;
      this.showToolTip = true;
    },
    onPartMouseOut (): void {
      this.showToolTip = false;
    }
  }
});
