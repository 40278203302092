













































































































import mixins from 'vue-typed-mixins';
import VueSimpleContextMenu from 'vue-simple-context-menu';
import 'vue-simple-context-menu/dist/vue-simple-context-menu.css';
import { GeneralBodyParts, generalHorseParts, isArrayItem } from '@/state/x-ray/body-parts';
import { BaseMap } from '../../mixins/base-map';

interface IContextMenuElement extends Vue {
  showMenu(event: Event): void;
}

export default mixins(BaseMap).extend({
  components: {
    ContextMenu: VueSimpleContextMenu
  },
  data () {
    return {
      options: [] as { name: string }[],
      frontLegsName: '',
      backLegsName: '',
      GeneralBodyParts
    };
  },
  methods: {
    async onClick (part: string, event?: Event): Promise<void> {
      this.options = [];
      const entry = generalHorseParts.find(item => isArrayItem(item) ? item.region === part : item === part);
      if (!entry) {
        throw new Error('Missing entry');
      }
      if (!isArrayItem(entry)) {
        return await this.clickHandler(entry);
      }

      if (!event) {
        throw new Error('Missing click event');
      }
      // removes highlighting from the previously selected part
      this.$store.commit('xRay/setSelectedPart', undefined);

      this.options = entry.bodyParts.map(item => {
        return { name: item };
      });

      const contextMenu = this.$refs['context-menu'] as IContextMenuElement;
      contextMenu.showMenu(event);
    },
    optionClicked (option: any): void {
      const selectedOption = option.option.name;
      this.options = [];

      const selectedEntry = generalHorseParts.find(entry => {
        if (isArrayItem(entry)) {
          return entry.bodyParts.some(item => item === selectedOption);
        }
        return false;
      });

      if (!selectedEntry || !isArrayItem(selectedEntry)) {
        throw new Error('Selected option is not recognized as a body part');
      }

      switch (selectedEntry.region) {
        case GeneralBodyParts.FRONT_LEGS:
          this.frontLegsName = selectedOption;
          break;
        case GeneralBodyParts.BACK_LEGS:
          this.backLegsName = selectedOption;
          break;
        default:
          throw new Error('Name not supported');
      }

      this.$nextTick(() => this.clickHandler(selectedOption));
    }
  }
});
