




import Vue from 'vue';
import { commit, dispatch } from 'vuex-pathify';
import router from '@/router';
import { isAppLocal } from '@/helpers/config';

export default Vue.extend({
  methods: {
    onClick (): void {
      if (isAppLocal()) {
        dispatch('dicomServer/postDicomAutoSend');
      }
      commit('xRay/flush');
      router.go(-1);
    }
  }
});
