















import Vue from 'vue';
import { get, sync } from 'vuex-pathify';
import RadioButtons from '@/components/form/RadioButtons.vue';
import DropdownButton from '@/components/form/DropdownButton.vue';

export default Vue.extend({
  components: {
    DropdownButton,
    RadioButtons
  },
  computed: {
    selectedView: sync('xRay/selectedView'),
    views: get<string[]>('xRay/generalViews'),
    showExtraViewsDropdown (): boolean {
      return this.views.length > 3;
    },
    baseViews (): string[] {
      return this.views.slice(0, 3);
    },
    extraViews (): string[] {
      return this.views.slice(3);
    }
  }
});
