






import { IImageModel } from '@/api/Models';
import Vue from 'vue';
import { commit, dispatch, get } from 'vuex-pathify';

export default Vue.extend({
  computed: {
    sensorAvailable: get<boolean>('sensor/sensorAvailable'),
    generatorAvailable: get<boolean>('generator/generatorAvailable'),
    images: get<IImageModel[]>('study/currentModalityImages')
  },
  async created () {
    this.$store.commit('xRay/flush');
    const studyId = this.$route.query.studyId as string | null;

    if (!studyId) throw new Error('Unable to load study');

    await dispatch('xRay/setOwnerAndPatientByStudyId', parseInt(studyId));
    await dispatch('xRay/fetchStudy', parseInt(studyId));
    commit('study/setSelectedImage', this.images[0]);
  },
  destroyed () {
    commit('xRay/flush');
  }
});
