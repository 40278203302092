import FMSAxios from '@/api/FMSAxios';

export async function getImageJpegData (imageId: number): Promise<string> {
  const response = await FMSAxios.get<Blob>(`image/${imageId}.jpg`, {
    responseType: 'blob'
  });
  return URL.createObjectURL(response.data);
}
export async function getCaseImageJpegData (imageId: number): Promise<string> {
  const response = await FMSAxios.get<Blob>(`/case/image/${imageId}.jpg`, {
    responseType: 'blob'
  });
  return URL.createObjectURL(response.data);
}
export function createStudyDate (date: Date): string {
  const month = padDateNumber(date.getMonth() + 1);
  const day = padDateNumber(date.getDate());
  const minute = padDateNumber(date.getMinutes());
  return `${date.getFullYear()}-${month}-${day} ${date.getHours()}:${minute}`;
}

export function padDateNumber (dateNumber: number): string {
  return dateNumber < 10 ? `0${dateNumber}` : dateNumber.toString();
}
