








































import Vue from 'vue';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import eventBus, { ModalEventNames } from '@/eventBus';
import SelectAllButton from './SelectAllButton.vue';
import ImageSelection from './ImageSelection.vue';
import ModalFrame from './ModalFrame.vue';
export default Vue.extend({
  components: {
    ImageSelection,
    SelectAllButton,
    ModalFrame
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    heading: {
      type: String,
      required: true
    },
    errorMessage: {
      type: String,
      default: ''
    },
    onSelection: {
      type: Function,
      default: () => undefined
    },
    isConfirmDisabled: {
      type: Boolean,
      default: false
    },
    onConfirm: {
      type: Function,
      required: true
    },
    confirmationButtonText: {
      type: String,
      default: 'Send'
    },
    allowMulti: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      faTimes
    };
  },
  methods: {
    onCloseModalClick (): void {
      eventBus.$emit(ModalEventNames.closeModal);
    },
    onInput (imageIds: number[]) {
      this.$emit('input', imageIds);
    }
  }
});
