import Vue from 'vue';
import { Module, Store } from 'vuex';
import vuexStore, { IRootState } from '@/state';
import FMSAxios from '@/api/FMSAxios';
import { ISensorModel, ISensorInfo, Modality, SensorState, ISensorStatus } from '@/api/Models';
import { once } from 'lodash';
import { ConnectionStatus, SwalTitles } from '../enums';
import { openEventSource } from '../eventSourceState';
import { commit, dispatch, make } from 'vuex-pathify';
import axios from 'axios';
import * as Sentry from '@sentry/vue';

export interface ISensorState {
  sensor?: ISensorModel;
  sensorState?: SensorState;
  sensorStatus?: ISensorStatus;
  sensorSource?: EventSource;
  sensorError?: string;
  readyState: number;
}

const _state: ISensorState = {
  sensor: undefined,
  sensorState: undefined,
  sensorStatus: undefined,
  sensorSource: undefined,
  sensorError: undefined,
  readyState: EventSource.CLOSED
};

const sensor: Module<ISensorState, IRootState> = {
  state: _state,
  getters: {
    getSensorAvailable (state): boolean {
      if (state.sensorState === SensorState.NotAvailable || state.readyState !== ConnectionStatus.OPEN) return false;
      return true;
    },
    getRoot (state: ISensorState): string {
      if (!state.sensor) {
        return '';
      }
      const { port, serverIp } = state.sensor;
      return `http://${serverIp}:${port}`;
    },
    getUrl (state: ISensorState): string {
      if (!state.sensor) {
        return '';
      }
      const { port, serverIp, brand } = state.sensor;
      return `http://${serverIp}:${port}/${brand}`;
    }
  },
  mutations: {
    ...make.mutations(_state)
  },
  actions: {
    initialize: once(async (context, store: Store<IRootState>) => {
      await dispatch('sensor/fetchSensors');

      if (store.get('sensor/sensor')) {
        await dispatch('sensor/openConnection');
        dispatch('sensor/fetchSensorInfo');
      }

      store.watch(state => {
        return {
          readyState: state.sensor.readyState,
          sensorState: state.sensor.sensorState
        };
      }, (newState) => {
        if (newState.readyState === EventSource.CLOSED) {
          commit('sensor/setSensorState', SensorState.NotAvailable);
        }
      });
    }),
    async fetchSensors (): Promise<void> {
      const response = await FMSAxios.get('sensor/local');
      const sensorData: ISensorModel = response.data;

      if (sensorData) {
        commit('sensor/setSensor', sensorData);
        Sentry.setTag('modality', sensorData.modality);
      } else {
        Sentry.setTag('modality', 'None');
      }
    },
    openConnection () {
      const sensorSource: EventSource = vuexStore.get('sensor/sensorSource');
      const url = vuexStore.get('sensor/url');

      if (sensorSource === undefined) {
        openEventSource(`${url}/events`, 'sensor/setSensorSource', 'sensor/setReadyState', event => {
          commit('sensor/setSensorState', event.data);
        }, () => commit('sensor/setSensorStatus', undefined));
      }
    },
    closeConnection () {
      const sensorSource: EventSource = vuexStore.get('sensor/sensorSource');

      if (sensorSource) {
        sensorSource.close();
        commit('sensor/setSensorSource', undefined);
        commit('sensor/setSensorState', undefined);
        commit('sensor/setSensorStatus', undefined);
        commit('sensor/setReadyState', ConnectionStatus.CLOSED);
      }
    },
    async validateSensor (context, destinationModality: Modality): Promise<void> {
      await dispatch('sensor/fetchSensorInfo');

      let message = '';

      const { sensor: _sensor, sensorState, readyState } = vuexStore.get('sensor');

      if (!_sensor) {
        message = 'No sensor has been registered with the current machine';
      } else if (_sensor.modality !== destinationModality) {
        message = `${destinationModality} XRay is not supported on the current machine`;
      } else if (sensorState !== SensorState.Ready) {
        message = `Unable to connect to ${_sensor.brand} ${_sensor.model}. State ${sensorState}`;
      } else if (readyState !== EventSource.OPEN) {
        message = `${_sensor.brand} ${_sensor.model} is currently unavailable`;
      }

      if (!message) return;

      console.error(message);

      await Vue.swal.fire({
        icon: 'error',
        title: SwalTitles.SensorConnectionError,
        text: message
      });
    },
    async fetchSensorInfo (): Promise<ISensorInfo> {
      const url = vuexStore.get('sensor/url');
      const response = await axios.get(`${url}/status`);

      commit('sensor/setSensorState', response?.data.status.state);
      commit('sensor/setSensorStatus', response?.data.status);

      return response.data;
    }
  },
  namespaced: true
};

export default sensor;
