























import Vue from 'vue';
import PageTemplate from '@/components/page-template/PageTemplate.vue';
import XRayButton from '@/components/study-table/XRayButton.vue';
import Table from './components/Table.vue';
import SelectOwner from './components/owner/SelectOwner.vue';
import SelectPatient from './components/patient/SelectPatient.vue';
import RefreshButton from '@/components/study-table/RefreshButton.vue';

export default Vue.extend({
  components: {
    PageTemplate,
    Table,
    SelectOwner,
    SelectPatient,
    XRayButton,
    RefreshButton
  }
});
