var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalFrame',{staticClass:"dicom-dump",attrs:{"heading":"DICOM Dump"}},[_c('VueGoodTable',{attrs:{"style-class":"study-table","rows":_vm.tags,"columns":_vm.columns,"search-options":{
      enabled: true,
    },"pagination-options":{
      enabled: true,
      mode: 'pages',
      perPageDropdown: [10],
      dropdownAllowAll: false,
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }