




































import Vue from 'vue';
import { dispatch, get } from 'vuex-pathify';
import { EntityStatus, IClinicModel, IPermissionProfileModel, IUserModel } from '@/api/Models';
import { formatDateShort } from '@/helpers/date';
import TableList from './TableList.vue';

import EditUserModal from './EditUserModal.vue';
import eventBus, { ModalEventNames } from '@/eventBus';
import vuexStore from '@/state';
import EditUserPermissionModal from './EditUserPermissionModal.vue';

function formatClinicId (clinicId: number): string | undefined {
  const clinics: IClinicModel[] = vuexStore.get('clinic/clinics');
  const clinic = clinics.find(item => item.id === clinicId);
  return clinic?.name;
}

export default Vue.extend({
  components: {
    TableList
  },
  data () {
    return {
      columns: [
        { label: 'ID', field: 'id', hidden: true },
        { label: 'Username', field: 'username' },
        { label: 'First Name', field: 'firstName' },
        { label: 'Last Name', field: 'lastName' },
        { label: 'Registration Date', field: 'createdOn' },
        { label: 'Clinic', field: 'clinicId', formatFn: formatClinicId }
      ],
      selectedUser: undefined as undefined | IUserModel,
      selectedUserProfile: undefined as undefined | IPermissionProfileModel
    };
  },
  computed: {
    users: get<IUserModel[]>('user/users'),
    statusToggleLabel (): string {
      return this.selectedUserProfile?.status === EntityStatus.Active ? 'Disable' : 'Enable';
    },
    isSelectedRoot (): boolean {
      return !!this.selectedUserProfile?.root;
    },
    isUserLoaded (): boolean {
      return !!this.selectedUser && !!this.selectedUserProfile;
    }
  },
  watch: {
    selectedUser (newUser: IUserModel | undefined) {
      if (newUser) {
        this.updatesSelectedProfile(newUser.permissionProfileId);
      }
    }
  },
  created () {
    dispatch('user/fetchAllUsers');
  },
  methods: {
    createRow (user: IUserModel): any {
      return { createdOn: formatDateShort(user.createdOn as Date) };
    },
    async updatesSelectedProfile (profileId: number) {
      const permission: IPermissionProfileModel = await dispatch('permissionProfile/fetchProfile', profileId);
      this.selectedUserProfile = permission;
    },
    onCreateClick (): void {
      eventBus.launchModal(EditUserModal);
    },
    onEditClick (): void {
      eventBus.launchModal(EditUserModal, { user: this.selectedUser });
    },
    onEditPermissionsClick (): void {
      eventBus.$once(ModalEventNames.closeModal, () => {
        this.updatesSelectedProfile(this.selectedUserProfile?.id as number);
      });
      eventBus.launchModal(EditUserPermissionModal, { profile: this.selectedUserProfile });
    },
    async onStatusToggleClick (): Promise<void> {
      const profile = this.selectedUserProfile as IPermissionProfileModel;
      profile.status = profile.status === EntityStatus.Active ? EntityStatus.Inactive : EntityStatus.Active;

      await dispatch('permissionProfile/putProfile', profile);
      this.updatesSelectedProfile(profile.id as number);
    }
  }
});
