/* eslint-disable @typescript-eslint/no-explicit-any */
import { importInternal, external, toolStyle, getModule } from 'cornerstone-tools';
import { getColor } from '../utils/getColor';

// Drawing
const getNewContext = importInternal('drawing/getNewContext');
const draw = importInternal('drawing/draw');
const setShadow = importInternal('drawing/setShadow');
const drawCircle = importInternal('drawing/drawCircle');
const drawLinkedTextBox = importInternal('drawing/drawLinkedTextBox');
const drawHandles = importInternal('drawing/drawHandles');
// Utility
const getROITextBoxCoords = importInternal('util/getROITextBoxCoords');

function renderDataCircle (eventData: any, data: any, config: any): void {
  const { element } = eventData;

  const {
    handleRadius,
    hideHandlesIfMoving,
    renderDashed
  } = config;

  const getDistance = external.cornerstoneMath.point.distance;
  const lineWidth = toolStyle.getToolWidth();
  const lineDash = getModule('globalConfiguration').configuration.lineDash;
  const context = getNewContext(eventData.canvasContext.canvas);

  draw(context, (canvasContext: CanvasRenderingContext2D) => {
    if (data.visible === false) {
      return;
    }

    // Configure
    const color = getColor(data);
    const handleOptions = {
      color,
      handleRadius,
      // hard-coding this to false because there's no way yet to set a configuration that only applies to certain shapes
      drawHandlesIfActive: false,
      hideHandlesIfMoving
    };

    setShadow(canvasContext, config);

    const circleOptions: any = { color };
    if (renderDashed) {
      circleOptions.lineDash = lineDash;
    }

    const startCanvas = external.cornerstone.pixelToCanvas(
      element,
      data.handles.start
    );
    const endCanvas = external.cornerstone.pixelToCanvas(
      element,
      data.handles.end
    );
    // Calculating the radius where startCanvas is the center of the circle to be drawn
    const radius = getDistance(startCanvas, endCanvas);

    // Draw Circle
    drawCircle(
      canvasContext,
      element,
      data.handles.start,
      radius,
      circleOptions,
      'pixel'
    );
    // only need to draw the end handle
    if (!data.static) {
      drawHandles(canvasContext, eventData, [data.handles.end], handleOptions);
    }

    // Default to textbox on right side of ROI
    if (!data.handles.textBox.hasMoved) {
      const defaultCoords = getROITextBoxCoords(
        eventData.viewport,
        data.handles
      );

      Object.assign(data.handles.textBox, defaultCoords);
    }

    function textBoxAnchorPoints (handles: any): void {
      _findTextBoxAnchorPoints(handles.start, handles.end);
    }

    const text = `${data.toolType} ${data.step}`;

    drawLinkedTextBox(
      canvasContext,
      element,
      data.handles.textBox,
      text,
      data.handles,
      textBoxAnchorPoints,
      color,
      lineWidth,
      10,
      true
    );
  });
}

function getCircleCoords (startHandle: any, endHandle: any): any {
  const { distance } = external.cornerstoneMath.point;
  const radius = distance(startHandle, endHandle);

  return {
    left: Math.floor(Math.min(startHandle.x - radius, endHandle.x)),
    top: Math.floor(Math.min(startHandle.y - radius, endHandle.y)),
    width: radius * 2,
    height: radius * 2
  };
}

function _findTextBoxAnchorPoints (startHandle: any, endHandle: any): any {
  const { left, top, width, height } = getCircleCoords(startHandle, endHandle);

  return [
    {
      // Top middle point of ellipse
      x: left + width / 2,
      y: top
    },
    {
      // Left middle point of ellipse
      x: left,
      y: top + height / 2
    },
    {
      // Bottom middle point of ellipse
      x: left + width / 2,
      y: top + height
    },
    {
      // Right middle point of ellipse
      x: left + width,
      y: top + height / 2
    }
  ];
}

export default renderDataCircle;
