import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';

export default Extension.create({
  addProseMirrorPlugins () {
    return [
      new Plugin({
        key: new PluginKey('overflow-limiter'),
        props: {
          handleKeyDown: (view, event): boolean => {
            if (event.key !== 'Enter') return false;
            // use elements that appear after the current selection
            const children = Array.from(view.dom.children)
              .filter(child => {
                const pos = view.posAtDOM(child, 0);
                return pos > view.state.selection.$to.pos;
              });

            const lastChildWithTextIndex = children.findIndex(child => !!child.textContent);
            const childrenWithoutText = children.slice(lastChildWithTextIndex + 1);
            if (childrenWithoutText.length < 1) return false;

            const startPos = view.posAtDOM(childrenWithoutText[0], 0) - 1;
            const endPos = view.posAtDOM(childrenWithoutText.reverse()[0], 0) + 1;

            const transaction = view.state.tr.delete(startPos, endPos);
            const newState = view.state.applyTransaction(transaction);
            view.updateState(newState.state);

            return false;
          }
        }
      })
    ];
  }
});
