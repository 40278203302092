/* eslint-disable @typescript-eslint/no-explicit-any */
import { external } from 'cornerstone-tools';
import { getLineVector } from '../../math';
import getBaseData from '../getBaseData';
import getDistanceWithPixelSpacing from '../../getDistanceWithPixelSpacing';
import updatePerpendicularLine from './updatePerpendicularLine';
import getDirectionMultiplier from './getDirectionMultiplier';

export default function movePerpendicularLine (
  proposedPoint: any,
  measurementData: any,
  eventData: any,
  fixedPoint: any
): any {
  const { lineSegment } = external.cornerstoneMath;
  const baseData = getBaseData(measurementData, eventData, fixedPoint);
  const {
    columnPixelSpacing,
    rowPixelSpacing,
    start,
    longLine,
    intersection
  } = baseData;

  // Stop here if the long line has no length
  if (!lineHasLength(columnPixelSpacing, rowPixelSpacing, longLine)) {
    return false;
  }

  // Inclination of the perpendicular line
  const vector = getLineVector(
    columnPixelSpacing,
    rowPixelSpacing,
    start,
    intersection
  );

  // Get a helper line to calculate the intersection
  const helperLine = getHelperLine(baseData, proposedPoint, vector);

  // Find the new intersection in the long line
  const newIntersection = lineSegment.intersectLine(longLine, helperLine);

  // Stop the flow here if there's no intersection point between lines
  if (!newIntersection) {
    return false;
  }

  // Calculate and the new position of the perpendicular handles
  const newLine = updatePerpendicularLine(
    baseData,
    newIntersection,
    helperLine,
    vector
  );

  // Change the position of the perpendicular line handles
  measurementData.handles.perpendicularStart.x = newLine.start.x;
  measurementData.handles.perpendicularStart.y = newLine.start.y;
  measurementData.handles.perpendicularEnd.x = newLine.end.x;
  measurementData.handles.perpendicularEnd.y = newLine.end.y;

  return true;
}

function lineHasLength (
  columnPixelSpacing: number,
  rowPixelSpacing: number,
  line: any
): any {
  const lineLength = getDistanceWithPixelSpacing(
    columnPixelSpacing,
    rowPixelSpacing,
    line.start,
    line.end
  );

  return lineLength !== 0;
}

function getHelperLine (baseData: any, proposedPoint: any, vector: any): any {
  const {
    columnPixelSpacing,
    rowPixelSpacing,
    perpendicularEnd,
    fixedPoint
  } = baseData;

  // Create a helper line to find the intesection point in the long line
  const highNumber = Number.MAX_SAFE_INTEGER;

  // Get the multiplier
  const multiplier =
    getDirectionMultiplier(fixedPoint, perpendicularEnd) * highNumber;

  return {
    start: proposedPoint,
    end: {
      x: proposedPoint.x + vector.y * rowPixelSpacing * multiplier,
      y: proposedPoint.y + vector.x * columnPixelSpacing * multiplier * -1
    }
  };
}

