import { Species } from '@/api/Models';

const baseViews: string[] = ['VD', 'DV', 'LAT'];

type SpeciesViewsMap = {
  [species in Species]: string[];
};

const speciesViewsMap: SpeciesViewsMap = {
  Canine: baseViews,
  Feline: baseViews,
  Equine: [...baseViews, 'LM', 'DLMPO', 'DMPLO', 'DP', 'PA', 'PA55PR_PADIO', 'D65PR_PADIO', 'CD30L_CRMO']
};

export default speciesViewsMap;
