// need to do this, because the config need to be loaded
// before any of the state modules

import pathify from 'vuex-pathify';

pathify.options.mapping = function (type, name, formatters) {
  switch (type) {
    case 'mutations':
      return formatters.camel('set', name); // setFoo
    case 'actions':
      return formatters.camel('set', name); // setFoo
    case 'getters':
      return formatters.camel('get', name); // getFoo
    default:
      return name; // foo
  }
};

pathify.options.deep = 2;
pathify.debug();

export default pathify;
