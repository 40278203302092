import { Species } from '@/api/Models';
import { isString } from 'lodash';

type MixedBodyPartListArrayItem = { region: string; bodyParts: string[] };
type MixedBodyPartListItem = string | MixedBodyPartListArrayItem;

type MixedBodyPartList = MixedBodyPartListItem[];

export enum GeneralBodyParts {
  SKULL = 'Skull',
  CERVICAL = 'Cervical',
  SCAPULA = 'Scapula',
  CHEST = 'Chest',
  FRONT_LEGS = 'Front Legs',
  ABDOMEN = 'Abdomen',
  BACK_LEGS = 'Back Legs',
  VERTEBRAE = 'Vertebrae',
  SACRAL_VERTEBRAE = 'Sacral Vertebrae',
  CAUDAL_VERTEBRAE = 'Caudal Vertebrae'
}

export const generalHorseParts: MixedBodyPartList = [
  GeneralBodyParts.SKULL,
  GeneralBodyParts.CERVICAL,
  GeneralBodyParts.SCAPULA,
  GeneralBodyParts.CHEST,
  {
    region: GeneralBodyParts.FRONT_LEGS,
    bodyParts: [
      'Left Forefoot',
      'Right Forefoot',
      'Right Carpus',
      'Left Carpus',
      'Right Front Fetlock',
      'Left Front Fetlock',
      'Right Navicular of Forelimb',
      'Left Navicular of Forelimb'
    ]
  },
  GeneralBodyParts.ABDOMEN,
  {
    region: GeneralBodyParts.BACK_LEGS,
    bodyParts: [
      'Right Stifle',
      'Left Stifle',
      'Right Hind Fetlock',
      'Left Hind Fetlock',
      'Right Tarsus',
      'Left Tarsus'
    ]
  },
  GeneralBodyParts.VERTEBRAE,
  GeneralBodyParts.SACRAL_VERTEBRAE,
  GeneralBodyParts.CAUDAL_VERTEBRAE
];

export enum HorseTeeth {
  RU_INCISORS_101_102_103 = '101,102,103 - RU - Incisors',
  LU_Incisors_201_202_203 = '201,202,203 - LU - Incisors',
  RU_Canine_104 = '104 - RU - Canine',
  LU_Wolf_Tooth_205 = '205 - LU - Wolf Tooth',
  LU_Canine_204 = '204 - LU - Canine',
  RU_Wolf_Tooth_105= '105 - RU - Wolf Tooth',
  RU_Premolars_105_106_107_108= '105,106,107,108 - RU - Premolars',
  RU_Molars_109_110_111= '109,110,111 - RU - Molars',
  LU_Premolars_205_206_207_208= '205,206,207,208 - LU - Premolars',
  LU_Molars_209_210_211= '209,210,211 - LU - Molars',
  RL_Molars_409_410_411= '409,410,411 - RL - Molars',
  RL_Premolars_405_406_407_408= '405,406,407,408 - RL - Premolars',
  RL_Wolf_Tooth_405= '405 - RL - Wolf Tooth',
  LL_Molars_309_310_311= '309,310,311 - LL - Molars',
  LL_Premolars_305_306_307_308= '305,306,307,308 - LL - Premolars',
  LL_Wolf_Tooth_305= '305 - LL - Wolf Tooth',
  RL_Canine_404= '404 - RL - Canine',
  RL_Incisors_401_402_403= '401,402,403 - RL - Incisors',
  LL_Incisors_301_302_303= '301,302,303 - LL - Incisors',
  LL_Canine_304= '304 - LL - Canine'
}

export enum CatTeeth {
  RU_Incisors_101_102_103 = '101,102,103 - RU - Incisors',
  LU_Incisors_201_202_203 = '201,202,203 - LU - Incisors',
  RU_Canine_104 = '104 - RU - Canine',
  LU_Canine_204 = '204 - LU - Canine',
  RU_Premolars_106_107_108 = '106,107,108 - RU - Premolars',
  RU_Molar_109 = '109 - RU - Molar',
  LU_Premolars_206_207_208 = '206,207,208 - LU - Premolars',
  LU_Molar_209 = '209 - LU - Molar',
  RL_Molar_409 = '409 - RL - Molar',
  RL_Premolars_407_408 = '407,408 - RL - Premolars',
  LL_Molar_309 = '309 - LL - Molar',
  LL_Premolars_307_308 = '307,308 - LL - Premolars',
  RL_Canine_404 = '404 - RL - Canine',
  RL_Incisors_401_402_403 = '401,402,403 - RL - Incisors',
  LL_Incisors_301_302_303 = '301,302,303 - LL - Incisors',
  LL_Canine_304 ='304 - LL - Canine'
}

export enum DogTeeth {
  RU_Incisors_101_102_103 = '101,102,103 - RU - Incisors',
  LU_Incisors_201_202_203 = '201,202,203 - LU - Incisors',
  RU_Canine_104 = '104 - RU - Canine',
  LU_Canine_204 = '204 - LU - Canine',
  RU_Premolars_105_106_107_108 = '105,106,107,108 - RU - Premolars',
  RU_Molars_109_110 = '109,110 - RU - Molars',
  LU_Premolars_205_206_207_208 = '205,206,207,208 - LU - Premolars',
  LU_Molars_209_210 = '209,210 - LU - Molars',
  RL_Molars_409_410_411 = '409,410,411 - RL - Molars',
  RL_Premolars_405_406_407_408 = '405,406,407,408 - RL - Premolars',
  LL_Molars_309_310_311 = '309,310,311 - LL - Molars',
  LL_Premolars_305_306_307_308 = '305,306,307,308 - LL - Premolars',
  Canine_404_RL = '404 - RL - Canine',
  RL_Incisors_401_402_403 = '401,402,403 - RL - Incisors',
  LL_Incisors_301_302_303 = '301,302,303 - LL - Incisors',
  LL_Canine_304 = '304 - LL - Canine'
}

export function getDentalParts (species: Species): string[] {
  switch (species) {
    case Species.Canine:
      return Object.values(DogTeeth);
    case Species.Equine:
      return Object.values(HorseTeeth);
    case Species.Feline:
      return Object.values(CatTeeth);
    default:
      throw new Error('Species has no teeth dictionary');
  }
}

export function getGeneralParts (species: Species): string[] {
  switch (species) {
    case Species.Canine:
    case Species.Feline:
      return Object.values(GeneralBodyParts);
    case Species.Equine:
      return mapMixedList(generalHorseParts);
    default:
      throw new Error('Species has no teeth dictionary');
  }
}

function mapMixedList (list: MixedBodyPartList): string[] {
  let bodyParts: string[] = [];

  list.forEach(item => {
    if (typeof item === 'string') {
      bodyParts.push(item);
    } else if (isArrayItem(item)) {
      bodyParts = [...bodyParts, ...item.bodyParts];
    } else {
      throw new Error('Error mapping list');
    }
  });
  return bodyParts;
}

export function isArrayItem (value: MixedBodyPartListItem): value is MixedBodyPartListArrayItem {
  return !isString(value) && Array.isArray(value.bodyParts) && isString(value.region);
}
