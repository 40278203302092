





















import Vue from 'vue';
import { get, dispatch } from 'vuex-pathify';
import { faRadiation, faExclamationTriangle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { SensorState } from '@/api/Models';
import { CaptureStatus } from '@/state/x-ray/enums';

export default Vue.extend({
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      buttonClass: undefined as string | undefined,
      buttonIcon: undefined as IconDefinition | undefined,
      buttonText: undefined as string | undefined,
      faRadiation,
      faExclamationTriangle
    };
  },
  computed: {
    xRay: get('xRay'),
    sensorState: get('sensor/sensorState'),
    generatorAvailable: get('generator/generatorAvailable'),
    inProgress: get('xRay/cancelationToken'),
    loopStarted: get('xRay/loopStarted'),
    anyAnnotations (): boolean {
      return this.xRay.annotations?.length > 0 && !!this.xRay.selectedAnnotation;
    },
    isDisabled (): boolean {
      const disabledState = [SensorState.NotAvailable, SensorState.PostAcquisitionCleanup, undefined];
      const isDisabledState = disabledState.some(state => this.sensorState === state);

      return !this.anyAnnotations || isDisabledState || this.disabled;
    }
  },
  watch: {
    async sensorState (): Promise<void> {
      this.updateButtonProperties();
      await dispatch('xRay/ensureProcessingModal');
    },
    loopStarted (): void {
      this.updateButtonProperties();
    }
  },
  beforeDestroy () {
    return dispatch('xRay/stopXRay');
  },
  created () {
    this.updateButtonProperties();
  },
  methods: {
    async onClick (): Promise<void> {
      if (!this.inProgress && this.sensorState === SensorState.Ready) {
        await dispatch('xRay/startXRay');
      } else {
        await dispatch('xRay/launchCancelXRayModal');
      }
    },
    updateButtonProperties (): void {
      switch (this.sensorState) {
        case SensorState.Ready:
        case SensorState.RadiationReceived:
        case SensorState.LoadingImage:
        case SensorState.PostAcquisitionCleanup:
          this.buttonIcon = !this.loopStarted ? faRadiation : undefined;
          this.buttonClass = 'btn-secondary';
          this.buttonText = !this.loopStarted ? CaptureStatus.XRay : CaptureStatus.Preparing;
          break;
        case SensorState.WaitingForRadiation:
          this.buttonIcon = faRadiation;
          this.buttonClass = 'btn-success';
          this.buttonText = CaptureStatus.WaitingForRadiation;
          break;
        case SensorState.PreparingSensor:
          this.buttonIcon = undefined;
          this.buttonClass = 'btn-secondary';
          this.buttonText = CaptureStatus.Preparing;
          break;
        default:
          this.buttonIcon = faExclamationTriangle;
          this.buttonClass = 'btn-secondary';
          this.buttonText = CaptureStatus.SensorUnavailable;
      }
    }
  }
});
