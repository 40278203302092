











































import Vue from 'vue';
import { get, dispatch } from 'vuex-pathify';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import FinishButton from '@/components/x-ray/FinishButton.vue';

import DeviceStatusButton from '@/components/x-ray/DeviceStatusButton.vue';
import StudyInfo from '@/components/x-ray/StudyInfo.vue';
import GeneratorConfigBox from './GeneratorConfigBox.vue';
import FoStatusBox from './FoStatusBox.vue';

export default Vue.extend({
  components: {
    FinishButton,
    StudyInfo,
    GeneratorConfigBox,
    DeviceStatusButton,
    FoStatusBox
  },
  data () {
    return {
      saveIcon: faSave
    };
  },
  computed: {
    generatorAvailable: get('generator/generatorAvailable'),
    generatorDisabled: get('generator/generatorDisabled'),
    selectedAnnotation: get('xRay/selectedAnnotation'),
    generatorStatusButtonsClass (): string {
      return this.generatorDisabled ? 'device-status-buttons-without-gen' : 'device-status-buttons';
    },
    isSaveDisabled (): boolean {
      if (this.generatorDisabled && this.selectedAnnotation) return false;
      else if (this.generatorDisabled && !this.selectedAnnotation) return true;

      return !this.generatorAvailable || !this.selectedAnnotation;
    }
  },
  methods: {
    onSaveClick (): Promise<void> {
      return dispatch('generator/saveConfig');
    }
  }
});
