import Vue from 'vue';
import { commit, dispatch, get } from 'vuex-pathify';
import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';

import { ClinicType, IPatientModel, IPetOwnerModel, IStudieModel } from '@/api/Models';
import { dateSort, formatDateTime } from '@/helpers/date';
import { createStudyDate } from '@/state/study/helpers';

interface IBaseRow {
  date: string;
  studyId: number;
  patientId: string;
  patientDatabaseId?: number;
  name: string;
  ownerName: string;
}

export const BaseStudyTable = Vue.extend({
  components: {
    VueGoodTable
  },
  computed: {
    owners: get<IPetOwnerModel[]>('owner/owners'),
    patients: get<IPatientModel[]>('patient/patients'),
    selectedStudy: get<IStudieModel | undefined>('study/selectedStudy'),
    clinicType: get<ClinicType>('clinic/clinic@clinicType'),
    baseColumns (): any[] {
      const veterinaryColumns = [
        { label: 'Owner', field: 'ownerName' }
      ];

      return [
        { label: 'Study ID', field: 'studyId', hidden: true },
        { label: 'Patient Database ID', field: 'patientDatabaseId', hidden: true },
        { label: 'Patient ID', field: 'patientId', type: 'number', sortable: false, thClass: 'vgt-left-align', tdClass: 'vgt-left-align' },
        { label: 'Patient Name', field: 'name' },
        { label: 'Study Date', field: 'date', formatFn: formatDateTime, sortFn: dateSort },
        { label: 'RAD', field: 'radStatus' },

        ...this.clinicType === ClinicType.Veterinary ? veterinaryColumns : []
      ];
    }
  },
  methods: {
    createBaseRows (studies: IStudieModel[]): IBaseRow[] {
      return studies.map((study: IStudieModel) => {
        const patient = this.patients.find((p) => study.patientId === p.id);
        const owner = this.owners.find((o: IPetOwnerModel) => patient?.ownerId === o.id);
        const date = createStudyDate(new Date(study.createdOn as Date));

        return {
          date,
          studyId: study.id as number,
          patientId: patient?.patientId || 'N/A',
          patientDatabaseId: patient?.id,
          name: patient?.name || 'N/A',
          ownerName: owner?.name || 'N/A',
          radStatus: 'N/A'
        };
      });
    },
    rowStyleClass (row: any): string {
      return this.selectedStudy?.id === row.studyId ? 'active' : '';
    },
    async onRowClick (studies: IStudieModel[], rowParams: any): Promise<void> {
      if (rowParams.row.ownerId) {
        await dispatch('owner/setOwnerById', rowParams.row.ownerId);
      }

      if (rowParams.row.patientDatabaseId) {
        await dispatch('patient/fetchPatient', rowParams.row.patientDatabaseId);
      }

      const studyId = rowParams.row.studyId;
      commit('study/setSelectedStudy', studies.find((study: IStudieModel) => study.id === studyId));
    }
  }
});
