import cornerstoneTools from 'cornerstone-tools';

import VHSTool from './custom-tools/vhs';
import TPLOTool from './custom-tools/tplo';
import TTATool from './custom-tools/tta';
import CropTool from './custom-tools/crop';
import { SwalTitles } from '@/state/enums';
import { createArrowAnnotateCallback } from './helpers';

export const allCornerstoneTools = [
  { Tool: cornerstoneTools.AngleTool },
  {
    Tool: cornerstoneTools.ArrowAnnotateTool,
    options: {
      configuration: {
        allowEmptyLabel: true,
        getTextCallback: createArrowAnnotateCallback(SwalTitles.EnterComment),
        changeTextCallback: createArrowAnnotateCallback(SwalTitles.EditComment)
      }
    }
  },
  { Tool: cornerstoneTools.BidirectionalTool },
  { Tool: cornerstoneTools.LengthTool },
  { Tool: cornerstoneTools.FreehandRoiTool },
  { Tool: cornerstoneTools.EllipticalRoiTool },
  { Tool: cornerstoneTools.RectangleRoiTool },
  { Tool: cornerstoneTools.PanTool },
  { Tool: cornerstoneTools.WwwcTool },
  { Tool: cornerstoneTools.WwwcRegionTool },
  { Tool: cornerstoneTools.MagnifyTool },
  { Tool: cornerstoneTools.EraserTool },
  {
    Tool: cornerstoneTools.ZoomTool,
    options: {
      configuration: {
        invert: false,
        preventZoomOutsideImage: false,
        minScale: 0.1,
        maxScale: 20.0
      }
    }
  },
  { Tool: VHSTool },
  { Tool: TPLOTool },
  { Tool: TTATool },
  { Tool: CropTool },
  { Tool: cornerstoneTools.TextMarkerTool },
  { Tool: cornerstoneTools.CobbAngleTool },
  { Tool: cornerstoneTools.ScaleOverlayTool }
];
