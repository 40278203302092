























import Vue from 'vue';
import { get } from 'vuex-pathify';

import { isAppCloud, isDevelopment } from '@/helpers/config';
import EditEmail from './components/EditEmail.vue';
import DicomInfo from './components/DicomInfo.vue';
import EditClinic from './components/EditClinic.vue';
import UserList from './components/lists/UserList.vue';
import ClinicList from './components/lists/ClinicList.vue';
import BulkDownload from './components/BulkDownload.vue';
import EditDicomServers from './components/EditDicomServers.vue';
import PageTemplate from '@/components/page-template/PageTemplate.vue';
import { IEmailConfigModel, IPermissionProfileModel } from '@/api/Models';

const formComponents = {
  BulkDownload,
  EditClinic,
  EditEmail,
  EditDicomServers,
  ClinicList,
  UserList,
  DicomInfo
};

const cloudRootSettings = [
  { label: 'All Clinics', component: 'ClinicList' },
  { label: 'Users', component: 'UserList' }
];
const cloudSettings = [
  { label: 'Dicom Info', component: 'DicomInfo' }
];
const bulkDownloadSettings = [
  { label: 'Bulk Download', component: 'BulkDownload' }
];
const dicomFormSettings = [
  { label: 'DICOM', component: 'EditDicomServers' },
  { label: 'Email', component: 'EditEmail' }
];

export default Vue.extend({
  components: { PageTemplate, ...formComponents },
  data () {
    return {
      selectedFormComponent: 'EditClinic'
    };
  },
  computed: {
    isRoot: get<boolean>('permissionProfile/isRoot'),
    emailConfig: get<IEmailConfigModel | undefined>('email/config'),
    permissionProfile: get<IPermissionProfileModel>('permissionProfile/profile'),
    forms (): any[] {
      const showCloudRootSettings = this.isRoot && (isAppCloud() || isDevelopment());
      return [
        { label: 'Clinic', component: 'EditClinic' },
        ...this.permissionProfile.dicomForms ? dicomFormSettings : [],
        ...this.permissionProfile.bulkDownload ? bulkDownloadSettings : [],
        ...showCloudRootSettings ? cloudRootSettings : [],
        ...isAppCloud() ? cloudSettings : []
      ];
    }
  },
  methods: {
    onClick (formComponent: string): void {
      this.selectedFormComponent = formComponent;
    },
    isActive (formComponent: string): boolean {
      return this.selectedFormComponent === formComponent;
    }
  }
});
