import { once } from 'lodash';
import { IPatientModel, IStudieModel } from '@/api/Models';
import { ActionContext, Module, Store } from 'vuex';
import vuexStore, { IRootState } from '../index';
import { make, commit, dispatch } from 'vuex-pathify';
import { getById, post, put } from '../restfulState';
import FMSAxios from '@/api/FMSAxios';

export interface IPatientState {
  patient?: IPatientModel;
  patients?: IPatientModel[];
}

const _state: IPatientState = {
  patient: undefined,
  patients: []
};

const patient: Module<IPatientState, IRootState> = {
  state: _state,
  getters: {
    getTodaysStudy (): IStudieModel | undefined {
      const patientId = vuexStore.get('patient/patient@id');
      if (!patientId) return undefined;

      const todaysStudies: IStudieModel[] = vuexStore.get('study/workList');
      return todaysStudies.find(s => s.patientId === patientId);
    }
  },
  mutations: {
    ...make.mutations(_state),
    setPatient (state, patientData: IPatientModel) {
      state.patient = patientData;
    }
  },
  actions: {
    initialize: once(async (context: ActionContext<IPatientState, IRootState>, store: Store <IRootState>) => {
      await dispatch('patient/fetchAllPatients');

      store.watch((state) => {
        return {
          clinicId: state.clinic.clinic?.id
        };
      }, (newState, oldState) => {
        if (newState.clinicId !== oldState.clinicId) {
          dispatch('patient/fetchAllPatients');
        }
      });
    }),
    async fetchAllPatients () {
      const clinicId: number = this.get('clinic/clinic@id');
      const response = await FMSAxios.get<IPatientModel[]>(`/patient?ClinicId=${clinicId}`);
      commit('patient/setPatients', response.data);
    },
    fetchPatient: getById('/patient', 'patient/setPatient'),
    async fetchPatientById (context, patientId: number) {
      const response = await FMSAxios.get<IPatientModel>(`/patient/${patientId}`);
      commit('patient/setPatient', response.data);
    },
    async putPatient (context, patientDetails: IPatientModel): Promise<IPatientModel> {
      patientDetails.clinicId = this.get('clinic/clinic@id');
      patientDetails.userId = this.get('auth/userId');

      const editedPatient = await put('/patient', patientDetails, 'patient/setPatient');
      await dispatch('patient/fetchAllPatients');
      return editedPatient;
    },
    async postPatient (context, patientDetails: IPatientModel): Promise<IPatientModel> {
      patientDetails.clinicId = this.get('clinic/clinic@id');
      patientDetails.userId = this.get('auth/userId');

      const newPatient = await post('/patient', patientDetails, 'patient/setPatient');
      await dispatch('patient/fetchAllPatients');
      return newPatient;
    },
    setPatientById (context, patientId: number) {
      const patients: IPatientModel[] = this.get('patient/patients');

      if (!patients?.length) return;

      const foundPatient = patients.find(p => p.id === patientId);

      commit('patient/setPatient', foundPatient);
    }
  },
  namespaced: true
};

export default patient;
