import Vue from 'vue';
import { Module } from 'vuex';
import { once } from 'lodash';
import { commit, dispatch, make } from 'vuex-pathify';
import vuexStore, { IRootState } from '@/state';
import FMSAxios from '@/api/FMSAxios';
import { IUserModel, IClinicModel, IBulkDownloadImagesRequest, ImageFileType, IClinicProfilesModel } from '@/api/Models';
import { getAll } from '../restfulState';

export interface IClinicState {
  clinic?: IClinicModel;
  clinics: IClinicModel[];
  clinicProfiles: IClinicProfilesModel[];
  clinicProfile?: IClinicProfilesModel;
}

const _state: IClinicState = {
  clinic: undefined,
  clinics: [],
  clinicProfiles: [],
  clinicProfile: undefined
};

const clinic: Module<IClinicState, IRootState> = {
  state: _state,
  mutations: {
    ...make.mutations(_state)
  },
  actions: {
    initialize: once(async () => {
      const user: IUserModel = vuexStore.get('user/user');
      const isRoot: boolean = vuexStore.get('permissionProfile/isRoot');
      const token = Vue.auth.token();

      if (token && user) {
        await dispatch('clinic/fetchClinic');
        if (isRoot) dispatch('clinic/fetchAllClinics');
      }
    }),
    fetchAllClinics: getAll('/clinic', 'clinic/setClinics'),
    async bulkDownloadZip (context, type: ImageFileType): Promise<Blob> {
      const currentClinic = context.state.clinic;
      if (!currentClinic) throw new Error('Missing Clinic');

      const request: IBulkDownloadImagesRequest = {
        type,
        clinicId: currentClinic.id as number
      };
      const response = await FMSAxios.post<Blob>('clinic/download', request, {
        responseType: 'blob'
      });
      return response.data;
    },
    async fetchClinic (): Promise<void> {
      const user: IUserModel = this.get('user/user');
      const response = await FMSAxios.get<IClinicModel>(`clinic/${user.clinicId}`);
      const clinicData = response.data;
      const responseX = await FMSAxios.get<IClinicProfilesModel>(`ClinicProfile/GetByClinic/${user.clinicId}`);
      const clinicProfiles = responseX.data;
      commit('clinic/setClinic', clinicData);
      commit('clinic/setClinicProfiles', clinicProfiles);

    },
    async postClinic (context, payload: IClinicModel): Promise<void> {
      await FMSAxios.post<IClinicModel>('clinic', payload);
      await dispatch('clinic/fetchAllClinics');
    },
    async postClinicProfile (context, payload: IClinicProfilesModel): Promise<void> {
      await FMSAxios.post<IClinicProfilesModel>('clinicprofile', payload);
      await dispatch('clinicprofile/fetchAllClinics');
    },
    async putClinic (context, payload: IClinicModel): Promise<void> {
      const isRoot: boolean = vuexStore.get('permissionProfile/isRoot');

      await FMSAxios.put<IClinicModel>('clinic', payload);
      await dispatch('clinic/fetchClinic');
      if (isRoot) await dispatch('clinic/fetchAllClinics');
    }
  },
  namespaced: true
};

export default clinic;
