

































































import eventBus, { ModalEventNames, IModalInfo } from '@/eventBus';
import Vue from 'vue';
import VBodyScrollLock from 'v-body-scroll-lock/dist/directives/bsl-directive';

export default Vue.extend({
  directives: {
    'body-scroll-lock': VBodyScrollLock
  },
  data () {
    return {
      stackedModals: [] as IModalInfo[],
      error1: '',
      error2: '',
      error3: ''
    };
  },
  computed: {
    isModalOpen (): boolean {
      return !!this.stackedModals.length;
    }
  },
  watch: {
    'stackedModals' (): void {
      this.$nextTick(() => {
        const baseModal = this.$refs.baseModal as HTMLDivElement;
        baseModal.focus();
      });
    }
  },
  mounted () {
    eventBus.$on(ModalEventNames.launchModal, (data: IModalInfo) => {
      this.stackedModals.push(data);
    });
    eventBus.$on(ModalEventNames.closeModal, () => {
      this.closeModal();
    });
    eventBus.$on(ModalEventNames.closeAll, () => {
      this.stackedModals = [];
    });
  },
  methods: {
    closeModal (): void {
      this.error1 = '';
      this.error2 = '';
      this.error3 = '';
      this.stackedModals.pop();
    }
  },
  errorCaptured (error) {
    switch (this.stackedModals.length) {
      case 1:
        this.error1 = error.message;
        break;
      case 2:
        this.error2 = error.message;
        break;
      case 3:
        this.error3 = error.message;
        break;
      default:
        return;
    }
    return false;
  }
});
