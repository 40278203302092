import { IPetOwnerModel, IPatientModel } from '@/api/Models';
import { Module, Store, ActionContext } from 'vuex';
import { IRootState } from '../index';
import { once } from 'lodash';
import { make, commit, dispatch } from 'vuex-pathify';
import { getById, post, put } from '../restfulState';
import FMSAxios from '@/api/FMSAxios';

export interface IOwnerState {
  owner?: IPetOwnerModel;
  owners?: IPetOwnerModel[];
}

const _state: IOwnerState = {
  owner: undefined,
  owners: []
};

const owner: Module<IOwnerState, IRootState> = {
  state: _state,
  mutations: {
    ...make.mutations(_state)
  },
  getters: {
    getPatients (state, getters, root): IPatientModel[] | undefined {
      return root.patient.patients?.filter(patient => patient.ownerId === state.owner?.id);
    }
  },
  actions: {
    initialize: once(async (context: ActionContext<IOwnerState, IRootState>, store: Store <IRootState>) => {
      await dispatch('owner/fetchAllOwners');

      store.watch(state => {
        return {
          owner: state.owner.owner,
          patient: state.patient.patient,
          clinicId: state.clinic.clinic?.id
        };
      }, (newState, oldState) => {
        if (newState.clinicId !== oldState.clinicId) {
          dispatch('owner/fetchAllOwners');
        }

        if (newState.owner?.id !== oldState.owner?.id && newState.owner?.id !== newState.patient?.ownerId) {
          commit('study/setSelectedStudy', undefined);
          commit('patient/setPatient', undefined);

          const newOwnerId = newState.owner?.id;

          if (newOwnerId) {
            const patients = store.get('owner/patients');

            if (patients?.length === 1) {
              commit('patient/setPatient', patients[0]);
            }
          }
        }
      });
    }),
    fetchOwner: getById('/owner', 'owner/setOwner'),
    async fetchOwnerById (context: ActionContext<IOwnerState, IRootState>, ownerId: number) {
      const response = await FMSAxios.get<IPatientModel>(`/owner/getOwner/${ownerId}`);
      commit('owner/setOwner', response.data);
    },
    async fetchAllOwners () {
      const clinicId: number = this.get('clinic/clinic@id');
      const response = await FMSAxios.get<IPetOwnerModel[]>(`/owner?ClinicId=${clinicId}`);
      commit('owner/setOwners', response.data);
    },
    async putOwner (context, ownerDetails: IPetOwnerModel): Promise<IPetOwnerModel> {
      ownerDetails.userId = this.get('auth/userId');
      ownerDetails.clinicId = this.get('clinic/clinic@id');

      const editedOwner = await put('/owner', ownerDetails, 'owner/setOwner');
      await dispatch('owner/fetchAllOwners');
      return editedOwner;
    },
    async postOwner (context, ownerDetails: IPetOwnerModel): Promise<IPetOwnerModel> {
      ownerDetails.userId = this.get('auth/userId');
      ownerDetails.clinicId = this.get('clinic/clinic@id');

      const newOwner = await post('/owner', ownerDetails, 'owner/setOwner');
      await dispatch('owner/fetchAllOwners');
      return newOwner;
    },
    setOwnerById (context, ownerId: number): void {
      const owners: IPetOwnerModel[] = this.get('owner/owners');

      if (!owners?.length) return;

      const foundOwner = owners.find(p => p.id === ownerId);

      commit('owner/setOwner', foundOwner);
    }
  },
  namespaced: true
};

export default owner;
