import Vue from 'vue';
import pathify from './pathify';
import Vuex, { Store } from 'vuex';
import auth, { IAuthState } from './auth';
import study, { IStudyState } from './study';
import xRay, { IXRayState } from './x-ray';
import sensor, { ISensorState } from './sensor';
import patient, { IPatientState } from './patient';
import owner, { IOwnerState } from './owner';
import generator, { IGeneratorState } from './generator';
import clinic, { IClinicState } from './clinic';
import user, { IUserState } from './user';
import breed, { IBreedState } from './breed';
import viewer, { IViewerState } from './viewer';
import caseViewer, { ICaseViewerState } from './caseViewer';

import dicomServer, { IDicomServerState } from './dicomServer';
import email, { IEmailState } from './email';
import radcase, { IRadCaseState } from './case';

import report, { IReportState } from './report/index';
import permissionProfile, { IPermissionProfileState } from './permissionProfile/index';

Vue.use(Vuex);

export interface IRootState {
  auth: IAuthState;
  breed: IBreedState;
  email: IEmailState;
  study: IStudyState;
  patient: IPatientState;
  owner: IOwnerState;
  xRay: IXRayState;
  sensor: ISensorState;
  generator: IGeneratorState;
  clinic: IClinicState;
  user: IUserState;
  viewer: IViewerState;
  caseViewer: ICaseViewerState;
  dicomServer: IDicomServerState;
  report: IReportState;
  permissionProfile: IPermissionProfileState;
  radcase: IRadCaseState;
}

export const store = new Store<IRootState>({
  modules: {
    sensor,
    auth,
    breed,
    clinic,
    dicomServer,
    email,
    generator,
    patient,
    study,
    owner,
    xRay,
    user,
    viewer,
    caseViewer,
    report,
    permissionProfile,
    radcase
  },
  plugins: [pathify.plugin]
});

export default store;
