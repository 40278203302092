/* eslint-disable @typescript-eslint/no-explicit-any */
import { removeToolState } from 'cornerstone-tools';

// returns a function that can be used to remove line that have lost their dependencies from state
function dependencySync (dependencyList: any): any {
  // ensure dependencyList list has correct format with this try/catch
  try {
    if (!Array.isArray(dependencyList)) {
      throw new Error('Incorrect dependencyList format sent to dependencySync, returning null');
    }

    dependencyList.forEach(item => {
      if (!item.step || !Array.isArray(item.dependsOn)) {
        throw new Error('Incorrect dependencyList format sent to dependencySync, returning null');
      }
    });
  } catch (err) {
    console.error(err);
    return null;
  }

  const dependantLineNumbers = dependencyList.map(item => item.step);

  return (toolData: any, element: any, name: any): void => {
    // filter out lines in the toolData that are independant
    const relevantLines = toolData.data
      .filter((line: any) => dependantLineNumbers.includes(line.step));
    const toolDataLineNumbers = toolData.data.map((line: any) => line.step);

    // get all the lines that have lost their dependencies
    const orphanedLines = relevantLines.filter((line: any) => {
      // check if all of the line's dependencies exist
      const dependenciesExist = dependencyList
        .find(dependency => dependency.step === line.step).dependsOn
        .every((dependencyNum: any) => toolDataLineNumbers.includes(dependencyNum));

      return !dependenciesExist;
    });
    // remove orphaned lines from state
    orphanedLines.forEach((line: any) => removeToolState(element, name, line));
  };
}

export default dependencySync;
