
















import Vue from 'vue';
import { IImageModel } from '@/api/Models';
import JpegImageContainer from './JpegImageContainer.vue';
import CornerstoneImageContainer from './CornerstoneImageContainer.vue';

export default Vue.extend({
  components: {
    JpegImageContainer,
    CornerstoneImageContainer
  },
  props: {
    image: {
      type: Object as () => IImageModel,
      required: true
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    useCornerstoneLoader: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick (image: IImageModel): void {
      this.$emit('selection', image);
    },
    onDragStart (event: DragEvent, image: IImageModel): void {
      this.$emit('dragstart', event, image);
    }
  }
});
