















import Vue from 'vue';

export default Vue.extend({
  props: {
    options: {
      type: Array,
      required: true
    },
    buttonClass: {
      type: String,
      default: undefined
    },
    value: {
      type: String,
      default: undefined
    }
  },
  methods: {
    isActive (option: string): boolean {
      return option === this.value;
    },
    onClick (option: string): void {
      this.$emit('input', option);
    }
  }
});
