












import Vue from 'vue';
import { get } from 'vuex-pathify';

import CreateReportPageButton from '@/components/CreateXLogReportPageButton.vue';

import { isAppLocal } from '@/helpers/config';
import { IPermissionProfileModel, IStudieModel } from '@/api/Models';
import { navigate, NavigationMethods } from '@/router/helpers';

export default Vue.extend({
  components: {
    CreateReportPageButton
  },
  computed: {
    selectedStudyHasImages: get('study/selectedStudyHasImages'),
    selectedStudy: get<IStudieModel | undefined>('study/selectedStudy'),
    profile: get<IPermissionProfileModel>('permissionProfile/profile'),

    isViewImagesDisabled (): boolean {
      return !this.selectedStudy || !this.selectedStudyHasImages;
    }
  },
  methods: {
    isAppLocal,
    onReportClick (): void {
      navigate(this.$route, NavigationMethods.push, '/report');
    }
  }
});
