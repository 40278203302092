




















import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: [String, Number],
      default: undefined
    },
    label: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    labelColumn: {
      type: String,
      default: 'col-4'
    },
    inputColumn: {
      type: String,
      default: 'col-8'
    }
  },
  methods: {
    onInput () {
      const input = this.$refs.input as HTMLInputElement;
      this.$emit('input', input.value);
    }
  }
});
