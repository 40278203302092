


































































import Vue from 'vue';
import ImagesModalFrame from '@/components/modal/ImagesModalFrame.vue';
import { get, sync } from 'vuex-pathify';
import { IPetOwnerModel, IPatientModel, IStudieModel } from '@/api/Models';
import eventBus, { ModalEventNames } from '@/eventBus';
import FMSAxios from '@/api/FMSAxios';

export default Vue.extend({
  components: { ImagesModalFrame },
  data () {
    return {
      selectedImageIds: [] as number[],
      owner: undefined as IPetOwnerModel | undefined,
      patient: undefined as IPatientModel | undefined,
      patients: [] as IPatientModel[],
      studies: [] as IStudieModel[],
      originalSelectedStudy: undefined as IStudieModel | undefined
    };
  },
  computed: {
    owners: get<IPetOwnerModel[]>('owner/owners'),
    selectedStudy: sync<IStudieModel | undefined>('study/selectedStudy'),
    isConfirmDisabled (): boolean {
      return !this.selectedImageIds.length;
    }
  },
  async created () {
    this.originalSelectedStudy = this.selectedStudy ? { ...this.selectedStudy } : undefined;
    this.patient = await this.fetch(`patient/${this.selectedStudy?.patientId}`);
    this.patients = await this.fetch(`patient?ownerId=${this.patient?.ownerId}`);
    this.owner = await this.fetch(`owner/${this.patient?.ownerId}`);
    this.studies = await this.fetch(`study?patientId=${this.patient?.id}`);
  },
  destroyed () {
    this.selectedStudy = this.originalSelectedStudy;
  },
  methods: {
    onConfirm (): void {
      eventBus.$emit(ModalEventNames.imageSelected, this.selectedImageIds);
    },
    async onOwnerChange (): Promise<void> {
      this.patient = undefined;
      this.selectedStudy = undefined;
      this.studies = [];
      this.selectedImageIds = [];

      this.patients = await this.fetch(`patient?ownerId=${this.owner?.id}`);
    },
    async onPatientChange (patient: IPatientModel): Promise<void> {
      this.selectedStudy = undefined;
      this.selectedImageIds = [];

      this.studies = await this.fetch(`study?patientId=${patient?.id}`);
    },
    onStudyChange (): void {
      this.selectedImageIds = [];
    },
    searchOwner (option: IPetOwnerModel, label: number, search: string): boolean {
      const searchText = search.toLowerCase();
      return option.name.toLowerCase().indexOf(searchText) > -1;
    },
    async fetch (path: string) {
      const response = await FMSAxios.get(path);

      return response.data;
    }
  }
});
