export const NATIVE_HEIGHT = 1080;
export const NATIVE_WIDTH = 1920;

type Scaling = {
  widthScale: number;
  heightScale: number;
};

export function getScaling (): Scaling {
  const { innerWidth, innerHeight } = window;

  const aspectRatio = innerWidth / innerHeight;
  const nativeAspectRatio = 1920 / 1080;

  let widthScale, heightScale;
  if (aspectRatio.toFixed(2) === nativeAspectRatio.toFixed(2)) {
    widthScale = innerWidth / 1920;
    heightScale = innerHeight / 1080;
  } else if (aspectRatio < nativeAspectRatio) {
    widthScale = innerWidth / 1920;
    heightScale = widthScale;
  } else {
    heightScale = innerHeight / 1080;
    widthScale = heightScale;
  }

  return {
    heightScale,
    widthScale
  };
}
