import { IUserModel } from '@/api/Models';
import { IRootState } from '../';
import { ActionContext, Module, Store } from 'vuex';
import { dispatch, make, commit } from 'vuex-pathify';
import { once } from 'lodash';
import { get } from '../restfulState';
import Vue from 'vue';
import FMSAxios from '@/api/FMSAxios';

export interface IUserState {
  user?: IUserModel;
  users: IUserModel[];
}

const _state: IUserState = {
  user: undefined,
  users: []
};

const user: Module<IUserState, IRootState> = {
  state: _state,
  mutations: {
    ...make.mutations(_state)
  },
  actions: {
    initialize: once(async (context: ActionContext<IUserState, IRootState>, store: Store<IRootState>) => {
      const userId = store.get('auth/userId');
      const token = Vue.auth.token();

      if (token && userId) {
        await dispatch('user/fetchUser');
      } else {
        throw new Error('Missing a token or a user ID');
      }
    }),
    async fetchAllUsers (): Promise<void> {
      const isRoot: boolean = this.get('permissionProfile/isRoot');
      let response;
      if (isRoot) {
        response = await FMSAxios.get<IUserModel[]>('/user');
      } else {
        const clinicId: number = this.get('clinic/clinic@id');
        response = await FMSAxios.get<IUserModel[]>(`/user?ClinicId=${clinicId}`);
      }
      commit('user/setUsers', response.data);
    },
    async fetchAllRadiologistUser (): Promise<IUserModel[]> {
      const response = await FMSAxios.get<IUserModel[]>('/user/radiologists');
      return response.data;
    },
    async fetchUser (): Promise<void> {
      const userId = this.get('auth/userId');
      return await get(`user/${userId}`, 'user/setUser');
    },
    async postUser (context, payload: IUserModel): Promise<void> {
      await FMSAxios.post<IUserModel>('user', payload);
      await dispatch('user/fetchAllUsers');
    },
    async putUser (context, payload: IUserModel): Promise<void> {
      await FMSAxios.put<IUserModel>('user', payload);
      await dispatch('user/fetchUser');
      await dispatch('user/fetchAllUsers');
    }
  },
  namespaced: true
};

export default user;
