

































import Vue from 'vue';
import { commit, dispatch, get } from 'vuex-pathify';
import { IUserModel, SensorHealth } from '@/api/Models';
import eventBus from '@/eventBus';
import HardwareStatusModal from '@/components/x-ray/HardwareStatusModal.vue';
import { faCheck, faExclamationTriangle, faWifi, faThermometerHalf, faBatteryHalf, IconDefinition } from '@fortawesome/free-solid-svg-icons';

export default Vue.extend({
  props: {
    deviceType: {
      type: String,
      required: true
    },
    hardwareAvailable: {
      type: Boolean,
      default: undefined
    }
  },
  data () {
    return {
      timer: undefined as number | undefined,
      SensorHealth,
      faCheck,
      faExclamationTriangle,
      faWifi,
      faThermometerHalf,
      faBatteryHalf
    };
  },
  computed: {
    sensorHealth: get<SensorHealth>('sensor/sensorStatus@health'),
    sensorBattery: get<number>('sensor/sensorStatus@battery'),
    sensorTemperature: get<number>('sensor/sensorStatus@temp'),
    sensorLatency: get<number>('sensor/sensorStatus@latency'),
    sensorConnected: get<boolean>('sensor/sensorStatus@isConnected'),
    user: get<IUserModel>('user/user'),
    isGenerator (): boolean {
      return this.deviceType === 'Generator';
    },
    deviceConnectionClass (): string {
      if (this.isGenerator) return this.getGeneratorConnectionClass();

      if (!this.sensorConnected) return 'btn-danger';

      const defaultClass = 'btn-with-multi-icons';

      switch (this.sensorHealth) {
        case SensorHealth.Ok:
          return `${defaultClass} btn-success`;

        case SensorHealth.Warn:
          return `${defaultClass} btn-warning`;

        default:
          return `${defaultClass} btn-danger`;
      }
    },
    genIcon (): IconDefinition {
      if (this.hardwareAvailable) return faCheck;
      return faExclamationTriangle;
    },
    isUserRoot (): boolean {
      return this.user.username === 'root';
    }
  },
  // disable for now
  // watch: {
  //   hardwareAvailable (hardwareAvailable: boolean) {
  //     if (!hardwareAvailable) {
  //       this.$swal({
  //         title: `${this.deviceType} disconnected`
  //       });
  //     }
  //   }
  // },
  async created () {
    await this.startSensorHealthTimer();
  },
  beforeDestroy () {
    this.clearSensorHealthTimer();
  },
  methods: {
    onClick (): void {
      eventBus.launchModal(HardwareStatusModal);
    },
    async startSensorHealthTimer (): Promise<void> {
      if (this.isGenerator) return;

      await dispatch('sensor/fetchSensorInfo');

      const fiveSeconds = 5000;

      this.timer = window.setInterval(async () => {
        try {
          await dispatch('sensor/fetchSensorInfo');
        } catch (e) {
          commit('sensor/setSensorStatus', undefined);
        }
      }, fiveSeconds);
    },
    clearSensorHealthTimer (): void {
      clearInterval(this.timer);
    },
    getGeneratorConnectionClass (): string {
      if (this.hardwareAvailable) return 'btn-success';

      return 'btn-danger';
    }
  }
});
