





























import Vue from 'vue';
import PageTemplate from '@/components/page-template/PageTemplate.vue';
import ButtonInterface from './components/ButtonInterface.vue';
import Table from './components/Table.vue';
import Datepicker from 'vuejs-datepicker';
import SearchButton from '@/components/study-table/XlogSearchButton.vue';

export default Vue.extend({
  components: {
    PageTemplate,
    Table,
    ButtonInterface,
    Datepicker,
    SearchButton
  },
  data () {
    return {
      StudyDateFrom: '',
      StudyDateTo: ''
    };
  },
  watch: {
    StudyDateFrom (value) {
      const hiddenInput = document.getElementsByName('StudyDateFromHidden')[0] as HTMLInputElement;
      if (hiddenInput) {
        hiddenInput.value = value;
      }
    },
    StudyDateTo (value) {
      const hiddenInput = document.getElementsByName('StudyDateToHidden')[0] as HTMLInputElement;
      if (hiddenInput) {
        hiddenInput.value = value;
      }
    }
  },
  mounted () {
    const datePickerInputs = document.querySelectorAll('.vdp-datepicker div input') as NodeListOf<HTMLInputElement>;
    datePickerInputs.forEach(datePickerInput => {
      datePickerInput.addEventListener('blur', () => {
        datePickerInput.style.backgroundColor = 'transparent';
      });
    });
  }
});
