









import Vue from 'vue';
import ErrorBoundary from '@/components/ErrorBoundary.vue';
import Modal from '@/components/modal/Modal.vue';
import * as Sentry from '@sentry/vue';
import { CaptureConsole, Dedupe } from '@sentry/integrations';
import { isAppCloud } from './helpers/config';
import { getScaling, NATIVE_HEIGHT, NATIVE_WIDTH } from './helpers/scale';

if (process.env.NODE_ENV === 'production') {
  console.log('Initializing Sentry');
  Sentry.init({
    Vue,
    release: 'main',
    debug: true,
    dsn: 'https://9626dd4ca059434ab47f766ee15ae98d@o505860.ingest.sentry.io/5594966',
    autoSessionTracking: true,
    integrations: [
      new CaptureConsole({ levels: ['error', 'warn'] }),
      new Dedupe()
    ],
    tracingOptions: {
      trackComponents: true
    },
    logErrors: true,
    // We recommend adjusting this value in production, or using tracesSampler for finer control
    tracesSampleRate: 1.0,
    attachProps: true
  });
} else {
  console.warn(`Env: ${process.env.NODE_END} - Not Production, Disabling Sentry.`);
}

export default Vue.extend({
  components: {
    ErrorBoundary,
    Modal
  },
  mounted () {
    const app = this.$refs.app as HTMLElement;

    if (isAppCloud()) {
      app.style.width = '100%';
      app.style.height = '100%';
      app.classList.add('responsive');
      return;
    }

    const { widthScale, heightScale } = getScaling();

    app.style.width = `${NATIVE_WIDTH}px`;
    app.style.height = `${NATIVE_HEIGHT}px`;
    app.style.transform = `scale(${widthScale}, ${heightScale})`;
  }
});

