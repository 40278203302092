/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-case-declarations */
import { importInternal, getToolState, LengthTool, CircleRoiTool, Measurement } from 'cornerstone-tools';
import { getImage } from 'cornerstone-core';

import {
  getParallelPoint,
  calculateBreakingPoint,
  calculateSlope
} from './utils/math';

import getParallelLineEnd from './utils/getParallelLineEnd';

// Utility
import staticSync from './utils/staticSync';
import dependencySync from './utils/dependencySync';
import renderDataLength from './render/renderDataLength';
import renderDataCircle from './render/renderDataCircle';
import calculateTta from './calculations/calculateTta';
import { removeFromOverlay, removeAllFromOverlay, attachToOverlay } from './utils/toolOverlay';
import Instructor from './utils/instructor';

const moveAnnotation = importInternal('manipulators/moveAnnotation');
const moveHandleNearImagePoint = importInternal(
  'manipulators/moveHandleNearImagePoint'
);
const lengthCursor = importInternal('tools/cursors').lengthCursor;
const getLogger = importInternal('util/getLogger');
const getPixelSpacing = importInternal('util/getPixelSpacing');
const throttle = importInternal('util/throttle');

const BaseAnnotationTool = importInternal('base/BaseAnnotationTool');
const logger = getLogger('tools:annotation:TTATool');

export default class TTATool extends BaseAnnotationTool {
  constructor (props = {}) {
    const defaultProps = {
      name: 'TTA',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      svgCursor: lengthCursor,
      configuration: {
        drawHandles: true,
        drawHandlesOnHover: false,
        hideHandlesIfMoving: false,
        renderDashed: false
      }
    };

    super(props, defaultProps);
    this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);

    this.tta = null;
    this.instructor = null;
    this.lengthTool = new LengthTool();
    this.circleRoiTool = new CircleRoiTool();

    const dependencies = [
      { step: 3, dependsOn: [1, 2] },
      { step: 4, dependsOn: [1, 2, 3] }
    ];

    const statics = [
      { step: 1, staticOn: [4] },
      { step: 2, staticOn: [4] },
      { step: 3, staticOn: [4] }
    ];
    this.staticSync = staticSync(statics);
    this.dependencySync = dependencySync(dependencies);
  }

  // returns the number of the next line that should be drawn as part of the calculation
  getCurrentStep (toolData: any): number | undefined {
    const allNumbers = [1, 2, 3, 4];
    const currentNumbers = toolData.map((line: any) => line.step);
    const availableNumbers = allNumbers.filter(
      number => !currentNumbers.includes(number)
    );

    if (availableNumbers[0]) {
      return availableNumbers[0];
    }
  }

  createNewMeasurement (eventData: any): Measurement | undefined {
    // Prevent more than 4 measurements being drawn
    const toolData = getToolState(eventData.element, this.name);
    if (toolData && toolData.data.length >= 4) {
      return;
    }
    const goodEventData =
      eventData && eventData.currentPoints && eventData.currentPoints.image;

    if (!goodEventData) {
      logger.error(
        `required eventData not supplied to tool ${this.name}'s createNewMeasurement`
      );

      return;
    }

    const step = toolData ? this.getCurrentStep(toolData.data) : 1;
    const { x, y } = eventData.currentPoints.image;

    return {
      step,
      toolType: 'TTA',
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      handles: {
        start: {
          x,
          y,
          highlight: true,
          active: false
        },
        end: {
          x,
          y,
          highlight: true,
          active: true
        },
        initialRotation: eventData.viewport.rotation,
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true
        }
      }
    };
  }

  pointNearTool (element: HTMLElement, data: any, coords: any, interactionType: string): boolean {
    // run different pointNearTool method depending on which part of TTA we're measuring
    switch (data.step) {
      case 1:
        return this.circleRoiTool.pointNearTool(element, data, coords, interactionType);
      case 2:
        return this.circleRoiTool.pointNearTool(element, data, coords, interactionType);
      case 3:
        return this.lengthTool.pointNearTool(element, data, coords, interactionType);
      case 4:
        return this.lengthTool.pointNearTool(element, data, coords, interactionType);
      default:
        return false;
    }
  }

  renderToolData (evt: any): void {
    const eventData = evt.detail;
    const toolData = getToolState(evt.currentTarget, this.name);

    if (!toolData) {
      return;
    }
    this.staticSync(toolData);
    // delete lines that have lost their dependencies
    this.dependencySync(toolData, eventData.element, this.name);
    // If 4 measurements have finished drawing or if TTA has been calculated already
    const someActive = toolData.data.some(line => line.active);
    if ((toolData.data.length === 4 && !someActive) || !!this.tta) {
      // If TTA Angle is calculated but one of the measurements was deleted, set TTA Angle to null again
      if (toolData.data.length < 4) {
        removeFromOverlay('tta-result', eventData.element);
        this.tta = null;
      } else {
        this.drawTta(toolData.data, eventData.element, eventData.image);
      }
    }

    if (this.instructor) {
      const step = this.getCurrentStep(toolData.data);

      if (step && !someActive) this.instructor.setStage(step);
      else if (!step && !someActive) this.instructor.setStage(0);
    }

    // draw different shapes depending on where in the loop we are
    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];
      const step = data.step;

      const firstLine = toolData.data.find(line => line.step === 1);
      const secondLine = toolData.data.find(line => line.step === 2);
      const thirdLine = toolData.data.find(line => line.step === 3);

      switch (step) {
        case 1:
          renderDataCircle(eventData, data, this.configuration);
          break;

        case 2:
          renderDataCircle(eventData, data, this.configuration);
          if (firstLine) {
            const lineData: Measurement = {
              toolType: 'TTA',
              visible: firstLine.visible,
              active: false,
              color: undefined,
              invalidated: true,
              handles: {
                start: firstLine.handles.start,
                end: data.handles.start
              },
              static: true
            };
            renderDataLength(eventData, lineData, this.configuration);
          }
          break;

        case 3:
          if (!firstLine || !secondLine) {
            return;
          }

          const circleHandles = {
            start: firstLine?.handles.start,
            end: secondLine?.handles.start
          };
          const parallelLine = getParallelLineEnd(
            circleHandles,
            data.handles.end
          );
          const parallelLineData = {
            ...data.handles,
            start: {
              ...data.handles.start,
              ...parallelLine
            }
          };

          data.handles = parallelLineData;
          renderDataLength(eventData, data, this.configuration, 'end');
          break;

        case 4:
          const slope = calculateSlope(thirdLine?.handles);
          const breakingPoint = calculateBreakingPoint(
            thirdLine?.handles.end,
            slope
          );
          const parallelPoint = getParallelPoint(
            data.handles.end,
            slope,
            breakingPoint
          );

          const renderData = {
            ...data.handles,
            start: {
              ...data.handles.start,
              ...parallelPoint
            }
          };
          data.handles = renderData;
          renderDataLength(eventData, data, this.configuration);
          break;
        default:
      }
    }
  }

  // draws the calculation on the image
  // @toolData is the current tool state
  // @element is the element to draw on
  drawTta (toolData: any, element: HTMLElement, image: any): void {
    removeAllFromOverlay(element);
    const line = toolData.find((item: any) => item.step === 4);
    this.tta = calculateTta(line, image);
    attachToOverlay(`TTA: ${this.tta}`, 'tta-result', element, 20, 240);
  }

  updateCachedStats (image: any, element: HTMLElement, data: any): void {
    const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

    // Set rowPixelSpacing and columnPixelSpacing to 1 if they are undefined (or zero)
    const dx =
      (data.handles.end.x - data.handles.start.x) * (colPixelSpacing || 1);
    const dy =
      (data.handles.end.y - data.handles.start.y) * (rowPixelSpacing || 1);

    // Calculate the length, and create the text variable with the millimeters or pixels suffix
    const length = Math.sqrt(dx * dx + dy * dy);

    // Store the length inside the tool for outside access
    data.length = length;
    data.invalidated = false;
  }

  // Draw instructions when the tool activates
  activeCallback (element: HTMLElement): void {
    const canvas = Array.from(element.children).find(
      node => node.nodeName === 'CANVAS'
    );
    if (!this.instructor && canvas) {
      const text = [
        'Draw a circle to identify the circle of rotation of the femur',
        'Draw a circle to identify the circle of rotation of the tibia',
        'Click on the cranial border of the patella \r\n and adjust the height if necessary',
        'Click on the point where the border of \r\n the tibial tuberosity is closest to the parallel line'
      ];
      this.instructor = new Instructor(canvas as HTMLElement, text);
    }
    this.instructor.init();

    // attachement to overlay if needed
    const toolState = getToolState(element, this.name);
    if (toolState) {
      const toolData = toolState.data;
      if (toolData.length === 4 && canvas) {
        const image = getImage(element);
        this.drawTta(toolData, canvas as HTMLElement, image);
      }

      const nextStep = this.getCurrentStep(toolData);
      this.instructor.setStage(nextStep);
    }
  }

  passiveCallback (): void {
    if (this.instructor) {
      this.instructor.wipe();
    }
  }

  handleSelectedCallback (evt: any, data: any, handle: any, interactionType = 'mouse'): void {
    // line is not static OR the handle belongs to a linked text box
    if (data.static !== true || handle.hasBoundingBox) {
      moveHandleNearImagePoint(evt, this, data, handle, interactionType);
    }
  }

  toolSelectedCallback (evt: any, data: any, interactionType = 'mouse'): void {
    if (data.static !== true) {
      moveAnnotation(evt, this, data, interactionType);
    }
  }
}
