/* eslint-disable @typescript-eslint/no-explicit-any */
import { Measurement, importInternal, getModule, toolStyle } from 'cornerstone-tools';
import { getColor } from '../utils/getColor';

// Drawing
const getNewContext = importInternal('drawing/getNewContext');
const draw = importInternal('drawing/draw');
const setShadow = importInternal('drawing/setShadow');
const drawLine = importInternal('drawing/drawLine');
const drawLinkedTextBox = importInternal('drawing/drawLinkedTextBox');
const drawHandles = importInternal('drawing/drawHandles');

type HandleName = 'start' | 'end';

function renderDataLength (eventData: any, data: Measurement, config: any, handleName?: HandleName): void {
  const {
    handleRadius,
    drawHandlesOnHover,
    hideHandlesIfMoving,
    renderDashed
  } = config;
  // We have tool data for this element - iterate over each one and draw it
  const context = getNewContext(eventData.canvasContext.canvas);
  const { element } = eventData;

  const lineWidth = toolStyle.getToolWidth();
  const lineDash = getModule('globalConfiguration').configuration.lineDash;

  if (data.visible === false) {
    return;
  }

  draw(context, (canvasContext: any) => {
    // Configurable shadow
    setShadow(canvasContext, config);

    const color = getColor(data);
    const lineOptions: any = { color };

    if (renderDashed) {
      lineOptions.lineDash = lineDash;
    }

    // Draw the measurement line
    drawLine(
      canvasContext,
      element,
      data.handles.start,
      data.handles.end,
      lineOptions
    );

    // Draw the handles
    const handleOptions = {
      color,
      handleRadius,
      drawHandlesIfActive: drawHandlesOnHover,
      hideHandlesIfMoving
    };

    if (config.drawHandles && !data.static) {
      let drawHandlesData;
      switch (handleName) {
        case 'start':
          drawHandlesData = [data.handles.start];
          break;
        case 'end':
          drawHandlesData = [data.handles.end];
          break;
        default:
          drawHandlesData = data.handles;
          break;
      }

      drawHandles(canvasContext, eventData, drawHandlesData, handleOptions);
    }

    if (!data.handles.textBox) {
      return;
    }

    // draw the text box
    if (!data.handles.textBox.hasMoved) {
      const coords: { x: number; y?: number } = {
        x: Math.max(data.handles.start.x, data.handles.end.x)
      };

      // Depending on which handle has the largest x-value,
      // Set the y-value for the text box
      if (coords.x === data.handles.start.x) {
        coords.y = data.handles.start.y;
      } else {
        coords.y = data.handles.end.y;
      }

      data.handles.textBox.x = coords.x;
      data.handles.textBox.y = coords.y;
    }

    // Move the textbox slightly to the right and upwards
    // So that it sits beside the length tool handle
    const xOffset = 7;
    const text = `${data.toolType} ${data.step}`;
    drawLinkedTextBox(
      canvasContext,
      element,
      data.handles.textBox,
      text,
      data.handles,
      textBoxAnchorPoints,
      color,
      lineWidth,
      xOffset,
      true
    );
  });
}

function textBoxAnchorPoints (handles: any): any[] {
  const midpoint = {
    x: (handles.start.x + handles.end.x) / 2,
    y: (handles.start.y + handles.end.y) / 2
  };

  return [handles.start, midpoint, handles.end];
}

export default renderDataLength;
