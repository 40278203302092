
























import Vue from 'vue';
import { get } from 'vuex-pathify';
import { getToolbarTools, IDropdownToolGroup, ITool, getDropdownGroupedTools } from './caseToolLists';

import router from '@/router';
import Tool from './Tool.vue';
import DropdownToolGroup from './DropdownToolGroup.vue';
import TopBarContainer from '@/components/x-ray/TopBarContainer.vue';
import { IPermissionProfileModel } from '@/api/Models';

export default Vue.extend({
  components: {
    Tool,
    TopBarContainer,
    DropdownToolGroup
  },
  computed: {
    profile: get<IPermissionProfileModel>('permissionProfile/profile'),
    isRoot: get<boolean>('permissionProfile/isRoot'),
    tools (): ITool[][] {
      return getToolbarTools();
    },
    dropdownGroupedTools (): IDropdownToolGroup[] {
      return getDropdownGroupedTools(this.profile.dicomForms, this.profile.advancedTools, this.profile.doctorCase || this.profile.root);
    }
  },
  methods: {
    async onBackClick (): Promise<void> {
      router.go(-1);
    }
  }
});
