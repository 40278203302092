import moment from 'moment';

export default function getEmailBody (patient: string, studyString: string, clinic: any): string {
  const studyDate: Date = new Date(studyString);
  const studyFormat: string = moment(studyDate).format('MMMM DD, YYYY');
  const emailTemplate =
  'Hello,<br><br>Please find the attached radio-graphs for ' + patient + '. Taken on ' + studyFormat + '. <br>Complete medical records to follow.' +
  '<br><br>Regards,' +
  '<br>' + clinic.name + ',' +
  '<br>' + clinic.address +
  '<br>' + clinic.phone;

  return emailTemplate;
}
