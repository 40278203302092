


















import Vue from 'vue';
import { sync } from 'vuex-pathify';
import { IDisplay } from '@/state/viewer/images/images-types';
import { getDisplay } from '@/state/viewer/general-helpers';

export default Vue.extend({
  data () {
    return {
      rows: 3,
      columns: 3,
      hoverCoordinates: {
        row: -1,
        column: -1
      }
    };
  },
  computed: {
    displayGrid: sync<IDisplay[]>('viewer/images/displayGrid')
  },
  watch: {
    hoverCoordinates (hoverCoordinates) {
      const cells = this.$refs.columns as HTMLElement[];
      cells.forEach(cell => {
        const row = cell.attributes.getNamedItem('row')?.nodeValue;
        const column = cell.attributes.getNamedItem('column')?.nodeValue;
        if (!row || !column) return;

        const shouldHighlight = hoverCoordinates.row >= row && hoverCoordinates.column >= column;
        const isHighlighted = cell.classList.contains('highlight');
        if (shouldHighlight && !isHighlighted) {
          cell.classList.add('highlight');
          return;
        }
        if (!shouldHighlight && isHighlighted) {
          cell.classList.remove('highlight');
        }
      });
    }
  },
  methods: {
    onMouseOver (row: number, column: number): void {
      this.hoverCoordinates = { row, column };
    },
    onMouseOut (): void {
      this.resetHoverCoordinates();
    },
    onClick (rows: number, columns: number): void {
      const emptyGrid: IDisplay[] = [];
      for (let row = 0; row < rows; row++) {
        for (let column = 0; column < columns; column++) {
          // if there's a display at those co-ordinates that already has an enabledHTMLElement, transfer the element to to the new display
          const currentDisplay = getDisplay(this.displayGrid, { row, column });
          const emptyDisplay: IDisplay = {
            row,
            column,
            enabledHtmlElement: currentDisplay?.enabledHtmlElement,
            image: undefined
          };
          emptyGrid.push(emptyDisplay);
        }
      }
      this.displayGrid = emptyGrid;
      this.resetHoverCoordinates();
    },
    resetHoverCoordinates (): void {
      this.hoverCoordinates = { row: -1, column: -1 };
    }
  }
});
