/* eslint-disable @typescript-eslint/no-explicit-any */
import { Handle, importInternal } from 'cornerstone-tools';

const getPixelSpacing = importInternal('util/getPixelSpacing');

/**
 * Return the Vector of a line which determines its inclination and length
 *
 * @param {number} columnPixelSpacing Width that a pixel represents in mm
 * @param {number} rowPixelSpacing Height that a pixel represents in mm
 * @param {*} startPoint Start point of the line
 * @param {*} endPoint End point of the line
 *
 * @returns {*} Resulting line inclination vector
 */
function getLineVector (
  columnPixelSpacing: number,
  rowPixelSpacing: number,
  startPoint: any,
  endPoint: any
): any {
  const dx = (startPoint.x - endPoint.x) * columnPixelSpacing;
  const dy = (startPoint.y - endPoint.y) * rowPixelSpacing;
  const lineLength = Math.sqrt(dx * dx + dy * dy);
  const vectorX = dx / lineLength;
  const vectorY = dy / lineLength;

  return {
    x: vectorX,
    y: vectorY,
    length: lineLength
  };
}

// calculate slope between start and end point inside handles
function calculateSlope (handles: any): number {
  const { start, end } = handles;

  const ySub = end.y - start.y;
  const xSub = end.x - start.x;

  const slope = ySub / xSub;
  return slope;
}

// calculate intersection coordinates of two lines
function calculateIntersection (b1: any, m1: any, b2: any, m2: any): Handle {
  const x = (b2 - b1) / (m1 - m2);
  const y = (m1 * x) + b1;
  return { x, y };
}

function calculateBreakingPoint (point: any, slope: any): number {
  const breakPoint = point.y - (slope * point.x);
  return breakPoint;
}
// calcualtes center point of ellipses assuming you have handle coordinates of a square surrounding the circle
function calculateEllipseCenter (handles: any): Handle {
  const { start, end } = handles;
  const x = start.x + (end.x - start.x) / 2;
  const y = start.y + (end.y - start.y) / 2;
  return { x, y };
}

function calculateMidpoint (handles: any): Handle {
  const { start, end } = handles;
  const x = start.x + ((end.x - start.x) / 2);
  const y = end.y + ((start.y - end.y) / 2);
  return { x, y };
}

// returns coordinates of a point that is parallel to the point argument that is also located in the line to which lineSlope and lineBreak belongs to
function getParallelPoint (point: any, lineSlope: number, lineBreak: any): Handle {
  const breakPoint = point.y + (point.x / lineSlope);
  const x = (breakPoint - lineBreak) / (lineSlope + (1 / lineSlope));
  const y = (lineSlope * x) + lineBreak;
  return { x, y };
}

function length (vector: any): number {
  return Math.sqrt(Math.pow(vector.x, 2) + Math.pow(vector.y, 2));
}

function getSide (image: any, handleEnd: any, handleStart: any): Handle {
  const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

  return {
    x: (handleEnd.x - handleStart.x) * (colPixelSpacing || 1),
    y: (handleEnd.y - handleStart.y) * (rowPixelSpacing || 1)
  };
}

export {
  calculateSlope,
  calculateIntersection,
  calculateBreakingPoint,
  calculateEllipseCenter,
  calculateMidpoint,
  getParallelPoint,
  length,
  getSide,
  getLineVector
};
