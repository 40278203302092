










import Vue from 'vue';
import { get, dispatch, commit } from 'vuex-pathify';

import { IPatientModel, IStudieModel, Modality } from '@/api/Models';
import { navigate, NavigationMethods } from '@/router/helpers';
import { isServerLocal } from '@/helpers/config';

export default Vue.extend({
  computed: {
    patient: get<IPatientModel>('patient/patient'),
    modality: get<Modality>('sensor/sensor@modality'),
    todaysStudy: get<IStudieModel>('patient/todaysStudy'),
    isSelectedStudyToday: get<boolean>('study/isSelectedStudyToday'),
    selectedStudy: get<IStudieModel>('study/selectedStudy'),
    isDisabled (): boolean {
      if (this.$route.name === 'Studylist' && !this.selectedStudy) return true;

      return (!this.patient && !this.selectedStudy) || (this.modality === undefined && !!this.todaysStudy) || !isServerLocal();
    },
    buttonLabel (): string {
      if (!this.selectedStudy) return 'Start Study';

      if (this.isSelectedStudyToday) return 'Add Images';

      return this.todaysStudy ? 'Add Images to Today\'s Study' : 'Start Study';
    }
  },
  created () {
    this.ensureSelectedStudy();
  },
  methods: {
    async onClick (): Promise<void> {
      const patientId = this.patient?.id ? this.patient.id : this.selectedStudy.patientId;

      let study: IStudieModel;

      if (!this.isSelectedStudyToday && !this.todaysStudy) {
        study = await dispatch('study/postStudy', patientId);
      } else {
        study = this.todaysStudy ? this.todaysStudy : this.selectedStudy;
      }

      if (!this.modality) return;

      await dispatch('generator/validateGenerator', this.modality);
      await dispatch('sensor/validateSensor', this.modality);

      const path = this.modality.toLowerCase();

      navigate(this.$route, NavigationMethods.push, path, { studyId: study.id?.toString() });
    },
    ensureSelectedStudy (): void {
      if (!this.isSelectedStudyToday && this.todaysStudy) commit('study/setSelectedStudy', this.todaysStudy);
    }
  }
});
