
















import { get } from 'vuex-pathify';
import mixins from 'vue-typed-mixins';
import { IStudieModel, Species } from '@/api/Models';
import { getGeneralParts, getDentalParts } from '@/state/x-ray/body-parts';
import { AutoSuggestBase } from '../../../mixins/auto-suggest-base';

export default mixins(AutoSuggestBase).extend({
  props: {
    isGeneral: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    patientSpecies: get<Species>('patient/patient@speciesId'),
    study: get<IStudieModel>('study/selectedStudy'),
    bodyParts (): undefined | string[] {
      const species = this.patientSpecies as Species;
      return this.isGeneral ? getGeneralParts(species) : getDentalParts(species);
    },
    suggestions (): any {
      if (!this.bodyParts) {
        return;
      }

      const filteredBodyParts = this.bodyParts.filter(this.doesSuggestionIncludeInput);
      return [{ data: filteredBodyParts }];
    }
  }
});
