






import Vue from 'vue';
import PageTemplate from '@/components/page-template/PageTemplate.vue';

export default Vue.extend({
  components: {
    PageTemplate
  }
});
