/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  external,
  EVENTS,
  importInternal,
  addToolState,
  removeToolState
} from 'cornerstone-tools';

const moveNewHandle = importInternal('manipulators/moveNewHandle');

export default function addMeasurementDefault (this: any, evt: any, interactionType: string): void {
  const element = evt.detail.element;

  const measurementData = this.createNewMeasurement(evt.detail);
  // Associate this data with this imageId so we can render it and manipulate it
  addToolState(element, this.name, measurementData);
  external.cornerstone.updateImage(element);

  moveNewHandle(
    evt.detail,
    this.name,
    measurementData,
    measurementData.handles.end,
    this.options,
    interactionType,
    (success: any) => {
      if (measurementData.cancelled) {
        return;
      }

      if (success) {
        const eventType = EVENTS.MEASUREMENT_COMPLETED;
        const eventData = {
          toolName: this.name,
          toolType: this.name, // Deprecation notice: toolType will be replaced by toolName
          element,
          measurementData
        };

        external.cornerstone.triggerEvent(element, eventType, eventData);
      } else {
        removeToolState(element, this.name, measurementData);
      }
    }
  );
}
