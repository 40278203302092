import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isToday from 'dayjs/plugin/isToday';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(isToday);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);

export function formatDateTime (date: Date | dayjs.Dayjs): string | undefined {
  if (!date) return;

  return dayjs(date).format('YYYY-MM-DD kk:mm');
}

export function formatDateShort (date: Date | dayjs.Dayjs): string | undefined {
  if (!date) return;

  return dayjs(date).format('YYYY-MM-DD');
}

export function formatDateVerbose (date: Date | dayjs.Dayjs): string | undefined {
  if (!date) return;

  return dayjs(date).format('MMMM Do, YYYY');
}

export function dateSort (dateString: string, nextDateString: string): number {
  const firstDate = dayjs(dateString);
  const secondDate = dayjs(nextDateString);

  if (firstDate.isSame(secondDate)) return 0;

  if (firstDate.isBefore(secondDate)) return -1;

  return 1;
}
