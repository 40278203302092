















































import Vue from 'vue';
import { get, dispatch } from 'vuex-pathify';
import ValidatedForm from '@/components/form/ValidatedForm.vue';
import FormRow from '@/components/form/FormRow.vue';
import { IEmailConfigModel } from '@/api/Models';

export default Vue.extend({
  components: {
    ValidatedForm,
    FormRow
  },
  data () {
    return {
      form: {
        email: '',
        hostName: 'smtp.gmail.com',
        password: '',
        smtpPort: 587
      },
      errorMessage: '',
      loading: false
    };
  },
  computed: {
    email: get<IEmailConfigModel | undefined>('email/config'),
    clinicId: get<number>('clinic/clinic@id')
  },
  async created () {
    await dispatch('email/fetch');
    this.setFormData();
  },
  methods: {
    setFormData (): void {
      if (!this.email) {
        return;
      }
      const { email, hostName, password, smtpPort } = this.email;
      this.form = {
        email,
        password,
        smtpPort,
        hostName
      };
    },
    onPasswordFocus (): void {
      this.form.password = '';
    },
    onPasswordBlur (): void {
      if (this.form.password === '') {
        this.form.password = this.email ? this.email.password : '';
      }
    },
    async onTestClick (): Promise<void> {
      this.errorMessage = '';
      this.loading = true;
      try {
        await dispatch('email/testEmail', this.email?.id as number);
        this.$toasted.success('Test email sent successfully');
      } catch (error) {
        const errorMessage = error?.response?.data?.message ? error?.response?.data?.message : error.message;
        this.errorMessage = errorMessage;
      } finally {
        this.loading = false;
      }
    },
    async onSaveClick (): Promise<void> {
      this.errorMessage = '';
      this.loading = true;
      try {
        const payload: IEmailConfigModel = { ...this.email, ...this.form, clinicId: this.clinicId };
        await dispatch('email/updateEmailConfig', payload);
        this.setFormData();
      } catch (error) {
        this.errorMessage = error?.response?.data?.message ? error.response.data.message : error.message;
      } finally {
        this.loading = false;
      }
    }
  }
});
