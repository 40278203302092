




























import eventBus, { ModalEventNames } from '@/eventBus';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Vue from 'vue';
export default Vue.extend({
  props: {
    errorMessage: {
      type: String,
      default: ''
    },
    enterHandler: {
      type: Function,
      default: () => undefined
    },
    heading: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      faTimes
    };
  },
  methods: {
    onCloseModalClick (): void {
      eventBus.$emit(ModalEventNames.closeModal);
    }
  }
});
