/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-case-declarations */
import { importInternal, getToolState, Handle, Measurement, LengthTool, BidirectionalTool } from 'cornerstone-tools';

import addMeasurementDefault from './add-measurment/addMeasurementDefault';
import addMeasurementPerpendicular from './add-measurment/addMeasurementPerpendicular';
import renderDataLength from './render/renderDataLength';

import createPerpendicularLineHandles from './utils/createPerpendicularLineHandles';
import handleSelectedMouseCallbackPerpendicular from './utils/handleSelectedMouseCallbackPerpendicular';
import Instructor from './utils/instructor';
import { attachToOverlay, removeAllFromOverlay, removeFromOverlay } from './utils/toolOverlay';

const lengthCursor = importInternal('tools/cursors').lengthCursor;
const getLogger = importInternal('util/getLogger');
const getPixelSpacing = importInternal('util/getPixelSpacing');
const throttle = importInternal('util/throttle');

const logger = getLogger('tools:annotation:VHSTool');
const BaseAnnotationTool = importInternal('base/BaseAnnotationTool');
const moveHandleNearImagePoint = importInternal(
  'manipulators/moveHandleNearImagePoint'
);

function emptyLocationCallback (measurementData: any, eventData: any, doneCallback: () => void): void {
  doneCallback();
}

export default class VHSTool extends BaseAnnotationTool {
  constructor (props = {}) {
    const defaultProps = {
      name: 'VHS',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      svgCursor: lengthCursor,
      configuration: {
        changeMeasurementLocationCallback: emptyLocationCallback,
        getMeasurementLocationCallback: emptyLocationCallback,
        drawHandles: true,
        drawHandlesOnHover: false,
        hideHandlesIfMoving: false,
        renderDashed: false
      }
    };

    super(props, defaultProps);
    this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);
    this.vhs = null;
    this.instructor = null;

    this.handleSelectedMouseCallbackPerpendicular = handleSelectedMouseCallbackPerpendicular.bind(this);

    this.lengthTool = new LengthTool();
    this.bidirectionalTool = new BidirectionalTool();
  }

  getCurrentStep (toolData: Measurement[]): number | undefined {
    const allNumbers = [1, 2];
    const currentNumbers = toolData.map((line: any) => line.step);
    const availableNumbers = allNumbers.filter(
      number => !currentNumbers.includes(number)
    );

    if (availableNumbers[0]) {
      return availableNumbers[0];
    }
  }

  createNewMeasurement (eventData: any): Measurement | undefined {
    const toolData = getToolState(eventData.element, this.name);
    if (toolData && toolData.data.length >= 2) {
      return;
    }

    const goodEventData =
      eventData && eventData.currentPoints && eventData.currentPoints.image;

    if (!goodEventData) {
      logger.error(
        `required eventData not supplied to tool ${this.name}'s createNewMeasurement`
      );

      return;
    }

    const step = toolData ? this.getCurrentStep(toolData.data) : 1;
    const { x, y } = eventData.currentPoints.image;

    // add data of perpendicular handles if drawing second line
    const perpendicularHandles =
      step === 2 ?
        {
          perpendicularStart: { perpendicular: true, locked: true, index: 2 },
          perpendicularEnd: { perpendicular: true, index: 3 }
        } :
        {};

    return {
      step,
      toolType: this.name,
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      handles: {
        ...perpendicularHandles,
        start: {
          index: step === 2 ? 0 : undefined,
          perpendicular: step === 2,
          x,
          y,
          highlight: true,
          active: false
        },
        end: {
          index: step === 2 ? 1 : undefined,
          perpendicular: step === 2,
          x,
          y,
          highlight: true,
          active: true
        },
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true
        }
      },
      longestDiameter: 0,
      shortestDiameter: 0
    };
  }

  addNewMeasurement (evt: any, interactionType: string): void {
    const element = evt.detail.element;
    const toolData = getToolState(element, this.name);
    const step = toolData ? this.getCurrentStep(toolData.data) : 1;
    switch (step) {
      case 1:
        return addMeasurementDefault.call(this, evt, interactionType);
      case 2:
        return addMeasurementPerpendicular.call(this, evt, interactionType);
      default:
    }
  }

  pointNearTool (element: HTMLElement, data: any, coords: any): void {
    switch (data.step) {
      case 1:
        return this.lengthTool.pointNearTool(element, data, coords);
      case 2:
        return (
          this.lengthTool.pointNearTool(element, data, coords) ||
          this.bidirectionalTool.pointNearTool(element, data, coords)
        );
      default:
    }
  }

  renderToolData (evt: any): void {
    const eventData = evt.detail;
    const toolData = getToolState(evt.currentTarget, this.name);
    if (!toolData) {
      return;
    }

    const someActive = toolData.data.some(line => line.active);
    // If  measurements have finished drawing or if VHS has been calculated already
    if ((toolData.data.length === 2 && !someActive) || !!this.vhs) {
      // If VHS is calculated but one of the measurements was deleted, set VHS to null again
      if (toolData.data.length < 2) {
        removeFromOverlay('vhs-result', eventData.element);
        this.vhs = null;
      } else {
        this.drawVhs(toolData.data, eventData.element);
      }
    }

    if (this.instructor) {
      const step = this.getCurrentStep(toolData.data);

      if (step && !someActive) this.instructor.setStage(step);
      else if (!step && !someActive) this.instructor.setStage(0);
    }

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];

      switch (data.step) {
        case 1:
          renderDataLength(eventData, data, this.configuration);
          break;
        case 2:
          renderDataLength(eventData, data, this.configuration);
          createPerpendicularLineHandles(eventData, data);
          data.invalidated = true;

          const perpendicularData: Measurement = {
            ...data,
            handles: {
              start: data.handles.perpendicularStart as Handle,
              end: data.handles.perpendicularEnd as Handle
            }
          };
          const config = {
            ...this.configuration,
            drawHandlesOnHover: false
          };
          renderDataLength(eventData, perpendicularData, config);
          break;
        default:
      }

      const { image, element } = eventData;
      data.length ?
        this.throttledUpdateCachedStats(image, element, data) :
        this.updateCachedStats(image, element, data);

      if (data.handles.perpendicularStart) {
        data.perpendicularLength ?
          this.throttledUpdateCachedStats(image, element, data, true) :
          this.updateCachedStats(image, element, data, true);
      }
    }
  }

  drawVhs (toolData: any, element: HTMLElement): void {
    removeAllFromOverlay(element);
    const firstLine = toolData.find((line: any) => line.step === 1);
    const secondLine = toolData.find((line: any) => line.step === 2);
    const vertebraeLength = firstLine.length / 5;
    const perpendicularsTotal =
      secondLine.length + secondLine.perpendicularLength;
    this.vhs = (perpendicularsTotal / vertebraeLength).toFixed(3);
    attachToOverlay(`VHS: ${this.vhs}`, 'vhs-result', element, 20, 240);
  }

  updateCachedStats (image: any, element: HTMLElement, data: any, isPerpendicular = false): void {
    const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

    let start, end;

    if (isPerpendicular) {
      start = data.handles.perpendicularStart;
      end = data.handles.perpendicularEnd;
    } else {
      start = data.handles.start;
      end = data.handles.end;
    }
    // Set rowPixelSpacing and columnPixelSpacing to 1 if they are undefined (or zero)
    const dx = (end.x - start.x) * (colPixelSpacing || 1);
    const dy = (end.y - start.y) * (rowPixelSpacing || 1);

    // Calculate the length, and create the text variable with the millimeters or pixels suffix
    const length = Math.sqrt(dx * dx + dy * dy);
    // Store the length inside the tool for outside access
    if (isPerpendicular) {
      data.perpendicularLength = length;
    } else {
      data.length = length;
    }
    data.invalidated = false;
  }

  handleSelectedCallback (evt: any, data: any, handle: Handle, interactionType = 'mouse'): void {
    if (handle.perpendicular) {
      this.handleSelectedMouseCallbackPerpendicular(evt);
    } else {
      moveHandleNearImagePoint(evt, this, data, handle, interactionType);
    }
  }

  activeCallback (element: HTMLElement): void {
    const canvas = Array.from(element.children).find(
      node => node.nodeName === 'CANVAS'
    );
    if (!this.instructor && canvas) {
      const text = [
        'Starting from T4 vertebra, \r\n draw a straight line across 5 vertebrae',
        'Draw a line along the long-axis of the heart \r\n and adjust the perpendicular line across the short-axis'
      ];
      this.instructor = new Instructor(canvas as HTMLElement, text);
    }
    this.instructor.init();

    // attachement to overlay if needed
    const toolState = getToolState(element, this.name);
    if (toolState && canvas) {
      const toolData = toolState.data;
      if (toolData.length === 2) this.drawVhs(toolData, canvas as HTMLElement);

      const nextStep = this.getCurrentStep(toolData);
      this.instructor.setStage(nextStep);
    }
  }

  passiveCallback (): void {
    if (this.instructor) {
      this.instructor.wipe();
    }
  }
}
