import Vue from 'vue';
import { IImageModel } from '@/api/Models';
import { getImageJpegData } from '@/state/study/helpers';

export const JpegImageLoader = Vue.extend({
  data () {
    return {
      imageSrc: ''
    };
  },
  watch: {
    async image (newImage: IImageModel, oldImage: IImageModel) {
      if (newImage?.id && newImage.id !== oldImage?.id) {
        this.imageSrc = await this.getImageJpegData(newImage.id);
      }
    }
  },
  methods: {
    getImageJpegData
  }
});
