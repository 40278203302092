






























import Vue from 'vue';
import { Prop } from 'vue/types/options';
import { dispatch, get } from 'vuex-pathify';

import Title from './Title.vue';
import TextEditor from './text-editor/TextEditor.vue';
import ImagesLayout from './images/ImagesLayout.vue';
import { IReportPage } from '@/state/report';
import eventBus, { ModalEventNames } from '@/eventBus';
import ImagePicker from './images/ImagePickerModal.vue';

export default Vue.extend({
  components: {
    TextEditor,
    ImagesLayout,
    Title
  },
  props: {
    page: {
      type: Object as Prop<IReportPage>,
      required: true
    },
    pageIndex: {
      type: Number,
      required: true
    },
    isThumbnail: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      editorHeightLimit: undefined as undefined | number,
      selectedPlaceholderIndex: undefined as number | undefined
    };
  },
  computed: {
    studyId: get<number>('study/selectedStudy@id'),
    isActive (): boolean {
      return !this.isThumbnail && this.page.isActive;
    }
  },
  mounted () {
    this.setHeightLimit();
    this.initializeImagePickerListener();
  },
  beforeDestroy () {
    eventBus.$off(ModalEventNames.imageSelected);
  },
  methods: {
    onEditorInput (htmlContent: string): void {
      dispatch('report/updateHtmlContent', {
        htmlContent,
        pageIndex: this.pageIndex
      });
    },
    onEditorClick (): void {
      if (this.isThumbnail) return;

      dispatch('report/activatePage', this.pageIndex);
    },
    onImageClick (placeholderIndex: number): void {
      if (this.isThumbnail) {
        return;
      }

      this.selectedPlaceholderIndex = placeholderIndex;

      eventBus.launchModal(ImagePicker, { studyId: this.studyId });
    },
    setHeightLimit (): void {
      const rootContainer = this.$refs['root-container'] as HTMLElement;
      const topContainer = this.$refs['top-container'] as HTMLElement;

      this.editorHeightLimit = rootContainer.clientHeight - topContainer.clientHeight;
    },
    initializeImagePickerListener (): void {
      eventBus.$on(ModalEventNames.imageSelected, async (imageIds: number[]) => {
        const partialReportImage = { id: imageIds[0], index: this.selectedPlaceholderIndex };
        await dispatch('report/addImage', { image: partialReportImage, pageIndex: this.pageIndex });

        eventBus.$emit(ModalEventNames.closeModal);
      });

      eventBus.$on(ModalEventNames.closeModal, () => {
        this.selectedPlaceholderIndex = undefined;
      });
    }
  }
});
