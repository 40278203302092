









































import Vue from 'vue';
import { get } from 'vuex-pathify';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { IImageModel } from '@/api/Models';
  import CaseThumbnail from './CaseThumbnail.vue';

export default Vue.extend({
  components: {
    Swiper,
    SwiperSlide,
    CaseThumbnail
  },
  props: {
    images: {
      type: Array,
      required: true
    },
    onSelection: {
      type: Function,
      required: true
    },
    onDragStart: {
      type: Function,
      default: undefined
    },
    direction: {
      type: String,
      default: 'horizontal'
    },
    slidesPerView: {
      type: Number,
      required: true
    },
    numOfPlaceholders: {
      type: Number,
      default: 0
    },
    useCornerstoneLoader: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      swiperOptions: {
        direction: this.direction,
        slidesPerView: this.slidesPerView,
        spaceBetween: 10,
        slidesPerGroup: 3,
        allowTouchMove: false,
        mousewheel: true
      },
      isSwiperReady: false,
      isPrevInactive: true,
      isNextInactive: false
    };
  },
  computed: {
    selectedImage: get<IImageModel | undefined>('study/selectedImage'),
    isNavigationHidden (): boolean {
      return this.swiperOptions.slidesPerView >= this.images.length;
    },
    swiper (): any {
      const swiperRef = this.$refs.mySwiper as any;
      return swiperRef.$swiper;
    }
  },
  methods: {
    slideChange (): void {
      this.isPrevInactive = this.swiper.isBeginning;
      this.isNextInactive = this.swiper.isEnd;
    },
    onPrevClick (): void {
      this.swiper.slidePrev();
    },
    onNextClick (): void {
      this.swiper.slideNext();
    },
    isSelected (image: IImageModel): boolean {
      return this.selectedImage?.id === image.id;
    },
    swiperReady (): void {
      this.isSwiperReady = true;
    }
  }
});
