






import { fireError } from '@/helpers/sweetAlert';
import Vue from 'vue';

export default Vue.extend({
  errorCaptured (error) {
    console.error(error);
    fireError(error.message);
    return false;
  }
});
