























import Vue from 'vue';
import { get, dispatch } from 'vuex-pathify';
import eventBus, { ModalEventNames } from '@/eventBus';
import ModalFrame from '@/components/modal/ModalFrame.vue';
import ValidatedForm from '@/components/form/ValidatedForm.vue';

import FormRow from '@/components/form/FormRow.vue';
import { normalizeSpaces } from '@/helpers/string';
  import { EntityStatus, IClinicProfilesModel } from '@/api/Models';
import * as Sentry from '@sentry/vue';

export default Vue.extend({
  components: { ModalFrame, ValidatedForm, FormRow },
  props: {
    buttonType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      form: {
        firstName: '',
        lastName: '',
      },
      loading: false,
      errorMessage: '',
      resetOnComplete: false
    };
  },
  computed: {
    owner: get('owner/owner'),
    clinicId: get<number>('clinic/clinic@id')
  },
  created () {
    this.setFormData();
  },
  methods: {
    async onSaveClick (): Promise<void> {
      this.errorMessage = '';
      this.loading = true;
      this.resetOnComplete = false;

      const clinicProfilePayload: IClinicProfilesModel = {
        firstName: normalizeSpaces(this.form.firstName),
        lastName: normalizeSpaces(this.form.lastName),
        clinicId: this.clinicId,
        createdOn: new Date(),
        role: 1,
        isActive : true
      };

      try {
        await dispatch('clinic/postClinicProfile', clinicProfilePayload);
      } catch (error) {
        Sentry.captureException(error);
        //this.errorMessage = error?.response?.data?.message ? error.response.data.message : error.message;
      } finally {
        this.loading = false;
        this.resetOnComplete = true;
      }

      if (!this.errorMessage) {
        eventBus.$emit(ModalEventNames.closeModal);
      }
    },
    setFormData (): void {
      if (this.buttonType === 'Edit' && this.owner) {
        this.form = { ...this.form, ...this.owner };
      }
    }
  }
});
