/* eslint-disable @typescript-eslint/no-explicit-any */
import { Handle } from 'cornerstone-tools';
import { calculateSlope, calculateIntersection, calculateBreakingPoint } from './math';

// calculate the coordinates of a point opposite of startPoint that will intersect with longLine's and perpendicularLine's intersection
function getOppositePoint (tool: any, startPoint: Handle): Handle {
  const perpendicularHandles = {
    start: tool.handles.perpendicularStart,
    end: tool.handles.perpendicularEnd
  };

  // slopes
  const longSlope = calculateSlope(tool.handles);
  const perpendicularSlope = calculateSlope(perpendicularHandles);
  // break points
  const longBreak = calculateBreakingPoint(tool.handles.start, longSlope);
  const perpendicularBreak = calculateBreakingPoint(perpendicularHandles.start, perpendicularSlope);
  // intersection coordinates
  const intersection = calculateIntersection(longBreak, longSlope, perpendicularBreak, perpendicularSlope);
  // coordinates of the opposite point
  const oppositeX = intersection.x + (intersection.x - startPoint.x);
  const oppositeY = intersection.y + (intersection.y - startPoint.y);

  return {
    x: oppositeX,
    y: oppositeY
  };
}

export default getOppositePoint;
