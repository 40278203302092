








































import Vue from 'vue';
import { dispatch } from 'vuex-pathify';
import { Prop } from 'vue/types/options';
import ModalFrame from '@/components/modal/ModalFrame.vue';
import ValidatedForm from '@/components/form/ValidatedForm.vue';
import FormRow from '@/components/form/FormRow.vue';

import { ClinicType, IClinicModel } from '@/api/Models';
import { trimCommas } from '../helpers';
import eventBus, { ModalEventNames } from '@/eventBus';

export default Vue.extend({
  components: {
    ValidatedForm,
    FormRow,
    ModalFrame
  },
  props: {
    clinic: {
      type: Object as Prop<IClinicModel>,
      default: undefined
    }
  },
  data () {
    return {
      form: {
        name: this.clinic?.name,
        address: this.clinic?.address,
        phone: this.clinic?.phone,
        email: this.clinic?.email,
        aeTitle: this.clinic?.aeTitle,
        clinicType: this.clinic?.clinicType
      },
      errorMessage: '',
      loading: false,
      clinicTypes: Object.values(ClinicType)
    };
  },
  methods: {
    async onSaveClick (): Promise<void> {
      this.errorMessage = '';
      this.loading = true;

      try {
        const address = trimCommas(this.form.address as string);

        if (this.clinic) {
          await dispatch('clinic/putClinic', { ...this.clinic, ...this.form, address });
        } else {
          const payload: IClinicModel = { ...this.form, createdOn: new Date(), address };
          await dispatch('clinic/postClinic', payload);
        }
      } catch (error) {
        this.errorMessage = error?.response?.data?.message ? error.response.data.message : error.message;
      } finally {
        this.loading = false;
      }

      if (!this.errorMessage) {
        eventBus.$emit(ModalEventNames.closeModal);
      }
    }
  }
});
