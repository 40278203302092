













import Vue from 'vue';
import { IImageModel } from '@/api/Models';
import Thumbnail from '@/components/swiper/Thumbnail.vue';
import { get } from 'vuex-pathify';

export default Vue.extend({
  components: { Thumbnail },
  props: {
    selectionChange: {
      type: Function,
      required: true
    },
    selectedImageIds: {
      type: Array as () => number[],
      required: true
    },
    allowMulti: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    images: get<IImageModel[]>('study/images')
  },
  methods: {
    onSelection (image: IImageModel): void {
      let updatedIds = this.allowMulti ? this.selectedImageIds : [];

      const isSelected = this.isSelected(image);

      if (isSelected) {
        updatedIds = this.selectedImageIds.filter(id => id !== image.id);
      } else {
        updatedIds.push(image.id as number);
      }
      this.$emit('input', updatedIds);
      this.selectionChange(image);
    },
    isSelected (image: IImageModel): boolean {
      return this.selectedImageIds.some(id => id === image?.id);
    }
  }
});
