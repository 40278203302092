


























import Vue from 'vue';
import { isEqual } from 'lodash';
import { get, dispatch, sync } from 'vuex-pathify';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { IAnnotation, IImageModel, Modality } from '@/api/Models';
import { imageHasAnnotation } from '@/state/x-ray/helpers';

export default Vue.extend({
  props: {
    format: {
      type: Function,
      required: true
    },
    sort: {
      type: Function,
      default: undefined
    }
  },
  data () {
    return {
      deleteIcon: faTrashAlt
    };
  },
  computed: {
    images: get('study/currentModalityImages'),
    selectedAnnotation: sync<IAnnotation>('xRay/selectedAnnotation'),
    generalAnnotations: get<IAnnotation[]>('xRay/generalAnnotations'),
    dentalAnnotations: get<IAnnotation[]>('xRay/dentalAnnotations'),
    annotations (): IAnnotation[] {
      const modality: Modality = this.$store.get('sensor/sensor@modality');
      const annotations = modality === Modality.General ? this.generalAnnotations : this.dentalAnnotations;
      return this.sort ? annotations.sort(this.sort as (a: IAnnotation, b: IAnnotation) => number) : annotations;
    }
  },
  watch: {
    annotations (newAnnotations: IAnnotation[]) {
      const selectedExists = newAnnotations.some(annotation => isEqual(annotation, this.selectedAnnotation));
      if (!selectedExists && newAnnotations.length > 0) {
        this.selectedAnnotation = newAnnotations[0];
      }
    }
  },
  methods: {
    isActive (annotation: IAnnotation): boolean {
      return isEqual(annotation, this.selectedAnnotation);
    },
    onAnnotationClick (annotation: IAnnotation): void {
      this.selectedAnnotation = annotation;
    },
    onDeleteClick (annotation: IAnnotation): Promise<void> {
      return dispatch('xRay/deleteAnnotation', annotation);
    },
    numOfAssociatedImages (annotation: IAnnotation): number {
      const foundImages = this.images.filter((image: IImageModel) => imageHasAnnotation(image, annotation));

      return foundImages.length;
    }
  }
});
