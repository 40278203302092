



















import Vue from 'vue';
import { get, dispatch } from 'vuex-pathify';
import { IDicomServerModel, IImageModel } from '@/api/Models';
import ImagesModalFrame from '@/components/modal/ImagesModalFrame.vue';
import eventBus, { ModalEventNames } from '@/eventBus';
import ColumnInputWrapper from '@/components/form/ColumnInputWrapper.vue';

export default Vue.extend({
  components: { ImagesModalFrame, ColumnInputWrapper },
  data () {
    return {
      selectedServer: undefined as undefined | IDicomServerModel,
      selectedImageIds: [] as number[],
      errorMessage: ''
    };
  },
  computed: {
    servers: get<IDicomServerModel[]>('dicomServer/servers'),
    images: get<IImageModel[]>('study/images'),
    isDisabled (): boolean {
      return !this.selectedServer || !this.selectedImageIds?.length;
    }
  },
  created () {
    if (this.servers.length === 0) {
      this.errorMessage = 'No DICOM servers have been configured';
    }
    this.selectedServer = this.servers[0];
  },
  methods: {
    async onSend (): Promise<void> {
      try {
        await dispatch('dicomServer/postDicomSend', {
          imageIdList: this.selectedImageIds,
          dicomServerId: this.selectedServer?.id
        });
        this.$toasted.success('DICOM Sent');
      } catch (e) {
        this.$toasted.error('Failed to Send');
      } finally {
        eventBus.$emit(ModalEventNames.closeModal);
      }
    }
  }
});
