





















import mixins from 'vue-typed-mixins';
import { dispatch, get } from 'vuex-pathify';
import { IBreedModel, IPatientModel, IUserModel, ClinicType } from '@/api/Models';
import { IStudyListStudy } from '@/api/ExtendedModels';
import { isAppLocal } from '@/helpers/config';
import { BaseStudyTable } from '@/components/mixins/base-study-table';

export default mixins(BaseStudyTable).extend({
  computed: {
    users: get<IUserModel[]>('user/users'),
    studies: get<IStudyListStudy[]>('study/studyList'),
    breeds: get<IBreedModel[]>('breed/breeds'),
    columns (): any[] {
      const localColumns = [
        { label: 'Breed', field: 'breed' },
        { label: 'Species', field: 'species' }
      ];
      return [
        ...this.baseColumns,
        ...isAppLocal() && this.clinicType === ClinicType.Veterinary ? localColumns : [],
        { label: 'User', field: 'user' }
      ];
    },
    rows (): any[] {
      const baseRows = this.createBaseRows(this.studies);

      return baseRows.map(baseRow => {
        const study = this.studies.find(s => s.id === baseRow.studyId);
        if (!study) throw new Error('Error creating table rows');

        const user = this.users.find((u: IUserModel) => study.userId === u.id);
        const patient = this.patients.find((p: IPatientModel) => p.id === baseRow.patientDatabaseId);
        const breed = this.breeds.find((b: IBreedModel) => patient?.breedId === b.id);

        return {
          ...baseRow,
          user: user ? user.username : 'N/A',
          species: patient?.speciesId ? patient.speciesId : 'N/A',
          breed: breed ? breed.name : 'N/A'
        };
      });
    }
  },
  created () {
    dispatch('user/fetchAllUsers');
  },
  methods: {
    async onClick (params: any): Promise<void> {
      await this.onRowClick(this.studies, params);
    }
  }
});
