import Vue from 'vue';
import cornerstoneTools, { State } from 'cornerstone-tools';

import { SwalTitles } from '@/state/enums';
import cornerstone from 'cornerstone-core';
import { IDisplay } from '../images/images-types';

export function resetCursor (element: HTMLElement, state: State): void {
  if (state.svgCursorUrl) {
    window.URL.revokeObjectURL(state.svgCursorUrl);
  }

  state.svgCursorUrl = null;
  element.style.cursor = 'initial';
}

export function isAnyMeasurementVisible (globalToolState: any): boolean {
  const imageIds = Object.keys(globalToolState);

  return imageIds.some(imageId => {
    const imageTools = Object.keys(globalToolState[imageId]);
    return imageTools.some(toolName => {
      const toolState = globalToolState[imageId][toolName].data;
      return toolState.some((measurement: any, index: number) => globalToolState[imageId][toolName].data[index].visible);
    });
  });
}

export function toggleMeasurementsVisibility (globalToolState: any, element: HTMLElement): void {
  const anyVisible = isAnyMeasurementVisible(globalToolState);
  const imageIds = Object.keys(globalToolState);

  imageIds.forEach(imageId => {
    const imageTools = Object.keys(globalToolState[imageId]);
    imageTools.forEach(toolName => {
      const toolState = globalToolState[imageId][toolName].data;
      toolState.forEach((measurement: any, index: number) => {
        globalToolState[imageId][toolName].data[index].visible = !anyVisible;
      });
    });
  });
  cornerstone.updateImage(element);
}

export function clearMeasurements (globalToolState: any, display: IDisplay): void {
  const { image, enabledHtmlElement } = display;
  if (!enabledHtmlElement || !image) {
    throw new Error('Enabled element or loaded image is missing');
  }

  for (const key in globalToolState) {
    if (!key.startsWith(image.imageId)) continue;

    Object.keys(globalToolState[key]).forEach(toolName => {
      globalToolState[key][toolName].data = [];
    });
  }

  cornerstone.updateImage(enabledHtmlElement);
}

export function configureTextMarker (element: HTMLElement, iconLetter?: string): void {
  const configuration = {
    markers: iconLetter ? [iconLetter] : ['F1', 'F2', 'F3', 'F4', 'F5'],
    current: iconLetter || 'F1',
    loop: true,
    ascending: true,
    changeTextCallback: async (data: any, eventData: any, doneChangingTextCallback: Function) => {
      const textMarkerModal = await Vue.swal.fire({
        title: SwalTitles.EditMarker,
        input: 'text',
        confirmButtonText: 'Save'
      });
      doneChangingTextCallback(data, textMarkerModal.value);
    }
  };

  const textMarkerTool = cornerstoneTools.getToolForElement(element, 'TextMarker');
  textMarkerTool.configuration = configuration;
  textMarkerTool.hideDefaultCursor = true;
}

export function createArrowAnnotateCallback (title: SwalTitles) {
  return async (doneChangingTextCallback: Function) => {
    const annotationModal = await Vue.swal.fire({
      title: title,
      input: 'text',
      confirmButtonText: 'Save'
    });

    doneChangingTextCallback(annotationModal.value);
  };
}
export function getAge (dateOfBirth?: Date): string {
  if (!dateOfBirth) return ''; // Handle case where birthDate is not set

  const today = new Date();
  const birthDate = new Date(dateOfBirth);

  // Calculate age in years, months, and days
  const age = {
    years: today.getFullYear() - birthDate.getFullYear(),
    months: today.getMonth() - birthDate.getMonth(),
    days: today.getDate() - birthDate.getDate()
  };

  // Adjust for negative months (due to different days in months)
  if (age.months < 0 || (age.months === 0 && today.getDate() < birthDate.getDate())) {
    age.years--;
    age.months += 12; // Months should be positive
  }

  // Adjust for negative days (due to different days in months)
  if (age.days < 0) {
    const prevMonthLastDay = new Date(today.getFullYear(), today.getMonth(), 0).getDate();
    age.months--;
    age.days = prevMonthLastDay + age.days;
  }

  // Construct the age string
  let ageString = '';
  if (age.years > 0) {
    ageString += `${age.years} ${age.years > 1 ? 'years' : 'year'}`;
  }
  if (age.months > 0) {
    if (ageString !== '') ageString += ' ';
    ageString += `${age.months} ${age.months > 1 ? 'months' : 'month'}`;
  }
  if (age.days > 0) {
    if (ageString !== '') ageString += ' ';
    ageString += `${age.days} ${age.days > 1 ? 'days' : 'day'}`;
  }

  return ageString;
}
