import Vue from 'vue';
import FMSAxios from '@/api/FMSAxios';
import { ActionContext } from 'vuex';
import { IRootState } from '.';
import { commit } from 'vuex-pathify';

export function getById<TState> (path: string, mutation: string) {
  return async function <TModel> (context: ActionContext<TState, IRootState>, id: number): Promise<TModel> {
    const response = await FMSAxios.get(`${path}/${id}`);
    commit(mutation, response.data);
    return response.data;
  };
}

export function getAll (path: string, mutation: string) {
  return async function () {
    const response = await FMSAxios.get(`${path}`);
    commit(mutation, response.data);
  };
}
export async function put<TModel> (path: string, data: TModel, mutation: string): Promise<TModel> {
  const response = await FMSAxios.put(`${path}`, data);
  commit(mutation, response.data);
  return response.data;
}

export async function get<TModel> (path: string, mutation: string): Promise<TModel> {
  const response = await FMSAxios.get(path);
  commit(mutation, response.data);
  return response.data;
}
export async function post<TModel> (path: string, data: TModel, mutation: string): Promise<TModel> {
  const response = await FMSAxios.post(path, data);
  commit(mutation, response.data);
  return response.data;
}

export async function localDownload<TRequest> (path: string, request?: TRequest): Promise<void> {
  let savedPath = '';
  if (request) {
    const response = await FMSAxios.post<string>(path, request);
    savedPath = response.data;
  } else {
    const response = await FMSAxios.get<string>(path);
    savedPath = response.data;
  }

  Vue.toasted.show(`Saved file in ${savedPath}`, {
    closeOnSwipe: true,
    duration: 6000
  });
}
