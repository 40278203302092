

















import Vue from 'vue';
export default Vue.extend({
  props: {
    loading: {
      default: false,
      type: Boolean
    },
    errorMessage: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      isValid: true,
      showHighlights: false
    };
  },
  mounted () {
    this.validate();
  },
  updated () {
    this.validate();
  },
  methods: {
    validate (): boolean {
      const form = this.$refs.form as HTMLFormElement;
      this.isValid = form.checkValidity();
      return this.isValid;
    },
    validateWithHighlights (): boolean {
      this.showHighlights = true;
      return this.validate();
    }
  }
});
