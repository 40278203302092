/* eslint-disable @typescript-eslint/no-explicit-any */

// returns a function that could be used to determine which lines should be draggable(static) depending on which lines are already drawn
function staticSync (staticList: any): any {
  // ensure staticList list has correct format with this try/catch
  try {
    if (!Array.isArray(staticList)) {
      throw new Error('Incorrect dependencyList format sent to dependencySync, returning null');
    }

    staticList.forEach(item => {
      if (!item.step || !Array.isArray(item.staticOn)) {
        throw new Error('Incorrect dependencyList format sent to dependencySync, returning null');
      }
    });
  } catch (err) {
    console.error(err);
    return null;
  }

  const steps = staticList.map(item => item.step);

  return (toolData: any): void => {
    // filter out lines cannot be potentially static
    const relevantLines = toolData.data
      .filter((line: any) => steps.includes(line.step));
    const toolDataSteps = toolData.data.map((line: any) => line.step);

    relevantLines.forEach((line: any) => {
      // find the lines that needed to have been drawn for the line to be static
      const isStatic = staticList.find(item => item.step === line.step).staticOn
      // check if any of these lines have been drawn
        .some((step: any) => toolDataSteps.includes(step));

      if (isStatic) {
        line.static = true;
      } else {
        line.static = false;
      }
    });
  };
}

export default staticSync;
