











import Vue from 'vue';
import { throttle } from 'lodash';
import { Prop } from 'vue/types/options';
import cornerstone, { EVENTS } from 'cornerstone-core';
import { commit, dispatch, get } from 'vuex-pathify';

import store from '@/state';
import Overlay from './Overlay.vue';
import { isAppCloud } from '@/helpers/config';
import { getScaling } from '@/helpers/scale';
import { IDisplay, IInsertElementPayload } from '@/state/viewer/images/images-types';

const saveState = throttle(async (evt: any) => {
  const event = evt as CustomEvent;
  if (event.detail?.toolName === 'Crop') return;

  try {
    await dispatch('caseViewer/images/saveViewport');
    await dispatch('caseViewer/putState');
  } catch (e) {
    const { enabledHtmlElement, image } = store.state.viewer.images.activeDisplay;
    if (!enabledHtmlElement && !image) return;
    throw e;
  }
}, 2500);

export default Vue.extend({
  components: {
    Overlay
  },
  props: {
    display: {
      type: Object as Prop<IDisplay>,
      required: true
    }
  },
  computed: {
    activeDisplay: get<IDisplay>('caseViewer/images/activeDisplay'),
    isSingleImage: get<boolean>('caseViewer/images/isSingleImage')
  },
  watch: {
    async display (newDisplay: IDisplay, oldDisplay: IDisplay) {
      if (newDisplay.image && newDisplay.image.imageId !== oldDisplay.image?.imageId) {
        if (!newDisplay.enabledHtmlElement) {
          this.insertElement();
          await this.$nextTick(); // allow this.display to be updated after running insertElement
        }
        this.removeSaveEvent(); // prevent viewport from being overwritten before the sync event runs

        this.scale();
        this.dispayImage();
        this.resizeImage();
        this.attachSyncEvent();
        if (!this.isSingleImage) {
          await dispatch('caseViewer/images/saveViewport', this.display);
        }

        if (newDisplay.enabledHtmlElement === this.activeDisplay.enabledHtmlElement) this.attachSaveEvent();
      }
    },
    activeDisplay (newActiveDisplay: IDisplay, oldActiveDisplay: IDisplay) {
      const newActiveElement = newActiveDisplay.enabledHtmlElement;
      const oldActiveElement = oldActiveDisplay.enabledHtmlElement;
      const element = this.display.enabledHtmlElement;

      if (newActiveElement && element && newActiveElement === element) {
        this.attachSaveEvent();
        return;
      }

      if (oldActiveElement && element && oldActiveElement === element) {
        this.removeSaveEvent();
      }
    }
  },
  mounted () {
    this.scale();

    const container = this.$refs.container as HTMLElement;
    cornerstone.enable(container);
    this.insertElement();

    if (this.display.image) {
      this.attachSyncEvent();
      this.dispayImage();
    }
  },
  methods: {
    insertElement (): void {
      const payload: IInsertElementPayload = {
        row: this.display.row,
        column: this.display.column,
        element: this.$refs.container as HTMLElement
      };
      commit('caseViewer/images/insertElement', payload);
    },
    scale (): void {
      const container = this.$refs.container as HTMLElement;
      if (isAppCloud()) {
        container.style.height = '100%';
        container.style.width = '100%';
        return;
      }

      // manually scale container to make sure it's already scaled when we run cornerstone.enable
      const { height: parentHeight, width: parentWidth } = container.parentElement?.getBoundingClientRect() as DOMRect;
      container.style.height = `${parentHeight}px`;
      container.style.width = `${parentWidth}px`;

      // cancel out the global scaling since we've already scaled
      const { widthScale, heightScale } = getScaling();
      container.style.transform = `scale(${1 / widthScale}, ${1 / heightScale})`;
    },
    dispayImage (): void {
      const container = this.$refs.container as HTMLElement;
      cornerstone.displayImage(container, this.display.image);
    },
    attachSaveEvent (): void {
      const container = this.$refs.container as HTMLElement;
      //container.addEventListener(EVENTS.IMAGE_RENDERED, saveState);
    },
    removeSaveEvent (): void {
      const container = this.$refs.container as HTMLElement;
      //container.removeEventListener(EVENTS.IMAGE_RENDERED, saveState);
    },
    attachSyncEvent (): void {
      const container = this.$refs.container as HTMLElement;
      container.addEventListener(cornerstone.EVENTS.IMAGE_RENDERED, async () => {
        await dispatch('caseViewer/images/syncViewportAndCrop', this.display);
      }, { once: true });
    },
    resizeImage (): void {
      const container = this.$refs.container as HTMLElement;
      cornerstone.resize(container, !this.isSingleImage);
    }
  }
});
