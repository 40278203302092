import Vue from 'vue';
import auth from '@websanova/vue-auth/dist/v2/vue-auth.esm.js';
import driverAuthBearer from '@websanova/vue-auth/dist/drivers/auth/bearer.esm.js';
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js';
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js';
import router from '@/router';
import FMSAxios from '@/api/FMSAxios';

export default function vueAuth (): void {
  Vue.use(auth, {
    plugins: {
      http: FMSAxios,
      router: router
    },
    drivers: {
      auth: driverAuthBearer,
      http: driverHttpAxios,
      router: driverRouterVueRouter
    },
    options: {
      loginData: {
        fetchUser: false,
        staySignedIn: false
      },
      authRedirect: { name: 'Logout' },
      tokenDefaultKey: 'token',
      fetchData: { enabled: false },
      refreshData: { enabled: false }
    }
  });
}
