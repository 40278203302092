import { once } from 'lodash';
import { ActionContext, Module, Store } from 'vuex';
import { make, commit, dispatch } from 'vuex-pathify';
import { IRootState } from '..';
import { post, put } from '../restfulState';
import { IClinicModel, IEmailConfigModel, IEmailSendRequest } from '@/api/Models';
import FMSAxios from '@/api/FMSAxios';

export interface IEmailState {
  config?: IEmailConfigModel;
}

const _state: IEmailState = {
  config: undefined
};

const email: Module<IEmailState, IRootState> = {
  state: _state,
  mutations: {
    ...make.mutations(_state)
  },
  getters: {
    getIsConfigValid (state): boolean {
      return !!state.config && !!state.config.password;
    }
  },
  actions: {
    initialize: once(async (context: ActionContext<IEmailState, IRootState>, store: Store <IRootState>) => {
      await dispatch('email/fetch');

      store.watch((state) => {
        return {
          clinicId: state.clinic.clinic?.id
        };
      }, (newState, oldState) => {
        if (newState.clinicId !== oldState.clinicId) {
          dispatch('email/fetch');
        }
      });
    }),
    async fetch (): Promise<void> {
      const clinic: IClinicModel = this.get('clinic/clinic');
      const response = await FMSAxios.get<IEmailConfigModel[]>(`/emailConfig?ClinicId=${clinic.id}`);
      commit('email/setConfig', response.data[0]);
    },
    updateEmailConfig (context, newConfig: IEmailConfigModel): Promise<IEmailConfigModel> {
      const currentConfig = context.state.config;

      if (currentConfig) {
        const mixedConfig = { ...currentConfig, ...newConfig };
        return put('/emailConfig', mixedConfig, 'email/setConfig');
      }
      return post('/emailConfig', newConfig, 'email/setConfig');
    },
    async postEmail (context, payload: IEmailSendRequest): Promise<void> {
      await FMSAxios.post('emailConfig/send', payload);
    },
    async testEmail (context, configId: number): Promise<void> {
      await FMSAxios.post(`emailConfig/send/${configId}/test`);
    }
  },
  namespaced: true
};

export default email;
