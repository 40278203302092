/* eslint-disable @typescript-eslint/no-explicit-any */
import { external, EVENTS, importInternal, store } from 'cornerstone-tools';
import setHandlesPosition from './setHandlesPosition';
import getActiveTool from '../getActiveTool';

const BaseAnnotationTool = importInternal('base/BaseAnnotationTool');
const { state } = store;

export default function (
  mouseEventData: any,
  toolName: string,
  data: any,
  handle: any,
  doneMovingCallback: any,
  preventHandleOutsideImage?: any
): void {
  const { element, image, buttons } = mouseEventData;
  const distanceFromTool = {
    x: handle.x - mouseEventData.currentPoints.image.x,
    y: handle.y - mouseEventData.currentPoints.image.y
  };

  function _dragCallback (event: any): any {
    const eventData = event.detail;

    handle.hasMoved = true;
    if (handle.index === undefined || handle.index === null) {
      handle.x = eventData.currentPoints.image.x + distanceFromTool.x;
      handle.y = eventData.currentPoints.image.y + distanceFromTool.y;
    } else {
      setHandlesPosition(handle, eventData, data, distanceFromTool);
    }

    if (preventHandleOutsideImage) {
      handle.x = Math.max(handle.x, 0);
      handle.x = Math.min(handle.x, eventData.image.width);

      handle.y = Math.max(handle.y, 0);
      handle.y = Math.min(handle.y, eventData.image.height);
    }

    data.invalidated = true;

    external.cornerstone.updateImage(element);

    const activeTool = getActiveTool(element, buttons, 'mouse');
    if (activeTool instanceof BaseAnnotationTool) {
      activeTool.updateCachedStats(image, element, data);
    }

    const modifiedEventData = {
      toolName,
      toolType: toolName, // Deprecation notice: toolType will be replaced by toolName
      element,
      measurementData: data
    };

    external.cornerstone.triggerEvent(
      element,
      EVENTS.MEASUREMENT_MODIFIED,
      modifiedEventData
    );
  }

  handle.active = true;
  handle.moving = true;
  state.isToolLocked = true;

  element.addEventListener(EVENTS.MOUSE_DRAG, _dragCallback);
  element.addEventListener(EVENTS.TOUCH_DRAG, _dragCallback);

  const currentImage = external.cornerstone.getImage(element);
  function imageRenderedHandler (): void {
    const newImage = external.cornerstone.getImage(element);

    // Check if the rendered image changed during measurement modifying and stop it if so
    if (newImage.imageId !== currentImage.imageId) {
      interactionEndCallback();
    }
  }

  // Bind the event listener for image rendering
  element.addEventListener(
    external.cornerstone.EVENTS.IMAGE_RENDERED,
    imageRenderedHandler
  );

  function interactionEndCallback (): void {
    handle.active = false;
    state.isToolLocked = false;

    element.removeEventListener(
      external.cornerstone.EVENTS.IMAGE_RENDERED,
      imageRenderedHandler
    );

    element.removeEventListener(EVENTS.MOUSE_DRAG, _dragCallback);
    element.removeEventListener(EVENTS.MOUSE_UP, interactionEndCallback);
    element.removeEventListener(EVENTS.MOUSE_CLICK, interactionEndCallback);

    element.removeEventListener(EVENTS.TOUCH_DRAG, _dragCallback);
    element.removeEventListener(EVENTS.TOUCH_DRAG_END, interactionEndCallback);
    element.removeEventListener(EVENTS.TAP, interactionEndCallback);

    external.cornerstone.updateImage(element);

    if (typeof doneMovingCallback === 'function') {
      doneMovingCallback();
    }
  }

  element.addEventListener(EVENTS.MOUSE_UP, interactionEndCallback);
  element.addEventListener(EVENTS.MOUSE_CLICK, interactionEndCallback);

  element.addEventListener(EVENTS.TOUCH_DRAG_END, interactionEndCallback);
  element.addEventListener(EVENTS.TAP, interactionEndCallback);
}
