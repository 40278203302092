





















import Vue from 'vue';
import { dispatch, get } from 'vuex-pathify';
import ImagesModalFrame from '@/components/modal/ImagesModalFrame.vue';
import { IImageModel, IAnnotation } from '@/api/Models';
import AnnotationInput from './AnnotationInput.vue';
import ViewInput from './ViewInput.vue';
import eventBus, { ModalEventNames } from '@/eventBus';

export default Vue.extend({
  components: {
    ImagesModalFrame,
    AnnotationInput,
    ViewInput
  },
  props: {
    sendCallback: {
      type: Function,
      default: () => undefined
    }
  },
  data () {
    return {
      selectedAnnotation: '',
      selectedView: '',
      selectedImageIds: [] as number[],
      errorMessage: ''
    };
  },
  computed: {
    images: get<IImageModel[]>('study/images'),
    selectedImage (): IImageModel | undefined {
      return this.images.find(image => image.id === this.selectedImageIds[0]);
    },
    isSelectedGeneral (): boolean {
      return this.selectedImage?.modality === 'General';
    }
  },
  mounted () {
    if (this.images.length === 0) {
      throw new Error("Can't open this window with no images in the study");
    }
    const firstImage = this.images[0];

    this.selectedAnnotation = firstImage.bodyPart;
    this.selectedView = firstImage.bodyPartView;
    this.selectedImageIds = [firstImage.id as number];
  },
  methods: {
    async onSend (): Promise<void> {
      this.errorMessage = '';

      if (!this.selectedAnnotation || (this.isSelectedGeneral && this.selectedView === '')) {
        this.errorMessage = 'Please ensure all inputs are filled out';
        return;
      }

      const annotation: IAnnotation = {
        bodyPart: this.selectedAnnotation,
        bodyPartView: this.isSelectedGeneral ? this.selectedView : undefined
      };

      try {
        await dispatch('study/editImageAnnotations', {
          imageId: this.selectedImageIds[0],
          annotation
        });
        eventBus.$emit(ModalEventNames.closeModal);
        this.$toasted.success('Annotation Updated');

        this.sendCallback();
      } catch (e) {
        this.errorMessage = e.message;
      }
    },
    onSelection (selectedImage: IImageModel): void {
      this.selectedAnnotation = selectedImage.bodyPart;
      this.selectedView = selectedImage.bodyPartView;
    }
  }
});
