import router from '@/router';
import { Route } from 'vue-router';
import { isEqual } from 'lodash';

export enum NavigationMethods {
  push = 'push',
  replace ='replace'
}

export function navigate (route: Route, method: NavigationMethods, path: string, query = {}): void {
  const isPathEqual = isEqual(route.path, path);
  const isQueryEqual = isEqual(route.query, query);

  if (isPathEqual && isQueryEqual) return;

  switch (method) {
    case NavigationMethods.push:
      router.push({ path, query });
      break;
    case NavigationMethods.replace:
      router.replace({ path, query });
      break;
    default:
      break;
  }
}
