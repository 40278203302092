



















import Vue from 'vue';
import dcmjs from 'dcmjs';
import { get } from 'vuex-pathify';
import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';

import ModalFrame from '@/components/modal/ModalFrame.vue';
import { IDisplay } from '@/state/viewer/images/images-types';

interface ITableItem {
  tag: string;
  type: string;
  value: string;
  name: string;
}

export default Vue.extend({
  components: {
    ModalFrame,
    VueGoodTable
  },
  data () {
    return {
      columns: [
        { label: 'Tag', field: 'tag' },
        { label: 'Type', field: 'type' },
        { label: 'Value', field: 'value' },
        { label: 'Name', field: 'name' }
      ]
    };
  },
  computed: {
    getMetadata: get('viewer/metadata/imageMetadata'),
    activeDisplay: get<IDisplay>('viewer/images/activeDisplay'),
    metadata (): any {
      return this.getMetadata(this.activeDisplay.image?.imageId);
    },
    tags (): ITableItem[] {
      if (!this.metadata) return [];

      const tagNames = Object.keys(this.metadata);
      const tagDicionary = Object.values(dcmjs.data.DicomMetaDictionary.dictionary);

      return tagNames
        .map(tagName => {
          const dictionaryEntry: any = tagDicionary.find((item: any) => item.name === tagName);
          const value = this.tagValueToString(this.metadata[tagName]);

          const tableItem: ITableItem = {
            name: tagName,
            tag: dictionaryEntry?.tag,
            type: dictionaryEntry?.vr,
            value
          };
          return tableItem;
        })
        .filter(tableItem => !!tableItem.tag);
    }
  },
  methods: {
    tagValueToString (tagValue: any): string {
      if (tagValue?.Alphabetic) {
        return tagValue.Alphabetic;
      }
      if (Array.isArray(tagValue)) {
        return tagValue.join(', ');
      }
      return tagValue;
    }
  }
});
