import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Login from '@/screens/login/Login.vue';
import GeneralXray from '@/screens/general-x-ray/General-X-Ray.vue';
import DentalXray from '@/screens/dental x-ray/Dental-X-Ray.vue';
import Registration from '@/screens/registration/Registration.vue';
import Studylist from '@/screens/studylist/Studylist.vue';
import Viewer from '@/screens/viewer/Viewer.vue';
import CaseViewer from '@/screens/viewer/CaseViewer.vue';

import Report from '@/screens/report/Report.vue';
import Settings from '@/screens/settings/Settings.vue';
import NotFound from '@/screens/notfound/NotFound.vue';
import store from '@/state';
import { dispatch } from 'vuex-pathify';
import { isAppLocal } from '../helpers/config';
import XLogReportVue from '../screens/xlogreport/XLogReport.vue';
import Cases from '@/screens/cases/Cases.vue';
import EditCases from '@/screens/cases/EditCases.vue';

Vue.use(VueRouter);

const localRoutes: RouteConfig[] = [
  {
    path: '/general',
    name: 'General',
    component: GeneralXray,
    meta: { auth: true }
  },
  {
    path: '/dental',
    name: 'Dental',
    component: DentalXray,
    meta: { auth: true }
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration,
    meta: { auth: true, title: 'Patient Registration' }
  }
];

const routes: RouteConfig[] = [
  { path: '/', redirect: { name: 'Login' } },
  { path: '/signin', redirect: { name: 'Login' } },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/viewer',
    name: 'Viewer',
    component: Viewer,
    beforeEnter: async (to, from, next): Promise<void> => {
      if (!from.name) {
        next('/studylist'); // must go through study list first to populate the study state
        return;
      }
      const promises = [dispatch('viewer/fetchState'), dispatch('viewer/initialize', store), dispatch('viewer/metadata/fetchMetadata')];
      await Promise.all(promises);
      next();
    }
  },
  {
    path: '/caseviewer',
    name: 'CaseViewer',
    component: CaseViewer,
    beforeEnter: async (to, from, next): Promise<void> => {
      if (!from.name) {
        next('/studylist'); // must go through study list first to populate the study state
        return;
      }
      const promises = [dispatch('caseViewer/fetchState'), dispatch('caseViewer/initialize', store), dispatch('caseViewer/metadata/fetchMetadata')];
      await Promise.all(promises);
      next();
    }
  },
  {
    path: '/studylist',
    name: 'Studylist',
    component: Studylist,
    meta: { auth: true, title: 'Study List' }
  },
  {
    path: '/xlogreport',
    name: 'XLogReport',
    component: XLogReportVue,
    meta: { auth: true, title: 'X-Ray Log Report' }
  },
  {
    path: '/cases',
    name: 'cases',
    component: Cases,
    meta: { auth: true, title: 'RAD Cases' }
  },
  {
    path: '/editcases/:caseId',
    name: 'Editcases',
    component: EditCases,
    meta: { auth: true, title: 'Edit RAD Cases' }
  },
  {
    path: '/report',
    name: 'Report',
    component: Report,
    meta: { auth: true },
    beforeEnter: (to, from, next): void => {
      if (!from.name) {
        next('/studylist'); // must go through study list first
      }
      next();
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: { auth: true, title: 'Settings' }
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter: async (to, from): Promise<void> => {
      await dispatch('auth/logout', from.name);
    }
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  },
  ...isAppLocal() ? localRoutes : []
];

const router = new VueRouter({
  routes,
  mode: 'history',
  base: isAppLocal() ? '/vue' : '/'
});

router.beforeEach(async (to, from, next) => {
  const token = Vue.auth.token();

  if (to.name === 'Login') {
    next();
    return;
  }

  if (token) {
    await dispatch('user/initialize', store);
    await dispatch('permissionProfile/initialize');
    await dispatch('clinic/initialize');
    dispatch('study/initialize', store);
    dispatch('dicomServer/initialize', store);
    dispatch('patient/initialize', store);
    dispatch('owner/initialize', store);
    dispatch('email/initialize', store);
    dispatch('radcase/initialize', store);
    await dispatch('breed/initialize', store);

    if (isAppLocal()) {
      await dispatch('sensor/initialize', store);
      await dispatch('generator/initialize', store);
      await dispatch('xRay/initialize', store);
    }
  }
  next();
});

export default router;
