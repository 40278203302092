










import Vue from 'vue';
import { get } from 'vuex-pathify';
import { navigate, NavigationMethods } from '@/router/helpers';
import { IStudieModel } from '@/api/Models';

export default Vue.extend({
  computed: {
    selectedStudy: get<IStudieModel>('study/selectedStudy'),
    selectedStudyHasImages: get('study/selectedStudyHasImages'),
    isDisabled (): boolean {
      return !this.selectedStudyHasImages || !this.selectedStudy;
    }
  },
  methods: {
    onClick(): void {
      navigate(this.$route, NavigationMethods.push, '/caseviewer');
    }
  }
});
