var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-list"},[_c('VueGoodTable',{attrs:{"style-class":"study-table","rows":_vm.rows,"columns":_vm.columns,"row-style-class":_vm.rowStyleClass,"search-options":{
      enabled: true,
    },"pagination-options":{
      enabled: true,
      mode: 'pages',
      perPageDropdown: [10],
      dropdownAllowAll: false,
    },"sort-options":{
      enabled: true,
    }},on:{"on-row-click":function($event){return _vm.onRowClick($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }