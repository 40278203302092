import FMSAxios from '@/api/FMSAxios';
import { DirectiveBinding } from 'vue/types/options';

export default {
  async bind (el: HTMLElement, binding: DirectiveBinding) {
    if (binding.value) {
      const response = await FMSAxios.get(binding.value, { responseType: 'blob' });

      el.setAttribute('src', window.URL.createObjectURL(response.data));
    }
  }
};
