











import Vue from 'vue';
import { dispatch, get } from 'vuex-pathify';
import { IClinicModel, ImageFileType } from '@/api/Models';
import { SwalTitles } from '@/state/enums';
export default Vue.extend({
  computed: {
    clinic: get<IClinicModel>('clinic/clinic')
  },
  methods: {
    async confirm () {
      const result = await Vue.swal.fire({
        title: SwalTitles.BulkDownload,
        showCancelButton: true
      });
      return result;
    },
    async onDicomClick (): Promise<void> {
      const result = await this.confirm();
      if (result.isConfirmed) {
        const zip: Blob = await dispatch('clinic/bulkDownloadZip', ImageFileType.Dcm);
        this.processZip(zip);
      }
    },
    async onJpegClick (): Promise<void> {
      const result = await this.confirm();
      if (result.isConfirmed) {
        const zip: Blob = await dispatch('clinic/bulkDownloadZip', ImageFileType.Jpg);
        this.processZip(zip);
      }
    },
    processZip (zip: Blob): void {
      const blobUrl = URL.createObjectURL(zip);
      const anchor = document.createElement('a');
      anchor.href = blobUrl;
      anchor.download = `${this.clinic.name} images.zip`;
      anchor.click();
      anchor.remove();
    }
  }
});
