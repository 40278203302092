














import { get } from 'vuex-pathify';
import mixins from 'vue-typed-mixins';

import { BaseStudyTable } from '@/components/mixins/base-study-table';
import { IStudieModel } from '@/api/Models';

export default mixins(BaseStudyTable).extend({
  computed: {
    studies: get<IStudieModel[]>('study/workList'),
    rows () {
      return this.createBaseRows(this.studies);
    }
  },
  methods: {
    async onClick (params: any): Promise<void> {
      await this.onRowClick(this.studies, params);
    }
  }
});
