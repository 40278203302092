































import Vue from 'vue';
import { dispatch, get } from 'vuex-pathify';
import { generalAnnotationToString } from '@/state/x-ray/helpers';
import AnnotationList from '@/components/x-ray/AnnotationList.vue';
import HighlightMap from '@/components/x-ray/map/HighlightMap.vue';
import ImagePreview from '@/components/x-ray/images/ImagePreview.vue';
import Thumbnails from '@/components/x-ray/images/Thumbnails.vue';
import StartXRayButton from '@/components/x-ray/StartXRayButton.vue';
import XRayPageTemplate from '@/components/x-ray/X-Ray-Page-Template.vue';
import SizeSelection from './components/SizeSelection.vue';
import ViewSelection from './components/ViewSelection.vue';
import AddAnnotationButton from './components/AddAnnotationButton.vue';
import TopBar from './components/top-bar/TopBar.vue';
import TopBarContainer from '@/components/x-ray/TopBarContainer.vue';
import { IAnnotation, Species } from '@/api/Models';

export default Vue.extend({
  components: {
    SizeSelection,
    ViewSelection,
    AnnotationList,
    ImagePreview,
    Thumbnails,
    TopBar,
    TopBarContainer,
    StartXRayButton,
    HighlightMap,
    AddAnnotationButton,
    XRayPageTemplate
  },
  computed: {
    generatorPower: get('generator/status@pw'),
    selectedPart: get('xRay/selectedPart'),
    selectedView: get('xRay/selectedView'),
    study: get('study/selectedStudy'),
    selectedSize: get('xRay/selectedSize'),
    patientSpecies: get<Species>('patient/patient@speciesId'),
    isAddAnnotationDisabled (): boolean {
      return !this.selectedView || !this.selectedSize;
    },
    showMap (): boolean {
      return !!this.mapName;
    },
    mapName (): string | undefined {
      switch (this.patientSpecies) {
        case Species.Feline:
          return 'CatMap';
        case Species.Canine:
          return 'DogMap';
        case Species.Equine:
          return 'HorseMap';
        default:
          return undefined;
      }
    }
  },
  async created () {
    await this.ensureGeneratorPower();
  },
  methods: {
    async ensureGeneratorPower (): Promise<void> {
      if (!this.generatorPower) {
        await dispatch('generator/togglePower', true);
      }
    },
    onPartSelected (part: string): void {
      this.$store.commit('xRay/setSelectedPart', part);
    },
    formatAnnotation (annotation: IAnnotation): string {
      return generalAnnotationToString(annotation);
    }
  }
});
