













import Vue from 'vue';
import Footer from './Footer.vue';
import Sidebar from './Sidebar.vue';
import Header from './Header.vue';

export default Vue.extend({
  name: 'Home',
  components: {
    Footer,
    Sidebar,
    Header
  },
  props: {
    showSidebar: {
      type: Boolean,
      default: false
    }
  }
});
