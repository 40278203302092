





















import Vue from 'vue';
import ModalFrame from '@/components/modal/ModalFrame.vue';
import eventBus, { ModalEventNames } from '@/eventBus';
import { get, dispatch, commit } from 'vuex-pathify';
import { IImageModel } from '@/api/Models';

export default Vue.extend({

  components: {
    ModalFrame
  },

  props: {
    errorMessage: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      rejectionReasons: [
        'Wrong Annotations',
        'Wrong Technique',
        'Over-Exposure',
        'Low Exposure',
        'Bad Colimation',
        'Bad Image',
        'Low Quality'
      ]
    };
  },

  computed: {
    image: get<IImageModel>('study/selectedImage'),
    images: get<IImageModel[]>('study/currentModalityImages')
  },

  methods: {
    async rejectImage (rejectionReason: string): Promise<void> {
      await dispatch('study/rejectImage', { rejectionReason, imageId: this.image.id });
      // select first image that is not the rejected image
      commit('study/setSelectedImage', this.images.find(image => image.id !== this.image.id));
      eventBus.$emit(ModalEventNames.closeModal);
    }
  }
});

