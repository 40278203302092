













































import Vue from 'vue';
import ValidatedForm from '@/components/form/ValidatedForm.vue';

import FormRow from '@/components/form/FormRow.vue';
import { IClinicModel } from '@/api/Models';
import { dispatch, get } from 'vuex-pathify';
import { trimCommas } from './helpers';
import eventBus from '@/eventBus';
import addDoctorModal from '@/screens/settings/components/AddDoctorModal.vue';
import ClinicProfileList from '@/screens/settings/components/lists/ClinicProfileList.vue';

export default Vue.extend({
  components: { ValidatedForm, ClinicProfileList, FormRow },
  data () {
    return {
      form: {
        name: '',
        address: '',
        phone: '',
        email: '',
        aeTitle: ''
      },
      loading: false,
      errorMessage: '',
      resetOnComplete: false
    };
  },
  computed: {
    clinic: get<IClinicModel>('clinic/clinic')
  },
  created () {
    this.setFormData();
  },
  methods: {
    setFormData (): void {
      this.form = {
        name: this.clinic?.name,
        address: this.clinic?.address,
        phone: this.clinic?.phone,
        aeTitle: this.clinic?.aeTitle,
        email: this.clinic?.email
      };
    },
    async onClick (): Promise<void> {
      this.errorMessage = '';
      this.loading = true;
      this.resetOnComplete = false;

      try {
        const address = trimCommas(this.form.address as string);
        const clinicPayload: IClinicModel = { ...this.clinic, ...this.form, address };
        await dispatch('clinic/putClinic', clinicPayload);

        this.setFormData();
      } catch (error) {
        this.errorMessage = error?.response?.data?.message ? error.response.data.message : error.message;
      } finally {
        this.loading = false;
        this.resetOnComplete = true;
      }
    },
    OpenDoctorModal (buttonType: string): void {
      eventBus.launchModal(addDoctorModal , { buttonType });
    }
  }
});
