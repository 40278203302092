




















import { get } from 'vuex-pathify';
import mixins from 'vue-typed-mixins';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import ViewImagesButton from '@/components/ViewImagesButton.vue';
import { JpegImageLoader } from '@/components/mixins/jpeg-image-loader';
import RejectImageButton from './RejectImageButton.vue';
import { IImageModel } from '@/api/Models';
export default mixins(JpegImageLoader).extend({
  components: {
    RejectImageButton,
    ViewImagesButton
  },
  data () {
    return {
      openIcon: faEye
    };
  },
  computed: {
    image: get<IImageModel>('study/selectedImage'),
    annotation (): string {
      const image = this.image;
      if (!image) {
        return '';
      }
      return this.$store.get('xRay/imageDescription', image);
    }
  }
});
