






import Vue from 'vue';
import { dispatch } from 'vuex-pathify';
import { SwalTitles } from '@/state/enums';

export default Vue.extend({
  props: {
    onClickCallback: {
      type: Function,
      default: () => undefined
    }
  },
  methods: {
    async onClick (): Promise<void> {
      let selectedOptionsArray: string[] = [];

      const { isConfirmed } = await this.$swal.fire({
        title: SwalTitles.CreateXLogReport,
        html: this.generateCheckboxHtml(),
        showCancelButton: true,
        confirmButtonText: 'Download Report',
        preConfirm: () => {
          const checkboxes = document.querySelectorAll('input[type="checkbox"]:checked') as NodeListOf<HTMLInputElement>;
          selectedOptionsArray = Array.from(checkboxes).map((checkbox) => checkbox.value);
          return selectedOptionsArray;
        }
      });

      if (isConfirmed) {
        await dispatch('study/getXLogReports', { selectedOptionsArray: selectedOptionsArray });
        await this.onClickCallback();
      }
    },
    generateCheckboxHtml (): string {
      // Customize this function to generate your checkbox HTML template
      const tags = [
        '(0008,0020) StudyDate',
        '(0008,0021) SeriesDate',
        '(0008,0022) AcquisitionDate',
        '(0008,0080) InstitutionName',
        '(0008,1030) StudyDescription',
        '(0008,103E) SeriesDescription',
        '(0008,1070) OperatorsName',
        '(0010,0010) PatientName',
        '(0010,0020) PatientID',
        '(0010,0040) PatientSex',
        '(0010,1000) OtherPatientIDs',
        '(0010,2201) PatientSpeciesDescription',
        '(0010,2297) ResponsiblePerson',
        '(0010,2298) ResponsiblePersonRole',
        '(0018,0060) KVP',
        '(0018,1150) ExposureTime',
        '(0018,1151) XRayTubeCurrent',
        '(0018,1152) Exposure'
      ];

      const options = tags.map(tag => {
        const [value, caption] = tag.split(' ');
        return { value, caption };
      });
      return `<div class="checkbox-container">${options.map(option => `
        <label><input type="checkbox" value="${option.value}"> ${option.caption}</label><br>
      `).join('')}</div>`;
    }
  }
});
