import { render, staticRenderFns } from "./Overlay.vue?vue&type=template&id=238084fe&scoped=true&"
import script from "./Overlay.vue?vue&type=script&lang=ts&"
export * from "./Overlay.vue?vue&type=script&lang=ts&"
import style0 from "./Overlay.vue?vue&type=style&index=0&id=238084fe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "238084fe",
  null
  
)

export default component.exports