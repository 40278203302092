











import Vue from 'vue';
import { get, call } from 'vuex-pathify';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

export default Vue.extend({
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      plusIcon: faPlus
    };
  },
  computed: {
    selectedPart: get('xRay/selectedPart'),
    study: get('study/selectedStudy'),
    isDisabled (): boolean {
      return !this.selectedPart || !this.study || this.disabled;
    }
  },
  methods: {
    onClick: call('xRay/addAnnotation')
  }
});
