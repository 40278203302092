

















import Vue from 'vue';
import { commit, get } from 'vuex-pathify';
import { IClinicModel, IUserModel } from '@/api/Models';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default Vue.extend({
  data () {
    return {
      faTimes
    };
  },
  computed: {
    clinics: get<IClinicModel[]>('clinic/clinics'),
    clinic: get<IClinicModel | undefined>('clinic/clinic'),
    user: get<IUserModel | undefined>('user/user'),
    title (): string | undefined {
      return this.$route.meta.title || 'Page';
    },
    showClinicReset (): boolean {
      return this.user?.clinicId !== this.clinic?.id;
    }
  },
  methods: {
    onClinicResetClick (): void {
      const userClinic = this.clinics.find(clinic => this.user?.clinicId === clinic.id);
      if (!userClinic) throw new Error('Clinic belonging to user was not found');

      commit('clinic/setClinic', userClinic);
    }
  }
});
