






import { IAnnotation } from '@/api/Models';
import Vue from 'vue';
import { call, get } from 'vuex-pathify';

export default Vue.extend({
  data () {
    return {
      allSelected: false
    };
  },
  computed: {
    annotations: get<IAnnotation[]>('xRay/annotations')
  },
  watch: {
    annotations (annotationList: IAnnotation[]) {
      if (annotationList.length === 0 && this.allSelected) {
        this.allSelected = false;
      }
    }
  },
  methods: {
    selectAll: call('xRay/postAllDentalAnnotations'),
    deleteAll: call('xRay/deleteAllDentalAnnotations'),
    onClick (): void {
      if (this.allSelected) {
        this.deleteAll(undefined);
      } else {
        this.selectAll(undefined);
      }
      this.allSelected = !this.allSelected;
    }
  }
});
