import { Handle, Handles } from 'cornerstone-tools';
import { calculateSlope, calculateBreakingPoint, calculateMidpoint } from './math';

function getParallelLineEnd (handles: Handles, point: Handle): Handle {
  // first line calculations
  const firstSlope = calculateSlope(handles);
  const firstMidpoint = calculateMidpoint(handles);

  // perpendicular line calculations
  const perpendicularSlope = -(1 / firstSlope);
  const perpendicularBreak = calculateBreakingPoint(firstMidpoint, perpendicularSlope);

  // parallel line calculations
  const parallelBreak = calculateBreakingPoint(point, firstSlope);
  // midpoint
  const parallelMidX = (perpendicularBreak - parallelBreak) / (firstSlope + (1 / firstSlope));
  const parallelMidY = (firstSlope * parallelMidX) + parallelBreak;
  // endpoint
  const parallelEndX = parallelMidX - (point.x - parallelMidX);
  const parallelEndY = parallelMidY + (parallelMidY - point.y);
  return {
    x: parallelEndX,
    y: parallelEndY
  };
}

export default getParallelLineEnd;
