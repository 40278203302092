



















import Vue from 'vue';
import { commit, get } from 'vuex-pathify';
import { VueAutosuggest } from 'vue-autosuggest';
import { IVueAutoSuggestResult } from '@/plugins/vueAutoSuggest/interfaces';
import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { IClinicProfilesModel } from '@/api/Models';

interface ISuggestions {
  data: IClinicProfilesModel[];
}

export default Vue.extend({
  components: {
    VueAutosuggest
  },
  data () {
    return {
      input: '',
      addIcon: faPlus,
      editIcon: faPen
    };
  },
  computed: {
    doctor: get<IClinicProfilesModel | undefined>('clinic/clinicProfile'),
    doctors: get<IClinicProfilesModel[]>('clinic/clinicProfiles'),
    suggestions (): ISuggestions[] {
      const formattedInput = this.input.replace(/\s+/g, ' ').trim().toLowerCase();
      const filteredNames = this.doctors.filter(doctor => {
        return doctor.firstName.toLowerCase().includes(formattedInput) || doctor.lastName.toLowerCase().includes(formattedInput);
      });
      return [{ data: filteredNames }];
    }
  },
  watch: {
    doctor (newDoctor: IClinicProfilesModel | undefined, oldDoctor: IClinicProfilesModel | undefined) {
      if (newDoctor?.id !== oldDoctor?.id) {
        this.input = newDoctor ? `${newDoctor.firstName ?? ''} ${newDoctor.lastName ?? ''}` : '';
      }
    }
  },
  mounted () {
    this.input = this.doctor ? `${this.doctor.firstName} ${this.doctor.lastName}` : '';
  },
  methods: {
    onSelected (selection: IVueAutoSuggestResult<IClinicProfilesModel>) {
      const selectedDoctor = this.doctors.find((doctor: IClinicProfilesModel) => doctor.id === selection.item.id);
      if (selectedDoctor) {
        this.input = `${selectedDoctor.firstName} ${selectedDoctor.lastName}`;
        this.$emit('doctor-selected', selectedDoctor.id); // Emit the event with the selected doctor's ID
        // commit('clinic/setClinicProfile', selectedDoctor);
      }
    },
    getSuggestionValue (suggestion: IVueAutoSuggestResult<IClinicProfilesModel>) {
      return `${suggestion.item.firstName} ${suggestion.item.lastName}`;
    }
  }
});
