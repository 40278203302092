/* eslint-disable @typescript-eslint/no-explicit-any */
import { importInternal } from 'cornerstone-tools';

const getPixelSpacing = importInternal('util/getPixelSpacing');

function calculateTta (line: any, image: any): string {
  const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

  // Set rowPixelSpacing and columnPixelSpacing to 1 if they are undefined (or zero)
  const dx =
    (line.handles.end.x - line.handles.start.x) * (colPixelSpacing || 1);
  const dy =
    (line.handles.end.y - line.handles.start.y) * (rowPixelSpacing || 1);

  // Calculate the length, and create the text variable with the millimeters or pixels suffix
  const length = Math.sqrt(dx * dx + dy * dy);
  const tta = length.toFixed(3);

  return tta;
}

export default calculateTta;
