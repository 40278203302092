




























import Vue from 'vue';
import { call, get } from 'vuex-pathify';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { GeneratorStatusKeys, validateStatusKey } from '@/state/generator/helpers';

export default Vue.extend({
  filters: {
    camelCase (statusKey: string): string {
      if (statusKey === GeneratorStatusKeys.MX) {
        return 'mAs';
      }

      return `${statusKey[0].toLowerCase()}${statusKey[1].toUpperCase()}`;
    }
  },
  props: {
    statusKey: {
      type: String,
      required: true,
      validator: validateStatusKey
    },
    configurable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      plusIcon: faPlus,
      minusIcon: faMinus
    };
  },
  computed: {
    generatorAvailable: get('generator/generatorAvailable'),
    generatorDisabled: get('generator/generatorDisabled'),
    value (): number {
      return this.$store.get(`generator/status@${this.statusKey}`);
    },
    disabled (): boolean {
      const generatorValueSet = this.value !== 0;

      if (this.generatorDisabled && !generatorValueSet) return true;
      else if (this.generatorDisabled && generatorValueSet) return false;

      return !this.generatorAvailable;
    }
  },
  methods: {
    onDecrementClick: call('generator/decrementStatus'),
    onIncrementClick: call('generator/incrementStatus')
  }
});
