export function getEnv (key: string): string {
  const value = process.env[key];

  if (!value && value !== '') {
    throw new Error(`${key} is not defined`);
  }
  return value;
}

export function tryGetEnv (key: string): string | undefined {
  return process.env[key];
}

export function tryGetConfig (key: string): string | undefined {
  const prependedKey = `VUE_APP_${key}`;
  return process.env[prependedKey];
}

export function isDevelopment (): boolean {
  return getEnv('NODE_ENV') === 'development';
}

export function getConfig (key: string): string {
  const value = getEnv(`VUE_APP_${key}`);

  if (!value && value !== '') {
    throw new Error(`${key} is not defined`);
  }
  return value;
}

export function isServerLocal (): boolean {
  return location.origin.includes('localhost');
}

export function isAppLocal (): boolean {
  return getConfig('TYPE') === 'local';
}

export function isAppCloud (): boolean {
  return getConfig('TYPE') === 'cloud';
}
