













import Vue from 'vue';
import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

export default Vue.extend({
  data () {
    return {
      emailIcon: faEnvelope,
      phoneIcon: faPhoneAlt
    };
  }
});
