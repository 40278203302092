interface IInstructorElements {
  container: HTMLElement;
  toggleButton: HTMLElement;
  list: HTMLElement;
  listItems: Element[];
}

class Instructor {
  x: number;
  y: number;
  instructions: string[];
  stage: number;
  baseCanvas: HTMLElement;
  elements?: IInstructorElements;

  constructor (baseCanvas: HTMLElement, instructions: string[], anchorX = 20, anchorY = 100) {
    this.x = anchorX;
    this.y = anchorY;
    // prepend the step number to each instruction
    this.instructions = instructions.map((instruction, index) => `${index + 1}. ${instruction}`);
    this.stage = 1;
    this.baseCanvas = baseCanvas;
  }

  setStage (stage: number): void {
    if (this.stage !== stage) {
      this.stage = stage;
      this.refreshList();
    }
  }

  getStage (): number {
    return this.stage;
  }

  // update the list to highlight the instruction that coressponds to the current stage
  refreshList (): void {
    if (!this.elements) {
      return;
    }
    const instructionItems = this.elements.listItems;

    instructionItems.forEach((item, index) => {
      // if stage is 0, all items will be inactive
      if ((index + 1) === this.stage) item.classList.add('active');
      else item.classList.remove('active');
    });
  }

  init (): void {
    if (!this.baseCanvas.parentElement) {
      throw new Error('Instructor initialized inside an element without a parent');
    }
    if (!this.baseCanvas.parentElement.classList.contains('display')) return;
    // create canvas element and set its size to be same as baseCanvas
    const container = document.createElement('DIV');
    container.classList.add('instructor-overlay');
    container.classList.add('hideable');
    Instructor.resize(container, this.baseCanvas);
    // make sure instruction canvas always have same size as baseCanvas
    window.addEventListener('resize', () => {
      Instructor.resize(container, this.baseCanvas);
    });

    // add canvas element to the DOM
    this.baseCanvas.parentElement.appendChild(container);

    const toggleButton = this.renderToggle();
    const list = this.renderList();
    container.appendChild(toggleButton);
    container.appendChild(list);

    this.elements = {
      container,
      toggleButton,
      list,
      listItems: Array.from(list.children)
    };
  }

  // wipes out all instructor elements from the DOM
  wipe (): void {
    if (!this.elements) {
      return;
    }
    const toggleButton = this.elements.toggleButton;
    const instructionList = this.elements.list;

    if (toggleButton) toggleButton.remove();
    if (instructionList) instructionList.remove();

    this.elements.container.remove();
  }

  // createthe list of instruction elements
  renderList (): HTMLElement {
    const instructionList = document.createElement('UL');
    instructionList.style.top = `${this.y}px`;
    instructionList.style.left = `${this.x}px`;

    this.instructions.forEach((instruction, index) => {
      const item = document.createElement('LI');
      item.textContent = instruction;
      if ((index + 1) === this.stage) item.classList.add('active');

      instructionList.appendChild(item);
    });

    return instructionList;
  }

  // create the button that will toggle the instruction list visibility
  renderToggle (): HTMLElement {
    const button = document.createElement('BUTTON');
    button.style.top = `${this.y - 15}px`;
    button.style.left = `${this.x}px`;

    button.addEventListener('mousedown', event => {
      // stop cornerstone from picking up the click event
      event.stopPropagation();
      if (!this.elements) {
        return;
      }
      const instructionsOverlay = this.elements.container;
      instructionsOverlay.classList.toggle('hidden-list');
    });
    button.addEventListener('mouseup', event => event.stopPropagation());

    return button;
  }

  // resizes @element to match to @targetElement
  static resize (element: HTMLElement, targetElement: HTMLElement): void {
    const height = targetElement.getAttribute('height') || '';
    const width = targetElement.getAttribute('width') || '';
    element.setAttribute('height', height);
    element.setAttribute('width', width);
    element.style.height = targetElement.style.height;
    element.style.width = targetElement.style.width;
  }
}

export default Instructor;
