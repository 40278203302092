























import Vue from 'vue';
import { IReportImage } from '@/state/report';
import { Prop } from 'vue/types/options';
import { getImageJpegData } from '@/state/study/helpers';
import { IImageModel } from '@/api/Models';

export default Vue.extend({
  props: {
    image: {
      type: Object as Prop<IReportImage>,
      default: undefined
    },
    imageClass: {
      type: String,
      default: undefined
    },
    isThumbnailSize: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      imageSrc: undefined as string | undefined
    };
  },
  watch: {
    async image (newImage) {
      if (!newImage) return;
      this.imageSrc = await getImageJpegData(newImage.id as number);
    }
  },
  async created () {
    if (!this.image) return;
    this.imageSrc = await getImageJpegData(this.image.id as number);
  },
  methods: {
    onImageClick (): void {
      this.$emit('click');
    },
    getImageAnnotation (image: IImageModel): string {
      return image.modality === 'General' ? `${image.bodyPart} ${image.bodyPartView}` : `${image.bodyPart}`;
    }
  }
});
