import { render, staticRenderFns } from "./CornerstoneImageContainer.vue?vue&type=template&id=4d72b840&scoped=true&"
import script from "./CornerstoneImageContainer.vue?vue&type=script&lang=ts&"
export * from "./CornerstoneImageContainer.vue?vue&type=script&lang=ts&"
import style0 from "./CornerstoneImageContainer.vue?vue&type=style&index=0&id=4d72b840&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d72b840",
  null
  
)

export default component.exports