





































































import Vue from 'vue';
import { dispatch, get } from 'vuex-pathify';
import DatePicker from 'vuejs-datepicker';
import { Prop } from 'vue/types/options';
import { VueAutosuggest } from 'vue-autosuggest';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ModalFrame from '@/components/modal/ModalFrame.vue';
import ValidatedForm from '@/components/form/ValidatedForm.vue';
import FormRow from '@/components/form/FormRow.vue';

import { IClinicModel, IPermissionProfileModel, IUserModel } from '@/api/Models';
import eventBus, { ModalEventNames } from '@/eventBus';
import { IVueAutoSuggestResult } from '@/plugins/vueAutoSuggest/interfaces';
import { formatDateVerbose } from '@/helpers/date';
import validate from 'validate.js';

export default Vue.extend({
  components: {
    DatePicker,
    ValidatedForm,
    FormRow,
    ModalFrame,
    VueAutosuggest
  },
  props: {
    user: {
      type: Object as Prop<IUserModel>,
      default: undefined
    }
  },
  data () {
    return {
      form: {
        username: this.user?.username,
        password: '',
        confirmPassword: '',
        firstName: this.user?.firstName,
        lastName: this.user?.lastName,
        validUntil: this.user?.validUntil,
        clinicId: this.user?.clinicId as number | undefined
      },
      clinicSearch: '',
      errorMessage: '',
      loading: false,
      isRoot: false,
      disabledValidUntilDates: {
        to: new Date(Date.now())
      },
      faTimes
    };
  },
  computed: {
    clinics: get<IClinicModel[]>('clinic/clinics'),
    isEdit (): boolean {
      return !!this.user;
    },
    isClinicInvalid (): boolean {
      return !this.isEdit && !this.form.clinicId;
    },
    clinicSuggestions (): any {
      const clinics = this.clinics.filter(clinic => {
        const formattedInput = this.clinicSearch.trim().toLowerCase();
        return clinic.name.toLowerCase().includes(formattedInput);
      });
      console.log(clinics);
      return [{ data: clinics }];
    }
  },
  async created () {
    if (!this.user) return;
    const permission: IPermissionProfileModel = await dispatch('permissionProfile/fetchProfile', this.user.permissionProfileId);
    this.isRoot = permission.root;
  },
  methods: {
    formatDate: formatDateVerbose,
    validatePassword (): boolean {
      this.errorMessage = '';

      const confirmPassword = this.form.confirmPassword;
      const constraints = {
        equality: {
          attribute: this.form.confirmPassword,
          message: 'Password field must match Confirm Password field',
          // v2 comes out as undefined for some reason
          comparator: function (v1: string, v2: string) {
            return v1 === confirmPassword;
          }
        },
        length: { minimum: 8, message: 'Password must be at least 8 characters long' },
        format: {
          pattern: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$', // https://stackoverflow.com/a/19605207 without the special characters
          format: 'i',
          message: 'Must contain at least one digit, one uppercase character and one lowercase character'
        }
      };
      const errors = validate.single(this.form.password, constraints);
      const isValid = errors === undefined;
      this.errorMessage = !isValid ? errors[0] : '';

      return isValid;
    },
    getClinicName (suggestion: IVueAutoSuggestResult<IClinicModel>): string {
      return suggestion.item.name;
    },
    onSuggestionSelected (suggestion: IVueAutoSuggestResult<IClinicModel>) {
      this.form.clinicId = suggestion.item.id as number;
    },
    onSuggestionInput (value: string) {
      this.form.clinicId = undefined;
      this.clinicSearch = value;
    },
    onResetValidUntilClick (): void {
      this.form.validUntil = undefined;
    },
    async onSaveClick (): Promise<void> {
      if (!this.isEdit) {
        const isPasswordValid = this.validatePassword();
        if (!isPasswordValid) return;
      }

      this.errorMessage = '';
      this.loading = true;

      try {
        if (this.isEdit) {
          await dispatch('user/putUser', { ...this.user, ...this.form });
        } else {
          const payload: IUserModel = {
            ...this.form,
            clinicId: this.form.clinicId as number,
            permissionProfileId: -1,
            createdOn: new Date()
          };
          await dispatch('user/postUser', payload);
        }
      } catch (error) {
        this.errorMessage = error?.response?.data?.message ? error.response.data.message : error.message;
      } finally {
        this.loading = false;
      }

      if (!this.errorMessage) {
        eventBus.$emit(ModalEventNames.closeModal);
      }
    }
  }
});
