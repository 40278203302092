import Vue from 'vue';
import { ActionContext, Module, Store } from 'vuex';
import { GeneratorService } from './generatorService';
import { find, omit, once } from 'lodash';
import store, { IRootState } from '..';
import { calculateMx, calculateMaAndMs, decrementStatus, GeneratorStatusKeys, incrementStatus, validateStatusKey } from './helpers';
import { IGeneratorTechniqueModel, ISensorModel, Modality, IGeneratorTechniquesOverrideModel, IAnnotation, FocalSizes } from '@/api/Models';
import FMSAxios from '@/api/FMSAxios';
import axios from 'axios';
import { ConnectionStatus, SwalTitles } from '../enums';
import { openEventSource } from '../eventSourceState';
import { commit, dispatch, make } from 'vuex-pathify';
import { get } from '../restfulState';

export interface IConfigScales {
  [key: string]: number[];
  kv: number[];
  ma: number[];
  ms: number[];
  mx: number[];
  ws: number[];
  fo: number[];
  pw: number[];
}

export interface IGeneratorStatus {
  [index: string]: number;
  mx: number; // generator interprets this value as mx, user facing it should be mAs
  ms: number;
  ma: number;
  kv: number;
  ex: number;
  pw: number;
  fo: number;
  ws: number;
}

export interface IGeneratorState {
  scales?: IConfigScales;
  status: IGeneratorStatus;
  generatorTechniques?: IGeneratorTechniqueModel | IGeneratorTechniquesOverrideModel;
  generatorSource?: EventSource;
  generatorURL?: string;
  readyState: number;
  generatorDisabled: boolean;
}

const _state: IGeneratorState = {
  scales: undefined,
  status: {
    mx: 0,
    ms: 0,
    ma: 0,
    kv: 0,
    ex: 0,
    pw: 0,
    fo: 0,
    ws: 0
  },
  generatorTechniques: undefined,
  generatorSource: undefined,
  generatorURL: undefined,
  readyState: EventSource.CLOSED,
  generatorDisabled: false
};

const generatorService = new GeneratorService();

const generator: Module<IGeneratorState, IRootState> = {
  state: _state,
  getters: {
    getGeneratorAvailable (state): boolean {
      if (state.status.ex !== 0 || state.status.pw !== 1 || state.readyState !== ConnectionStatus.OPEN) return false;
      return true;
    }
  },
  mutations: {
    ...make.mutations(_state),
    setStatus (state: IGeneratorState, { key, value }: { key: GeneratorStatusKeys; value: number }): void {
      state.status[key] = value;
    },
    setStatusAll (state, status: IGeneratorStatus): void {
      state.status = status;
    }
  },
  actions: {
    initialize: once(async (context: ActionContext<IGeneratorState, IRootState>, vuexStore: Store<IRootState>) => {
      await dispatch('generator/fetchScales');
      await dispatch('generator/fetchGeneratorTechniques');

      const modality = vuexStore.get('sensor/sensor@modality');
      const generatorDisabled = vuexStore.get('generator/generatorDisabled');

      if (modality === Modality.General && !generatorDisabled) {
        const sensorRoot = vuexStore.get('sensor/root');
        commit('generator/setGeneratorURL', `${sensorRoot}/generator`);
        await dispatch('generator/openGeneratorConnection');
      } else if (modality === Modality.General && generatorDisabled) {
        console.info('Generator disabled. Will not open generator connection.');
      } else {
        console.warn('Cannot connect generator without General sensor.');
      }

      vuexStore.watch(state => {
        return {
          selectedAnnotation: state.xRay.selectedAnnotation,
          modality: state.sensor.sensor?.modality,
          readyState: state.generator.readyState,
          generatorURL: state.generator.generatorURL
        };
      }, async (newState, oldState) => {
        if (newState.selectedAnnotation && newState.selectedAnnotation !== oldState.selectedAnnotation && newState.modality === Modality.General) {
          await dispatch('generator/onAnnotationChange');
        }

        if (newState.readyState === EventSource.OPEN && oldState.readyState !== EventSource.OPEN && newState.generatorURL) {
          await axios.get(`${newState.generatorURL}/ws?value=0`);
        }
      });
    }),
    incrementStatus (context, key: GeneratorStatusKeys) {
      const generatorDisabled = store.get('generator/generatorDisabled');
      const configScales = store.get('generator/scales');

      if (generatorDisabled) {
        const value = store.get(`generator/status@${key}`);
        const updatedValue = incrementStatus(configScales, key, value);

        if (key === GeneratorStatusKeys.MX) {
          dispatch('generator/setMaAndMsFromMx', updatedValue);
        }

        return commit('generator/setStatus', { key, value: updatedValue });
      }

      const generatorURL: string = this.get('generator/generatorURL');
      if (!generatorURL) {
        throw new Error('Missing generator URL');
      }
      const status: IGeneratorStatus = this.get('generator/status');
      const newConfig = incrementStatus(configScales, key, status[key]);
      axios.get(`${generatorURL}/${key}?value=${newConfig}`);
    },
    decrementStatus (context, key: GeneratorStatusKeys) {
      const generatorDisabled = store.get('generator/generatorDisabled');
      const configScales = store.get('generator/scales');

      if (generatorDisabled) {
        const value = store.get(`generator/status@${key}`);
        const updatedValue = decrementStatus(configScales, key, value);

        if (key === GeneratorStatusKeys.MX) {
          dispatch('generator/setMaAndMsFromMx', updatedValue);
        }

        return commit('generator/setStatus', { key, value: updatedValue });
      }

      const generatorURL: string = this.get('generator/generatorURL');
      if (!generatorURL) {
        throw new Error('Missing generator URL');
      }
      const status: IGeneratorStatus = this.get('generator/status');
      const newConfig = decrementStatus(configScales, key, status[key]);
      axios.get(`${generatorURL}/${key}?value=${newConfig}`);
    },
    setMaAndMsFromMx (context, mx: number): void {
      const configScales = store.get('generator/scales');

      const currentMa = store.get(`generator/status@${GeneratorStatusKeys.MA}`);
      const currentMs = store.get(`generator/status@${GeneratorStatusKeys.MS}`);
      const { ma, ms } = calculateMaAndMs(configScales, mx, currentMa, currentMs);

      commit('generator/setStatus', { key: GeneratorStatusKeys.MA, value: ma });
      commit('generator/setStatus', { key: GeneratorStatusKeys.MS, value: ms });
    },
    async onFoChange (context, focalSize: FocalSizes): Promise<void> {
      const generatorDisabled = store.get('generator/generatorDisabled');
      const generatorURL = this.get('generator/generatorURL');

      const sizes = Object.values(FocalSizes);
      const value = sizes.indexOf(focalSize as FocalSizes);

      if (!generatorDisabled) {
        return await axios.get(`${generatorURL}/fo?value=${value}`);
      }

      commit('generator/setStatus', { key: GeneratorStatusKeys.FO, value });
    },
    async fetchGeneratorTechniques () {
      const userId = store.get('auth/userId');

      const { data: genTechniques } = await FMSAxios.get('/generatorTechnique');
      const { data: genTechniquesOverrides } = await FMSAxios.get(`/generatorTechniquesOverride?userId=${userId}`);

      const mergedTechniques = genTechniques.map((technique: IGeneratorTechniqueModel) => {
        const { speciesId, size, bodyPart, bodyPartView } = technique;
        let foundOverride = find(genTechniquesOverrides, { speciesId, size, bodyPart, bodyPartView });
        foundOverride = foundOverride ? omit(foundOverride, ['userId', 'createdOn']) : foundOverride;

        return foundOverride || technique;
      });

      commit('generator/setGeneratorTechniques', mergedTechniques);
    },
    async saveConfig () {
      const status = this.get('generator/status');
      const speciesId = this.get('patient/patient@speciesId');
      const annotation: IAnnotation = this.get('xRay/selectedAnnotation');
      const { animalSize: size, bodyPart, bodyPartView } = annotation;

      if (!status || !size || !bodyPart || !bodyPartView) {
        console.warn('Missing values in selected annotation');
        return;
      }

      const genTechniques: IGeneratorTechniqueModel[] = store.get('generator/generatorTechniques');

      const foundTechnique = find(genTechniques, { speciesId, bodyPart, bodyPartView, size });

      if (!foundTechnique) {
        console.warn('Unable to find technique with provided annotation.');
        return;
      }

      const userId = store.get('auth/userId');

      const payload: IGeneratorTechniquesOverrideModel = {
        userId,
        speciesId,
        size,
        bodyPart,
        bodyPartView,
        fo: status.fo,
        kv: status.kv,
        ma: status.ma,
        ms: status.ms
      };

      await FMSAxios.post('generatorTechniquesOverride/save', payload);
      await dispatch('generator/fetchGeneratorTechniques');
    },
    async onAnnotationChange () {
      const status = store.get('generator/status');
      const generatorTechniques = store.get('generator/generatorTechniques');
      const speciesId = store.get('patient/patient@speciesId');
      const annotation: IAnnotation = this.get('xRay/selectedAnnotation');
      const { animalSize: size, bodyPart, bodyPartView } = annotation;
      if (!status || !size || !bodyPart || !bodyPartView) {
        console.warn('Missing values in selected annotation');
        return;
      }

      const foundTechnique = find(generatorTechniques, { speciesId, bodyPart, bodyPartView, size });

      if (!foundTechnique) return;

      const generatorDisabled = store.get('generator/generatorDisabled');

      if (!generatorDisabled) {
        const generatorURL: string = this.get('generator/generatorURL');

        await axios.get(`${generatorURL}/fo?value=${foundTechnique.fo}`);
        await axios.get(`${generatorURL}/kv?value=${foundTechnique.kv}`);
        await axios.get(`${generatorURL}/ma?value=${foundTechnique.ma}`);
        await axios.get(`${generatorURL}/ms?value=${foundTechnique.ms}`);
      } else {
        commit('generator/setStatus', { key: 'fo', value: foundTechnique.fo });
        commit('generator/setStatus', { key: 'kv', value: foundTechnique.kv });
        commit('generator/setStatus', { key: 'ma', value: foundTechnique.ma });
        commit('generator/setStatus', { key: 'ms', value: foundTechnique.ms });

        const configScales = store.get('generator/scales');
        commit('generator/setStatus', { key: 'mx', value: calculateMx(configScales, foundTechnique.ma, foundTechnique.ms) });
      }
    },
    openGeneratorConnection () {
      const generatorDisabled = this.get('generator/generatorDisabled');

      if (generatorDisabled) {
        console.info('Generator disabled. Will not open generator connection.');
        return;
      }

      const generatorSource: EventSource = this.get('generator/generatorSource');

      if (generatorSource !== undefined) {
        console.warn('Trying to open a generator connection while a connection is already open');
        return;
      }

      const generatorURL: string = this.get('generator/generatorURL');

      openEventSource(`${generatorURL}/events`, 'generator/setGeneratorSource', 'generator/setReadyState', event => {
        let [key, value] = event.data.split(':');
        key = key.toLowerCase();
        value = parseFloat(value);
        if (validateStatusKey(key)) {
          commit('generator/setStatus', { key, value });
        }
      });
    },
    closeGeneratorConnection () {
      const generatorSource: EventSource = this.get('generator/generatorSource');

      if (generatorSource) {
        generatorSource.close();
        commit('generator/setGeneratorSource', undefined);
        commit('generator/setReadyState', ConnectionStatus.CLOSED);
        commit('generator/setStatusAll', {
          mx: 0,
          ms: 0,
          ma: 0,
          kv: 0,
          ex: 0,
          pw: 0,
          fo: 0,
          ws: 0
        });
      }
    },
    async togglePower (context, power: boolean) {
      const generatorDisabled = store.get('generator/generatorDisabled');

      if (generatorDisabled) {
        console.info('Generator disabled. Skipping toggle generator power.');
        return;
      }

      await dispatch('sensor/fetchSensors');

      const modality = store.get('sensor/sensor@modality');

      if (modality !== Modality.General) {
        console.info('Not a General XRay machine. Skipping toggle generator power.');
        return;
      }

      const generatorURL: string | undefined = await dispatch('generator/getGeneratorUrl');

      if (generatorURL) {
        await generatorService.togglePower(generatorURL, power);

        const key = 'pw';
        const value = power ? 1 : 0;
        commit('generator/setStatus', { key, value });
      }
    },
    async getGeneratorUrl (): Promise<string | undefined> {
      const token: string = Vue.auth.token();

      const generatorURL: string | undefined = this.get('generator/generatorURL');
      if (generatorURL) return generatorURL;

      if (!generatorURL && token) {
        const response = await FMSAxios.get('sensor');
        const careray = response.data?.find((sensor: ISensorModel) => sensor.modality === Modality.General);

        return careray?.serverIp ? `http://${careray.serverIp}:${careray.port}/generator` : undefined;
      }
    },
    async getGeneratorStatus (): Promise<GeneratorStatusKeys | undefined> {
      const generatorURL: string | undefined = await dispatch('generator/getGeneratorUrl');
      const response = await axios.get(`${generatorURL}/status`);
      commit('generator/setStatusAll', response.data);

      return response.data;
    },
    async validateGenerator (context, destinationModality: Modality): Promise<void> {
      if (destinationModality !== Modality.General) return;

      const generatorAvailable = store.get('generator/generatorAvailable');
      const generatorDisabled = store.get('generator/generatorDisabled');

      if (generatorAvailable || generatorDisabled) return;

      await Vue.swal.fire({
        icon: 'error',
        title: SwalTitles.GeneratorConnectionError,
        text: 'Generator is currently unavailable'
      });
    },
    async fetchScales (): Promise<void> {
      await get('generator/scales', 'generator/setScales');
    }
  },
  namespaced: true
};

export default generator;
