
























import Vue from 'vue';
import { Prop } from 'vue/types/options';
import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';

export default Vue.extend({
  components: {
    VueGoodTable
  },
  props: {
    items: {
      type: Array as Prop<any[]>,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    createRow: {
      type: Function,
      default: undefined
    },
    value: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    rows (): any[] {
      return this.items.map(item => {
        return { ...item, ...this.createRow ? this.createRow(item) : {} };
      });
    }
  },
  watch: {
    items (newItems: object[], oldItems: object[]) {
      const newItemAdded = newItems.length - oldItems.length === 1;
      if (newItemAdded) {
        this.$emit('input', newItems[newItems.length - 1]);
      }
    }
  },
  methods: {
    onRowClick (params: any): void {
      if (!params.row.id) return;
      const selectedItem = this.items.find(item => item.id === params.row.id);
      this.$emit('input', selectedItem);
    },
    rowStyleClass (row: any): string {
      return this.value?.id === row.id ? 'active' : '';
    }
  }
});
