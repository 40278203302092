



















import Vue from 'vue';
import { dispatch, get } from 'vuex-pathify';
import eventBus, { ModalEventNames } from '@/eventBus';
import { IDownloadImagesRequest, ImageFileType, IPatientModel, IStudieModel } from '@/api/Models';
import ImagesModalFrame from '@/components/modal/ImagesModalFrame.vue';
import ColumnInputWrapper from '@/components/form/ColumnInputWrapper.vue';
import { isServerLocal, isAppLocal } from '@/helpers/config';
import FMSAxios from '@/api/FMSAxios';
import { localDownload } from '@/state/restfulState';

const options = [
  {
    label: 'JPEG',
    value: ImageFileType.Jpg
  },
  {
    label: 'DICOM',
    value: ImageFileType.Dcm
  }
];

export default Vue.extend({
  components: { ImagesModalFrame, ColumnInputWrapper },
  data () {
    return {
      selectedImageIds: [] as number[],
      selectedType: options[0],
      options,
      errorMessage: ''
    };
  },
  computed: {
    patient: get<IPatientModel>('patient/patient'),
    study: get<IStudieModel>('study/selectedStudy')
  },
  async created () {
    await dispatch('patient/fetchPatient', this.study.patientId);
  },
  methods: {
    async onConfirm (): Promise<void> {
      this.errorMessage = '';

      if (this.selectedImageIds.length === 0) {
        this.errorMessage = 'Please select images';
        return;
      }

      try {
        const request: IDownloadImagesRequest = {
          imageIds: this.selectedImageIds,
          type: this.selectedType.value
        };
        if (isAppLocal() && isServerLocal()) {
          await localDownload<IDownloadImagesRequest>('image/download/local', request);
          eventBus.$emit(ModalEventNames.closeModal);
          return;
        }
        const response = await FMSAxios.post<Blob>('image/download', request, {
          responseType: 'blob'
        });
        const zipBlob = response.data;
        const blobUrl = URL.createObjectURL(zipBlob);

        const anchor = document.createElement('a');
        const date = new Date(Date.now());
        anchor.href = blobUrl;
        anchor.download = `${this.patient.name}_${this.patient.patientId}_${date.toLocaleDateString()}_${this.study.id}.zip`;
        anchor.click();
        anchor.remove();

        eventBus.$emit(ModalEventNames.closeModal);
      } catch (error) {
        this.errorMessage = error;
      }
    }
  }
});

