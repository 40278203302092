

































import Vue from 'vue';
import { dispatch } from 'vuex-pathify';

import router from '@/router';
import { isAppLocal } from '@/helpers/config';
import ValidatedForm from '@/components/form/ValidatedForm.vue';
import { navigate, NavigationMethods } from '@/router/helpers';

export default Vue.extend({
  components: {
    ValidatedForm
  },
  data () {
    return {
      username: '',
      password: '',
      loading: false,
      errorMessage: '',
      resetOnComplete: false
    };
  },
  methods: {
    async onLoginClick (): Promise<void> {
      const form = this.$refs.form as HTMLFormElement;
      const validated: boolean = form.validateWithHighlights();

      if (!validated) {
        this.errorMessage = 'There are some issues with the marked fields:';
        this.loading = false;
        this.resetOnComplete = true;

        return;
      }

      this.errorMessage = '';
      this.loading = true;
      this.resetOnComplete = false;

      try {
        await dispatch('auth/login', {
          username: this.username,
          password: this.password
        });
        navigate(router.currentRoute, NavigationMethods.push, isAppLocal() ? '/registration' : '/studylist');
      } catch (error) {
        console.error(error);
        this.errorMessage = error?.response?.data?.message ? error.response.data.message : error.message;
      } finally {
        this.loading = false;
        this.resetOnComplete = true;
      }
    }
  }
});
