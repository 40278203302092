import Vue from 'vue';
import { ActionContext, Module, Store } from 'vuex';
import vuexStore, { IRootState } from '@/state';
import FMSAxios from '@/api/FMSAxios';
import axios, { CancelTokenSource } from 'axios';
import { commit, dispatch, make } from 'vuex-pathify';
import { get } from '../restfulState';
import { once, isEqual } from 'lodash';
import { getDentalParts } from './body-parts';
import { SweetAlertOptions } from 'sweetalert2';
import { AnimalSizes, IImageModel, IStudieModel, Modality, IPatientModel, SensorState, Species, IEditImageAnnotationRequest, IAnnotation,
  ISensorModel } from '@/api/Models';
import { createGeneralAnnotation, createDentalAnnotation, doesGeneralAnnotationExist, doesDentalAnnotationExist, doesAnnotationExist,
  generateProcessingButtonProperties } from './helpers';
import { isSwalOpen } from '@/helpers/sweetAlert';
import { SwalTitles } from '../enums';
import speciesViewsMap from './views';

const cancelXRaySwalOptions: SweetAlertOptions = {
  title: SwalTitles.CancelXRay,
  text: 'Are you sure you want to cancel the XRay?',
  allowEnterKey: false,
  showConfirmButton: true,
  showDenyButton: true,
  confirmButtonText: 'Yes',
  buttonsStyling: false,
  backdrop: 'rgba(0, 0, 0, 0.8)',
  customClass: {
    confirmButton: 'btn btn-secondary mr-2',
    denyButton: 'btn btn-secondary'
  },
  preConfirm () {
    dispatch('xRay/stopXRay');
    Vue.swal.close();
  },
  preDeny () {
    const isProcessingXRayStatus = vuexStore.get('xRay/isProcessingXRayStatus');
    if (!isProcessingXRayStatus) return Vue.swal.close();

    dispatch('xRay/launchProcessingModal');
  }
};

export interface IXRayState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [index: string]: any;
  cancelationToken?: CancelTokenSource;
  selectedSize?: AnimalSizes;
  selectedPart?: string;
  selectedView?: string;
  selectedAnnotation?: IAnnotation;
  annotations: IAnnotation[];
  loopStarted: boolean;
}

const _state: IXRayState = {
  selectedSize: undefined,
  selectedPart: undefined,
  selectedView: undefined,
  selectedAnnotation: undefined,
  annotations: [],
  cancelationToken: undefined,
  loopStarted: false
};

const xRay: Module<IXRayState, IRootState> = {
  state: _state,
  mutations: {
    ...make.mutations(_state),
    flush (state): void {
      if (state.cancelationToken) {
        console.warn('Canceling XRay request due to store flush');
        state.cancelationToken.cancel();
      }

      for (const [key, value] of Object.entries(state)) {
        if (Array.isArray(value)) {
          state[key] = [];
        } else {
          state[key] = undefined;
        }
      }
    }
  },
  getters: {
    getGeneralViews (state: IXRayState, getters, root: IRootState): string[] {
      const patientSpecies = root.patient.patient?.speciesId;
      if (!patientSpecies) return [];

      return speciesViewsMap[patientSpecies];
    },
    getGeneralAnnotations (state: IXRayState) {
      return state.annotations?.filter(annotation => !!annotation.bodyPartView);
    },
    getDentalAnnotations (state: IXRayState) {
      return state.annotations?.filter(annotation => !annotation.bodyPartView);
    },
    getCaptureInProgress (state: IXRayState, getters, root: IRootState): boolean {
      const sensorState = root.sensor.sensorState;
      if (!sensorState) {
        return false;
      }
      const captureStatuses = [
        SensorState.RadiationReceived,
        SensorState.LoadingImage,
        SensorState.PostAcquisitionCleanup
      ];
      return captureStatuses.indexOf(sensorState) !== -1;
    },
    getIsProcessingXRayStatus (): boolean {
      const sensorState: SensorState = vuexStore.get('sensor/sensorState');

      const processingState = [SensorState.RadiationReceived, SensorState.LoadingImage, SensorState.PostAcquisitionCleanup];
      return processingState.some(state => state === sensorState);
    }
  },
  actions: {
    initialize: once((context: ActionContext<IXRayState, IRootState>, store: Store<IRootState>) => {
      store.watch(state => {
        return {
          images: state.study.images,
          sensorModality: state.sensor.sensor?.modality,
          selectedAnnotation: state.xRay.selectedAnnotation,
          selectedSize: state.xRay.selectedSize,
          sensorState: state.sensor.sensorState
        };
      }, newState => {
        if (newState.sensorState === SensorState.NotAvailable) {
          commit('xRay/setLoopStarted', false);
        }

        if (newState.selectedAnnotation?.animalSize && !newState.selectedSize) {
          commit('xRay/setSelectedSize', newState.selectedAnnotation.animalSize);
        }
      });
    }),
    async startXRay () {
      let selectedAnnotation: IAnnotation = this.get('xRay/selectedAnnotation');
      if (!selectedAnnotation) {
        throw new Error('Missing a selected annotation');
      }

      try {
        do {
          commit('xRay/setLoopStarted', true);
          const token = axios.CancelToken.source();
          commit('xRay/setCancelationToken', token);

          const sensorId = this.get('sensor/sensor@id');

          const response = await FMSAxios.post('image/capture-xray', {
            sensorId,
            studyId: this.get('study/selectedStudy@id'),
            annotation: {
              bodyPart: selectedAnnotation.bodyPart,
              bodyPartView: selectedAnnotation.bodyPartView,
              animalSize: selectedAnnotation.animalSize
            }
          }, { cancelToken: token.token });

          let image: IImageModel = response.data;
          // user could've changed the annotation by the time the x-ray was captured
          selectedAnnotation = this.get('xRay/selectedAnnotation');
          const imageAnnotations: IAnnotation = {
            bodyPart: image.bodyPart,
            bodyPartView: image.bodyPartView,
            animalSize: image.animalSize
          };

          if (!isEqual(imageAnnotations, selectedAnnotation)) {
            const payload: IEditImageAnnotationRequest = {
              imageId: image.id as number,
              annotation: selectedAnnotation
            };
            const updatedResponse = await FMSAxios.put('image/annotations', payload);

            image = updatedResponse.data;
          }

          commit('study/addImage', image);
          commit('study/setSelectedImage', image);
        } while (this.get('xRay/cancelationToken'));
      } catch (error) {
        commit('xRay/setLoopStarted', false);

        if (error.response?.data?.status === 408) {
          return await Vue.swal.fire({
            icon: 'error',
            title: SwalTitles.XRayTimeOut
          });
        }

        const isCancelled = error.message === undefined;
        if (!isCancelled) {
          throw new Error(error.response.data.message);
        }
      } finally {
        commit('xRay/setCancelationToken', undefined);
      }
    },
    stopXRay () {
      const cancelationToken: CancelTokenSource = this.get('xRay/cancelationToken');

      if (cancelationToken) {
        console.warn('Canceling XRay request');
        cancelationToken.cancel();
        commit('xRay/setCancelationToken', undefined);
      }
    },
    deleteAnnotation (context, deletedAnnotation: IAnnotation) {
      const annotations: IAnnotation[] = this.get('xRay/annotations');
      const foundAnnotation = annotations.find(annotation => isEqual(annotation, deletedAnnotation));

      if (foundAnnotation) {
        const updatedAnnotations: IAnnotation[] = annotations.filter(annotation => !isEqual(annotation, deletedAnnotation));
        this.commit('xRay/setAnnotations', updatedAnnotations);
        this.commit('xRay/setSelectedAnnotation', updatedAnnotations[0]);
        if (updatedAnnotations.length === 0) {
          dispatch('xRay/stopXRay');
        }
      }
    },
    addAnnotation (): void {
      const xRayState: IXRayState = this.get('xRay');
      const study: IStudieModel = this.get('study/selectedStudy');
      const modality: Modality = this.get('sensor/sensor@modality');

      const annotation = modality === Modality.General ? createGeneralAnnotation(xRayState, study) :
        modality === Modality.Dental ? createDentalAnnotation(xRayState.selectedPart as string, study) : undefined;
      if (!annotation) {
        throw new Error('Failed to create an annotation');
      }

      const annotations = xRayState.annotations;
      const annotationExists = modality === Modality.General ? doesGeneralAnnotationExist(annotations, annotation) :
        doesDentalAnnotationExist(annotations, annotation);

      if (!annotationExists) {
        xRayState.annotations.push(annotation);
        commit('xRay/setSelectedAnnotation', annotation);
      }
    },
    postAllDentalAnnotations (): void {
      const patientSpecies: Species = this.get('patient/patient@speciesId');
      if (!patientSpecies) {
        throw new Error('Attempting to add all annotations before patient loaded');
      }
      const teeth = getDentalParts(patientSpecies);
      const currentAnnotations: IAnnotation[] = this.get('xRay/dentalAnnotations');
      const currentTeeth = currentAnnotations.map(annotation => annotation.bodyPart);

      const missingAnnotations: IAnnotation[] = teeth.filter(tooth => currentTeeth.indexOf(tooth) === -1)
        .map(toothItem => {
          return {
            bodyPart: toothItem
          };
        });
      commit('xRay/setAnnotations', [...currentAnnotations, ...missingAnnotations]);
    },
    deleteAllDentalAnnotations (): void {
      const images: IImageModel[] = this.get('study/currentModalityImages');
      const currentAnnotations: IAnnotation[] = this.get('xRay/dentalAnnotations');

      const annotationsWithImages = currentAnnotations.filter(annotation => {
        return images.some(image => image.bodyPart === annotation.bodyPart);
      });
      this.set('xRay/annotations', annotationsWithImages);
    },
    async fetchStudy (context, studyId: number): Promise<void> {
      await get(`/study/${studyId}`, 'study/setSelectedStudy');
      await dispatch('xRay/fetchImages');
    },
    async fetchImages (): Promise<void> {
      const sensor: ISensorModel = this.get('sensor/sensor');
      const selectedStudy: IStudieModel = this.get('study/selectedStudy');
      if (!selectedStudy || !sensor) {
        const missing = !selectedStudy ? 'study' : 'sensor';
        throw new Error(`Attempting to fetch images before ${missing} was loaded`);
      }

      await get(`/image?studyId=${selectedStudy.id}&isRejected=0`, 'study/setImages');

      const annotations: IAnnotation[] = this.get('xRay/annotations');
      if (annotations.length === 0) {
        dispatch('xRay/setAnnotationsFromImages');
      }
    },
    setAnnotationsFromImages (): void {
      const annotations: IAnnotation[] = [];
      const images: IImageModel[] = this.get('study/currentModalityImages');

      images.forEach(image => {
        const annotation: IAnnotation = {
          bodyPart: image.bodyPart,
          bodyPartView: image.bodyPartView,
          animalSize: image.animalSize
        };

        if (!doesAnnotationExist(annotations, annotation)) {
          annotations.push(annotation);
        }
      });

      commit('xRay/setAnnotations', annotations);
    },
    async setOwnerAndPatientByStudyId (context, studyId: number): Promise<void> {
      const studyResponse = await FMSAxios.get(`study/${studyId}`);
      const study: IStudieModel = studyResponse.data;
      const patient: IPatientModel = await dispatch('patient/fetchPatient', study.patientId);
      await dispatch('owner/fetchOwner', patient.ownerId);
    },
    async ensureProcessingModal (): Promise<void> {
      const isLoopStarted = vuexStore.get('xRay/loopStarted');
      if (!isLoopStarted) return;

      const isProcessingXRayStatus = vuexStore.get('xRay/isProcessingXRayStatus');

      const isProcessingModalOpen = isSwalOpen(SwalTitles.CaptureInProgress);
      const isCancelModalOpen = isSwalOpen(SwalTitles.CancelXRay);

      if (!isProcessingXRayStatus && isProcessingModalOpen) {
        return Vue.swal.close();
      }

      if (isProcessingXRayStatus && isProcessingModalOpen) {
        return await dispatch('xRay/updateProcessingModal');
      }

      if (isProcessingXRayStatus && !isProcessingModalOpen && !isCancelModalOpen) {
        return await dispatch('xRay/launchProcessingModal');
      }
    },
    async launchProcessingModal (): Promise<void> {
      const sensorState: SensorState = vuexStore.get('sensor/sensorState');

      const { buttonText, buttonClass } = generateProcessingButtonProperties(sensorState);

      const swalOptions: SweetAlertOptions = {
        title: SwalTitles.CaptureInProgress,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: buttonText,
        buttonsStyling: false,
        backdrop: 'rgba(0, 0, 0, 0.8)',
        customClass: {
          confirmButton: buttonClass
        },
        preConfirm () {
          Vue.swal.insertQueueStep(cancelXRaySwalOptions);
        }
      };

      const isVisible = Vue.swal.isVisible();

      if (!isVisible) await Vue.swal.queue([swalOptions]);
      else Vue.swal.insertQueueStep(swalOptions);
    },
    updateProcessingModal (): void {
      const sensorState: SensorState = vuexStore.get('sensor/sensorState');

      const { buttonText, buttonClass } = generateProcessingButtonProperties(sensorState);

      Vue.swal.update({ confirmButtonText: buttonText, customClass: { confirmButton: buttonClass } });
    },
    async launchCancelXRayModal (): Promise<void> {
      await Vue.swal.queue([cancelXRaySwalOptions]);
    }
  },
  namespaced: true
};

export default xRay;
