import Vue, { Component } from 'vue';

export interface IModalInfo {
  component: Component;
  props?: object | undefined;
}

export enum ModalEventNames {
  closeModal = 'closeModal',
  launchModal = 'launchModal',
  closeAll = 'closeAll',
  imageSelected = 'image-selected'
}

export default new Vue({
  methods: {
    launchModal (component: Component, props: object | undefined = undefined): void {
      this.$emit(ModalEventNames.launchModal, { component, props });
    }
  }
});
