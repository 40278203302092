/* eslint-disable */

import dayjs from 'dayjs';
import { once } from 'lodash';
import { ActionContext, Module, Store } from 'vuex';
import { commit, dispatch, make } from 'vuex-pathify';
import vuexStore, { IRootState } from '..';
import { get, post } from '../restfulState';
import FMSAxios from '@/api/FMSAxios';
import { IStudyListStudy } from '@/api/ExtendedModels';
import { IStudieModel, IImageModel, IAnnotation, IDownloadImagesRequest } from '@/api/Models';
import { isAppLocal } from '@/helpers/config';
import { configureScope } from '@sentry/vue';

export interface IStudyState {
  selectedImage?: IImageModel;
  selectedStudy?: IStudieModel;
  workList: IStudieModel[];
  studyList: IStudyListStudy[];
  images: IImageModel[];
}

interface IRejectionDetails {
  rejectionReason: string;
  imageId: number;
}

interface IEditDetails {
  annotation: IAnnotation;
  imageId: number;
}

const _state: IStudyState =
{
  selectedStudy: undefined,
  selectedImage: undefined,
  workList: [],
  studyList: [],
  images: []
};

const study: Module<IStudyState, IRootState> = {
  state: _state,
  getters: {
    getCurrentModalityImages(state, getters, root): IImageModel[] {
      const sensorModality = root.sensor.sensor?.modality;
      if (sensorModality) {
        return state.images.filter(image => image.modality === sensorModality);
      }
      throw new Error('Trying get current modality images when no sensor modality has been detected');
    },
    getIsSelectedStudyToday(): boolean {
      const selectedStudy = vuexStore.get('study/selectedStudy');

      if (!selectedStudy) return false;

      return dayjs(selectedStudy?.createdOn).isToday();
    },
    getSelectedStudyHasImages(state): boolean {
      return state.images.length > 0;
    }
  },
  mutations: {
    ...make.mutations(_state),
    addImage(state, image: IImageModel): void {
      state.images.unshift(image);
    },
    setSelectedStudy (state, studyData: IStudieModel) {
      state.selectedStudy = studyData;
    },
  },
  actions: {
    initialize: once((context: ActionContext<IStudyState, IRootState>, store: Store<IRootState>) => {
      if (isAppLocal()) {
        dispatch('study/fetchWorkList');
      }
      dispatch('study/fetchStudyList');

      store.watch(state => {
        return {
          selectedStudy: state.study.selectedStudy,
          patientId: state.patient.patient?.id,
          clinicId: state.clinic.clinic?.id
        };
      }, async (newState, oldState) => {
        if (newState.clinicId !== oldState.clinicId) {
          dispatch('study/fetchStudyList');
        }

        if (newState.patientId !== oldState.patientId && newState.selectedStudy?.patientId !== newState.patientId) {
          commit('study/setSelectedStudy', undefined);
        }

        // required for getSelectedStudyHasImages getter
        if (newState.selectedStudy?.id !== oldState.selectedStudy?.id) {
          commit('study/setImages', []);
          if (newState.selectedStudy) {
            await get(`/image?studyId=${newState.selectedStudy.id}&isRejected=0`, 'study/setImages');
          }
        }
      });
    }),
    async postStudy(context, patientId: number): Promise<IStudieModel> {
      const clinicId = this.state.clinic.clinic?.id;
      const userId = this.state.user.user?.id;
      if (!clinicId || !userId) {
        throw new Error('Attempting to create a study without a clinic ID');
      }
      const body: Partial<IStudieModel> = {
        patientId: patientId,
        // CreatedOn, sopInstanceUID and studyInstance UID are temporary while they're still required in the model
        createdOn: new Date(),
        studyInstanceUID: 'dummy',
        sopInstanceUID: 'dummy',
        clinicId,
        userId
      };

      const studyData = await post('/study', body, 'study/setSelectedStudy') as IStudieModel;

      await dispatch('study/fetchWorkList');
      await dispatch('study/fetchStudyList');

      return studyData;
    },
    postReport(context, payload: { pdfBlob: Blob; fileName: string }) {
      const { pdfBlob, fileName } = payload;
      const formData = new FormData();
      const stringStudyId = context.state.selectedStudy?.id?.toString();

      if (!stringStudyId) {
        throw new Error('No study has been selected');
      }

      formData.append('pdfFile', pdfBlob);
      formData.append('studyId', stringStudyId);
      formData.append('fileName', fileName);

      return FMSAxios.post('study/pdf', formData);
    },
    async fetchWorkList(): Promise<void> {
      const clinicId: number = this.get('clinic/clinic@id');
      await get(`study/worklist/${clinicId}`, 'study/setWorkList');
    },
    async fetchStudyList(): Promise<void> {
      const clinicId: number = this.get('clinic/clinic@id');
      const currentDate = new Date(); // Get the current datetime

      var patientId = 0;
      var fromDate = (new Date(currentDate.getFullYear()-10, currentDate.getMonth())).toISOString().slice(0, 10); // Subtract 3 from the current month to get the last 3 monthes
      var patientName = '!';
      var toDate = new Date().toISOString().split('T')[0];
      var isEmptySearch = true;
      const studyDateFromControl = document.getElementsByName('StudyDateFromHidden')[0] as HTMLInputElement | null;
      if (studyDateFromControl != null && studyDateFromControl.value.length > 0) {
        const date = new Date(studyDateFromControl.value);
        fromDate = date.toLocaleDateString('en-CA');
        isEmptySearch = false;
      }

      const studyDateToControl = document.getElementsByName('StudyDateToHidden')[0] as HTMLInputElement | null;
      if (studyDateToControl != null && studyDateToControl.value.length > 0) {
        const date = new Date(studyDateToControl.value);
        toDate = date.toLocaleDateString('en-CA');
        isEmptySearch = false;
      }


      const patientIdControl = document.getElementsByName('patient-Id')[0] as HTMLInputElement | null;
      if (patientIdControl != null && patientIdControl !== undefined) {
        if (patientIdControl.value.length > 0) {
          patientId = parseInt(patientIdControl.value);
          isEmptySearch = false;
        }
      }

      const patientNameControl = document.getElementsByName('patient-name')[0] as HTMLInputElement | null;
      if (patientNameControl != null && patientNameControl !== undefined) {
        if (patientNameControl.value.length > 0) {
          patientName = patientNameControl.value;
          isEmptySearch = false;
        }
      }
      if (isEmptySearch === true) {
        fromDate = (new Date(currentDate.getFullYear(), currentDate.getMonth() - 3)).toISOString().slice(0, 10);
      }
      var requestUrl = `study/studylistByFilters/${clinicId}/${patientName}/${patientId}/${fromDate}/${toDate}`;
      await get(requestUrl, 'study/setStudyList');
    },
    async fetchStudyById (context,studyId:number): Promise<void> {
      const requestUrl = `study/${studyId}`;
      const response = await FMSAxios.get(requestUrl);
      commit('study/setSelectedStudy', response.data);
    },

    async studylistForXrayLogReport(): Promise<void> {
      const clinicId: number = this.get('clinic/clinic@id');
      const currentDate = new Date(); // Get the current datetime

      var patientId = 0;
      var fromDate = (new Date(currentDate.getFullYear() - 10, currentDate.getMonth())).toISOString().slice(0, 10); // Subtract 3 from the current month to get the last 3 monthes
      var patientName = '!';
      var toDate = new Date().toISOString().split('T')[0];
      var isEmptySearch = true;
      const studyDateFromControl = document.getElementsByName('StudyDateFromHidden')[0] as HTMLInputElement | null;
      if (studyDateFromControl != null && studyDateFromControl.value.length > 0) {
        const date = new Date(studyDateFromControl.value);
        fromDate = date.toLocaleDateString('en-CA');
        isEmptySearch = false;
      }

      const studyDateToControl = document.getElementsByName('StudyDateToHidden')[0] as HTMLInputElement | null;
      if (studyDateToControl != null && studyDateToControl.value.length > 0) {
        const date = new Date(studyDateToControl.value);
        toDate = date.toLocaleDateString('en-CA');
        isEmptySearch = false;
      }


      const patientIdControl = document.getElementsByName('patient-Id')[0] as HTMLInputElement | null;
      if (patientIdControl != null && patientIdControl !== undefined) {
        if (patientIdControl.value.length > 0) {
          patientId = parseInt(patientIdControl.value);
          isEmptySearch = false;
        }
      }

      const patientNameControl = document.getElementsByName('patient-name')[0] as HTMLInputElement | null;
      if (patientNameControl != null && patientNameControl !== undefined) {
        if (patientNameControl.value.length > 0) {
          patientName = patientNameControl.value;
          isEmptySearch = false;
        }
      }
      if (isEmptySearch === true) {
        fromDate = (new Date(currentDate.getFullYear(), currentDate.getMonth() - 3)).toISOString().slice(0, 10);
      }
      var requestUrl = `study/studylistForXrayLogReport/${clinicId}/${patientName}/${patientId}/${fromDate}/${toDate}`;
      await get(requestUrl, 'study/setStudyList');
    },
    async refreshAndfetchStudyList(): Promise<void> {
      const clinicId: number = this.get('clinic/clinic@id');
      const currentDate = new Date(); // Get the current datetime
      var patientId = 0;
      var fromDate = (new Date(currentDate.getFullYear(), currentDate.getMonth() - 3)).toISOString().slice(0, 10); // Subtract 3 from the current month to get the last 3 monthes
      var patientName = '!';
      var toDate = new Date().toISOString().split('T')[0];

      const datePickerInputs = document.querySelectorAll('.vdp-datepicker div input') as NodeListOf<HTMLInputElement>;
      datePickerInputs.forEach(datePickerInput => {
        datePickerInput.value = '';
      });

      const studyDateFromControl = document.getElementsByName("StudyDateFromHidden")[0] as HTMLInputElement | null;
      if (studyDateFromControl != null && studyDateFromControl.value.length > 0) {
        studyDateFromControl.value = '';
      }

      const studyDateToControl = document.getElementsByName("StudyDateToHidden")[0] as HTMLInputElement | null;
      if (studyDateToControl != null && studyDateToControl.value.length > 0) {
        studyDateToControl.value = '';
      }


      const patientIdControl = document.getElementsByName("patient-Id")[0] as HTMLInputElement | null;
      if (patientIdControl != null && patientIdControl !== undefined) {
        if (patientIdControl.value.length > 0) {
          patientIdControl.value = '';
        }
      }

      const patientNameControl = document.getElementsByName("patient-name")[0] as HTMLInputElement | null;
      if (patientNameControl != null && patientNameControl !== undefined) {
        if (patientNameControl.value.length > 0) {
          patientNameControl.value = '';
        }
      }
      var requestUrl = `study/studylistByFilters/${clinicId}/${patientName}/${patientId}/${fromDate}/${toDate}`;
      await get(requestUrl, 'study/setStudyList');
    },
    async editImageAnnotations(context, editDetails: IEditDetails): Promise<void> {
      const { imageId, annotation } = editDetails;
      const response = await FMSAxios.put('image/annotations', {
        imageId,
        annotation
      });

      const updatedImage: IImageModel = response.data;
      const clonedImages = [...context.state.images];
      const imageIndex = clonedImages.findIndex(image => image.id === updatedImage.id);
      clonedImages.splice(imageIndex, 1, updatedImage);

      commit('study/setImages', clonedImages);
    },
    async getXLogReports(context, {  selectedOptionsArray }): Promise<void> {
      var isEmptySearch = true;
      const clinicId: number = this.get('clinic/clinic@id');
      const currentDate = new Date(); // Get the current datetime
      var fromDate = (new Date(currentDate.getFullYear(), currentDate.getMonth() - 3)).toISOString().slice(0, 10); // Subtract 3 from the current month to get the last 3 monthes
      var toDate = new Date().toISOString().split('T')[0];

      const datePickerInputs = document.querySelectorAll('.vdp-datepicker div input') as NodeListOf<HTMLInputElement>;
      datePickerInputs.forEach(datePickerInput => {
        datePickerInput.value = '';
      });

      const studyDateFromControl = document.getElementsByName('StudyDateFromHidden')[0] as HTMLInputElement | null;
      if (studyDateFromControl != null && studyDateFromControl.value.length > 0) {
        const date = new Date(studyDateFromControl.value);
        fromDate = date.toLocaleDateString('en-CA');
        isEmptySearch = false;
      }

      const studyDateToControl = document.getElementsByName('StudyDateToHidden')[0] as HTMLInputElement | null;
      if (studyDateToControl != null && studyDateToControl.value.length > 0) {
        const date = new Date(studyDateToControl.value);
        toDate = date.toLocaleDateString('en-CA');
        isEmptySearch = false;
      }
      if (isEmptySearch === true) {
        fromDate = (new Date(currentDate.getFullYear(), currentDate.getMonth() - 3)).toISOString().slice(0, 10);
      }
      const tags = selectedOptionsArray.join('&tags=');
      const requestUrl = `study/GetXLogReport/${clinicId}/${fromDate}/${toDate}?tags=${tags}`;

      const response = await FMSAxios.get(requestUrl);
      try {
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'text/csv' });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'CaptureXray-LogReport-' + fromDate +'-'+toDate+'-'+clinicId+'.csv'; // Adjust the filename based on the server response

        // Append the link to the DOM
        document.body.appendChild(a);

        // Simulate a click to trigger the download
        a.click();

        // Remove the link from the DOM
        document.body.removeChild(a);

        // Revoke the object URL to free up resources
        window.URL.revokeObjectURL(url);
      } else {
        // Handle errors if needed
        console.error('Error downloading XLogReport');
      }
    } catch(error) {
      // Handle errors if needed
      console.error('Error in fetch', error);
    }
    },
    async rejectImage(context, rejectImageDetails: IRejectionDetails): Promise<void> {
      const userId: number = this.get('auth/userId');

      const rejectImageRequest = {
        rejectionReason: rejectImageDetails.rejectionReason,
        imageId: rejectImageDetails.imageId,
        userId: userId,
        createdOn: new Date()
      };

      await FMSAxios.post('image/reject', rejectImageRequest);
      await dispatch('xRay/fetchImages');
    },
    async fetchImage(context, imageId: number): Promise<IImageModel> {
      const response = await FMSAxios.get(`image/${imageId}`);

      return response.data;
    }
  },
  namespaced: true
};

export default study;
