















import Vue from 'vue';
import dayjs, { Dayjs } from 'dayjs';
import { get, sync } from 'vuex-pathify';

import { IStudieModel } from '@/api/Models';

export default Vue.extend({
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      date: undefined as Dayjs | undefined
    };
  },
  computed: {
    selectedStudy: get<IStudieModel>('study/selectedStudy'),
    clinicName: get<string>('clinic/clinic@name'),
    patientName: get<string>('patient/patient@name'),
    title: sync<string | undefined>('report/title')
  },
  created () {
    this.date = dayjs(this.selectedStudy.createdOn);
  }
});
