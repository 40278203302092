





























































import Vue from 'vue';
import PageTemplate from '@/components/page-template/PageTemplate.vue';
import Table from './components/Table.vue';
import { get } from 'vuex-pathify';
import { IRadCaseModel } from '@/api/Models';

export default Vue.extend({
  components: {
    PageTemplate,
    Table
  },
  data () {
    return {
      StudyDateFrom: '',
      StudyDateTo: '',
      selectedStatus: 1,
    };
  },
  computed: {
    cases: get('radcase/radcases'),
    availableCount (): number {
      return this.cases.filter((caseItem: IRadCaseModel) => caseItem.status === 1).length;
    },
    assignedCount (): number {
      return this.cases.filter((caseItem: IRadCaseModel) => caseItem.status === 2).length;
    },
    waitingsCount (): number {
      return this.cases.filter((caseItem: IRadCaseModel) => caseItem.status === 3).length;
    },
    finalizedCount (): number {
      return this.cases.filter((caseItem: IRadCaseModel) => caseItem.status === 4).length;
    },
    cancelledCount (): number {
      return this.cases.filter((caseItem: IRadCaseModel) => caseItem.status === 5).length;
    }
  },
  watch: {
    StudyDateFrom (value) {
      const hiddenInput = document.getElementsByName('StudyDateFromHidden')[0] as HTMLInputElement;
      if (hiddenInput) {
        hiddenInput.value = value;
      }
    },
    StudyDateTo (value) {
      const hiddenInput = document.getElementsByName('StudyDateToHidden')[0] as HTMLInputElement;
      if (hiddenInput) {
        hiddenInput.value = value;
      }
    }
  },
  mounted () {
    const datePickerInputs = document.querySelectorAll('.vdp-datepicker div input') as NodeListOf<HTMLInputElement>;
    datePickerInputs.forEach(datePickerInput => {
      datePickerInput.addEventListener('blur', () => {
        datePickerInput.style.backgroundColor = 'transparent';
      });
    });
  },
  methods: {
    filterCasesbyStatus (status: number): void {
      this.selectedStatus = status;
      this.$store.dispatch('radcase/fetchCasesByStatus', status);
    }
  }
});
