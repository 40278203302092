

















































import Vue from 'vue';
import { throttle } from 'lodash';
import StarterKit from '@tiptap/starter-kit';
import { Editor, EditorContent } from '@tiptap/vue-2';
import { faBold, faItalic, faUnderline, faUndo, faRedo } from '@fortawesome/free-solid-svg-icons';

import EmptyLineBreakClean from './EmptyLineBreakClean';
import OverflowLimit from './OverflowLimit';

export default Vue.extend({
  components: {
    EditorContent
  },
  props: {
    heightLimit: {
      type: Number,
      required: true
    },
    isActive: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      editor: null as null | Editor,
      menuBarHeight: 45,
      faBold,
      faItalic,
      faUnderline,
      faUndo,
      faRedo
    };
  },
  mounted () {
    const heightLimitOffset = 50;
    const contentHeightLimit = this.heightLimit - this.menuBarHeight - heightLimitOffset;

    this.editor = new Editor({
      extensions: [
        StarterKit.configure({
          heading: { levels: [2, 3] },
          history: { depth: 1, newGroupDelay: 100 }
        }),
        OverflowLimit.configure({
          maxHeight: contentHeightLimit
        }),
        EmptyLineBreakClean
      ],
      content: '<p>Write your report here....</p>',
      onUpdate: throttle((props) => {
        this.$emit('input', props.editor.getHTML());
      }, 3000)
    });
  },
  beforeDestroy () {
    if (this.editor) {
      this.editor.destroy();
    }
  }
});
