import { getConfig } from '@/helpers/config';
import cornerstone from 'cornerstone-core';
import cornerstoneTools, { Handles, importInternal } from 'cornerstone-tools';
import { IDisplay, IGridPayload } from './images/images-types';
import cornerstoneWebImageLoader from 'cornerstone-web-image-loader';
import { IImageModel } from '../../api/Models';

const getPixelSpacing = importInternal('util/getPixelSpacing');

export function createJpegUrl (imageData: ImageData): string {
  const newCanvas = document.createElement('canvas');
  const newCanvasContext = newCanvas.getContext('2d');

  if (!newCanvasContext) {
    throw new Error('Context identifier not found');
  }

  newCanvas.width = imageData.width;
  newCanvas.height = imageData.height;
  newCanvasContext.putImageData(imageData, 0, 0);
  return newCanvas.toDataURL('image/jpeg', 1.0);
}

export function getDisplay (displayGrid: IDisplay[], payload: IGridPayload): IDisplay | undefined {
  return displayGrid.find(display => display.row === payload.row && display.column === payload.column);
}

export function getDisplayIndex (displayGrid: IDisplay[], payload: IGridPayload): number {
  return displayGrid.findIndex(display => display.row === payload.row && display.column === payload.column);
}

export function getImageIndex (imagesOfStudy: IImageModel[], displayImage: any): number {
  return imagesOfStudy.findIndex(img => img.id?.toString() === displayImage.imageId.split('/').pop().replace('.jpg', '')) + 1;
}
export function createCaseCornerstoneImageId (imageId: number): string {
  const apiRoot = getConfig('API_SERVER');
  const imageUri = `${apiRoot}image/case/${imageId}.jpg`;
  if (apiRoot.includes('http')) return imageUri;

  return `${location.origin}${imageUri}`;
}
export function getGlobalToolState (): any {
  return cornerstoneTools.globalImageIdSpecificToolStateManager.saveToolState();
}

export function cropImage (display: IDisplay, cropCoords: Handles): Promise<void> {
  return new Promise(resolve => {
    const { enabledHtmlElement, image } = display;
    const { imageId, getCanvas } = image;
    if (!enabledHtmlElement || !imageId || !cropCoords || !cropCoords.start || !cropCoords.end) {
      throw new Error('Data missing for crop');
    }
    const cropWidth = Math.floor(cropCoords.end.x - cropCoords.start.x);
    const cropHeight = Math.floor(cropCoords.end.y - cropCoords.start.y);

    const canvasContext: CanvasRenderingContext2D = getCanvas().getContext('2d');
    const pixelArr = canvasContext.getImageData(cropCoords.start.x, cropCoords.start.y, cropWidth, cropHeight);
    const croppedImageData = new ImageData(pixelArr.data, cropWidth, cropHeight);
    const url = createJpegUrl(croppedImageData);
    const jpegImage = new Image(cropWidth, cropHeight);

    jpegImage.onload = () => {
      // image IDs must be unique because the image loader will return the previously drawn image if a duplicate image ID is detected
      const newImageId = `${imageId}/cropUID/(${cropCoords.start.x}, ${cropCoords.start.y}) | (${cropCoords.end.x}, ${cropCoords.end.y})`;
      const newImage = cornerstoneWebImageLoader.createImage(
        jpegImage,
        newImageId
      );

      const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);
      newImage.columnPixelSpacing = colPixelSpacing;
      newImage.rowPixelSpacing = rowPixelSpacing;

      const layerId = cornerstone.addLayer(enabledHtmlElement, newImage);
      cornerstone.setActiveLayer(enabledHtmlElement, layerId);

      resolve();
    };
    jpegImage.src = url;
  });
}
