import Vue from 'vue';
import { SwalTitles } from '@/state/enums';

export function isSwalOpen (title: SwalTitles): boolean {
  const modalTitle = Vue.swal.getTitle()?.innerHTML;

  if (modalTitle !== title) return false;

  return true;
}

export function fireError (errorMessage: string): void {
  Vue.swal.fire({
    title: SwalTitles.Error,
    text: errorMessage,
    icon: 'error'
  });
}
