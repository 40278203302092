







import Vue from 'vue';
export default Vue.extend({
  props: {
    resetOnComplete: {
      type: Boolean,
      default: true
    },
    click: {
      type: Function,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: false,
      hasError: false
    };
  },
  computed: {
    isDisabled (): boolean {
      return !!this.isLoading || this.disabled;
    }
  },
  methods: {
    async onClick (): Promise<void> {
      try {
        this.hasError = false;
        this.isLoading = true;
        const result = await this.click();
        if (result === false) {
          this.hasError = true;
        }
        this.handleCleanup();
      } catch (error) {
        this.hasError = true;
        this.isLoading = false;
        throw error;
      } finally {
        this.handleCleanup();
      }
    },
    handleCleanup (): void {
      if (this.resetOnComplete || this.hasError) {
        this.isLoading = false;
      }
    }
  }
});
