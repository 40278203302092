


































import Vue from 'vue';
import dayjs from 'dayjs';
import { jsPDF as JsPDF } from 'jspdf';
import { commit, dispatch, get } from 'vuex-pathify';

import { isAppLocal, isServerLocal } from '@/helpers/config';
import FMSAxios from '@/api/FMSAxios';
import generatePDF from '@/helpers/pdf';
import Page from './components/Page.vue';
import { IStudieModel } from '@/api/Models';
import { IReportPage } from '@/state/report';
import CreateReportPageButton from '@/components/CreateReportPageButton.vue';
import { localDownload } from '@/state/restfulState';

export default Vue.extend({
  components: { Page, CreateReportPageButton },
  data () {
    return {
      resetOnComplete: false
    };
  },
  computed: {
    pages: get<IReportPage[]>('report/pages'),
    selectedStudy: get<IStudieModel>('study/selectedStudy')

  },
  mounted () {
    if (this.pages.length > 0) {
      dispatch('report/activatePage', 0);
    }
  },
  methods: {
    async onBackClick (): Promise<void> {
      const result = await this.$swal.fire({
        title: 'Go Back?',
        text: 'The reports will not be saved',
        showConfirmButton: true,
        showCancelButton: true
      });

      if (result.isConfirmed) {
        commit('report/flush');
        this.$router.go(-1);
      }
    },
    onThumbnailClick (pageIndex: number): void {
      const pageRefs = this.$refs.pageRefs as Element[];
      const pageElement = pageRefs[pageIndex];

      pageElement.scrollIntoView({
        behavior: 'smooth'
      });

      dispatch('report/activatePage', pageIndex);
    },
    async generatePDF (): Promise<void> {
      const promises = this.pages.map((page, index) => dispatch('report/disablePage', index));
      await Promise.all(promises);

      this.resetOnComplete = false;

      const pageContainerRefs = this.$refs.pageRefs as HTMLElement[];
      const pageRefs = pageContainerRefs.map(pageRef => pageRef.firstChild) as HTMLElement[];

      const format = [279 * 3.7, 216 * 3.7]; // 1:1.2941 Aspect Ratio
      const doc = new JsPDF({ unit: 'px', format });

      try {
        await generatePDF(pageRefs, doc);
        const fileName = dayjs().format('YYYY-MM-DD_HH.mm.ss') + '.pdf';

        const pdfBlob = doc.output('blob');

        if (isAppLocal()) {
          await dispatch('study/postReport', { pdfBlob, fileName });
        }

        if (isAppLocal() && isServerLocal()) {
          await localDownload(`study/${this.selectedStudy?.id}/report/local`);
        } else {
          doc.save(fileName);
        }
      } finally {
        this.resetOnComplete = true;
      }
    }
  }
});
