import { Extension } from '@tiptap/core';

interface IOptions {
  maxHeight: number;
}

export default Extension.create<IOptions>({
  onUpdate () {
    const editorElement = this.editor.view.dom;
    if (editorElement.clientHeight >= this.options.maxHeight) {
      const editor = this.editor as any;
      editor.chain().undo().run();
    }
  }
});
