




















import Vue from 'vue';

export default Vue.extend({
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      showMenu: false
    };
  },
  computed: {
    isActive (): boolean {
      return this.options.some(option => option === this.value);
    },
    toggleLabel (): string {
      return this.isActive ? this.value : '...';
    }
  },
  methods: {
    onItemClick (option: string): void {
      this.$emit('input', option);
    },
    onToggleClick (): void {
      // reset value before opening up dropdown
      this.$emit('input', undefined);
      this.showMenu = true;

      this.$root.$el.addEventListener('mouseup', () => {
        this.showMenu = false;
      }, { once: true });
    }
  }
});
