
















import Vue from 'vue';

interface IFilePreview {
  name: string;
  src: Blob | string | ArrayBuffer | null;
  file: File;
}

export default Vue.extend({
  name: 'FileUpload',
  data () {
    return {
      filePreviews: [] as IFilePreview[]
    };
  },
  methods: {
    handleFileChange (event: Event) {
      const input = event.target as HTMLInputElement; // Type assertion to HTMLInputElement
      if (input.files && input.files.length > 0) {
        const files = Array.from(input.files);
        Array.from(files).forEach((file) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.filePreviews.push({
              name: file.name,
              src: e.target?.result ?? '',
              file
            });
            this.$emit('update:filePreviews', this.filePreviews);
          };
          reader.readAsDataURL(file);
        });
      }
    },
    isImage (file: File) {
      return file.type.startsWith('image/');
    },
    removeFile (index: number) {
      this.filePreviews.splice(index, 1);
      this.$emit('update:filePreviews', this.filePreviews);
    },
    formatSize (size: number) {
      if (typeof size !== 'number' || isNaN(size)) {
        return '0 B'; // Return a default value for invalid size
      }
      const i = Math.floor(Math.log(size) / Math.log(1024));
      return (
        (size / Math.pow(1024, i)).toFixed(2) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
      );
    }

  }
});
