





































































import Vue from 'vue';
import { capitalize } from 'lodash';
import { commit, get, sync } from 'vuex-pathify';
import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { IBreedModel, IStudieModel } from '@/api/Models';
import eventBus from '@/eventBus';
import addPatientModal from './AddPatientModal.vue';

export default Vue.extend({
  data () {
    return {
      addIcon: faPlus,
      editIcon: faPen
    };
  },
  computed: {
    breeds: get('breed/breeds'),
    owner: get('owner/owner'),
    patient: sync('patient/patient'),
    patients: get('owner/patients'),
    patientId: get('patient/patient@id'),
    patientBreedId: get('patient/patient@breedId'),
    todaysStudy: get<IStudieModel>('patient/todaysStudy'),
    patientBreedName (): string {
      return this.formatBreedName();
    },
    showSelectBar (): boolean {
      return !!(this.owner && this.patients?.length >= 1);
    }
  },
  methods: {
    onClick (buttonType: string) {
      eventBus.launchModal(addPatientModal, { buttonType });
    },
    onInput (): void {
      commit('study/setSelectedStudy', this.todaysStudy);
    },
    formatBreedName (): string {
      if (!this.patientBreedId || !this.breeds?.length) return '';

      const patientBreed = this.breeds.find((breed: IBreedModel) => breed.id === this.patientBreedId);

      if (!patientBreed.name) return '';

      const nameArray = patientBreed.name.split(' ');
      const updatedArray = nameArray.map((name: string) => capitalize(name));

      return updatedArray.join(' ');
    }
  }
});
