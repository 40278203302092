







import { IPetOwnerModel } from '@/api/Models';
import Vue from 'vue';
import { get } from 'vuex-pathify';

export default Vue.extend({
  computed: {
    patientName: get('patient/patient@name'),
    owner: get<IPetOwnerModel>('owner/owner'),
    studyDate: get('study/selectedStudy@createdOn'),
    ownerName (): string {
      if (this.owner) {
        return this.owner.name;
      }

      return '';
    },
    finishedLoading (): boolean {
      return !!this.patientName && !!this.ownerName && !!this.studyDate;
    }
  }
});
