







import Vue from 'vue';

export default Vue.extend({
  props: {
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: Object,
      required: true
    },
    link: {
      type: String,
      required: true
    }
  }
});
