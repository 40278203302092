

















import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: [String, Number],
      default: undefined
    },
    label: {
      type: String,
      default: undefined
    },
    inputColumn: {
      type: String,
      default: 'col-12'
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onInput () {
      const input = this.$refs.input as HTMLInputElement;
      this.$emit('input', input.value);
    }
  }
});
