







































import Vue from 'vue';
import { Prop } from 'vue/types/options';
import { get, dispatch } from 'vuex-pathify';
import { isString, omit, startCase } from 'lodash';
import ModalFrame from '../modal/ModalFrame.vue';
import { GeneratorStatusKeys } from '@/state/generator/helpers';
import { ISensorInfo, ISensorStatus, Modality } from '@/api/Models';

export default Vue.extend({
  components: { ModalFrame },
  filters: {
    formatValue (value: string | number): string | number {
      let result = value;

      if (isString(value)) {
        result = startCase(value);
      }

      return result;
    }
  },
  data () {
    return {
      isLoading: false,
      sensorInfo: {},
      generatorStatus: {} as Prop<GeneratorStatusKeys>,
      errorStatus: undefined,
      errorMessage: ''
    };
  },
  computed: {
    sensorBrand: get<string>('sensor/sensor@brand'),
    modality: get<Modality>('sensor/sensor@modality'),
    sensorStatus: get<ISensorStatus>('sensor/sensorStatus'),
    generatorDisabled: get('generator/generatorDisabled'),
    showGeneratorStatus (): boolean {
      if (this.generatorDisabled) return false;

      if (this.modality === Modality.General) return true;
      return false;
    }
  },
  async created () {
    await this.fetchHardwareStatus();
  },
  methods: {
    async fetchHardwareStatus (): Promise<void> {
      this.isLoading = true;

      try {
        const sensorInfo: ISensorInfo = await dispatch('sensor/fetchSensorInfo');
        this.sensorInfo = omit(sensorInfo, 'status');

        if (this.modality === Modality.General && !this.generatorDisabled) {
          this.generatorStatus = await dispatch('generator/getGeneratorStatus');
        }
      } catch (e) {
        this.errorStatus = e.response?.data?.status;
        this.errorMessage = e.response?.data?.message ? e.response.data.message : e.message;
      } finally {
        this.isLoading = false;
      }
    }
  }
});
