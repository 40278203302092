











import Vue from 'vue';
import PageTemplate from '@/components/page-template/PageTemplate.vue';
import LoginForm from './components/LoginForm.vue';
import ContactInfo from './components/ContactInfo.vue';

export default Vue.extend({
  components: {
    PageTemplate,
    LoginForm,
    ContactInfo
  }
});
