/* eslint-disable @typescript-eslint/no-explicit-any */
import { importInternal } from 'cornerstone-tools';

import { length, getSide } from '../utils/math';
const roundToDecimal = importInternal('util/roundToDecimal');

function calculateTplo (lines: any, image: any): number {
  // get mid point of the perpendicular line
  const pointC = {
    x: (lines[1].handles.perpendicularStart.x + lines[1].handles.perpendicularEnd.x) / 2,
    y: (lines[1].handles.perpendicularStart.y + lines[1].handles.perpendicularEnd.y) / 2
  };
  // Point B depends on which of ther perpendicular line coordinates are closer to point A
  const pointA = lines[2].handles.end;
  const distanceFromPerpStart = length(getSide(image, lines[1].handles.perpendicularStart, pointA));
  const distanceFromPerpEnd = length(getSide(image, lines[1].handles.perpendicularEnd, pointA));

  const pointB = distanceFromPerpStart < distanceFromPerpEnd ? lines[1].handles.perpendicularStart : lines[1].handles.perpendicularEnd;

  const sideBC = getSide(image, pointB, pointC);
  const sideAC = getSide(image, pointA, pointC);
  const sideAB = getSide(image, pointA, pointB);

  const sideBCLength = length(sideBC);
  const sideACLength = length(sideAC);
  const sideABLength = length(sideAB);

  // Cosine law
  let angle = Math.acos(
    (Math.pow(sideBCLength, 2) +
      Math.pow(sideACLength, 2) -
      Math.pow(sideABLength, 2)) /
    (2 * sideBCLength * sideACLength)
  );
  angle *= 180 / Math.PI;
  const tplo = roundToDecimal(angle, 2);

  return tplo;
}

export default calculateTplo;
