/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-case-declarations */
import { importInternal, getToolState, LengthTool, BidirectionalTool, CircleRoiTool, Measurement, Handle } from 'cornerstone-tools';
import { getImage } from 'cornerstone-core';

import handleSelectedMouseCallbackPerpendicular from './utils/handleSelectedMouseCallbackPerpendicular';

import addMeasurementDefault from './add-measurment/addMeasurementDefault';
import addMeasurementPerpendicular from './add-measurment/addMeasurementPerpendicular';
import renderDataLength from './render/renderDataLength';
import renderDataCircle from './render/renderDataCircle';
// Math
import getOppositePoint from './utils/getOppositePoint';
import createPerpendicularLineHandles from './utils/createPerpendicularLineHandles';
import calculateTplo from './calculations/calculateTplo';

import dependencySync from './utils/dependencySync';
import staticSync from './utils/staticSync';
import { removeFromOverlay, removeAllFromOverlay, attachToOverlay } from './utils/toolOverlay';
import Instructor from './utils/instructor';

const moveAnnotation = importInternal('manipulators/moveAnnotation');
const lengthCursor = importInternal('tools/cursors').lengthCursor;
const getLogger = importInternal('util/getLogger');
const moveHandleNearImagePoint = importInternal(
  'manipulators/moveHandleNearImagePoint'
);

const BaseAnnotationTool = importInternal('base/BaseAnnotationTool');
const logger = getLogger('tools:annotation:TPLOTool');

function emptyLocationCallback (measurementData: any, eventData: any, doneCallback: () => void): void {
  doneCallback();
}

export default class TPLOTool extends BaseAnnotationTool {
  constructor (props = {}) {
    const defaultProps = {
      name: 'TPLO',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      svgCursor: lengthCursor,
      configuration: {
        changeMeasurementLocationCallback: emptyLocationCallback,
        getMeasurementLocationCallback: emptyLocationCallback,
        drawHandles: true,
        drawHandlesOnHover: false,
        hideHandlesIfMoving: false,
        renderDashed: false
      }
    };

    super(props, defaultProps);
    this.handleSelectedMouseCallbackPerpendicular = handleSelectedMouseCallbackPerpendicular.bind(
      this
    );

    this.lengthTool = new LengthTool();
    this.bidirectionalTool = new BidirectionalTool();
    this.circleRoiTool = new CircleRoiTool();

    this.tplo = null;
    this.instructor = null;

    const dependencies = [
      { step: 2, dependsOn: [1] },
      { step: 3, dependsOn: [2, 1] }
    ];
    const statics = [{ step: 1, staticOn: [2, 3] }];
    this.staticSync = staticSync(statics);
    this.dependencySync = dependencySync(dependencies);
  }

  // returns the number of the next line that should be drawn as part of the calculation
  getCurrentStep (toolData: Measurement[]): number | undefined {
    const allNumbers = [1, 2, 3];
    const currentNumbers = toolData.map(line => line.step);
    const availableNumbers = allNumbers.filter(
      number => !currentNumbers.includes(number)
    );

    if (availableNumbers[0]) {
      return availableNumbers[0];
    }
  }

  createNewMeasurement (eventData: any): Measurement | undefined {
    if (!eventData.type.includes('mouse')) {
      return;
    }
    // Prevent more than 3 measurements being drawn
    const toolData = getToolState(eventData.element, this.name);
    if (toolData && toolData.data.length >= 3) {
      return;
    }
    const goodEventData =
      eventData && eventData.currentPoints && eventData.currentPoints.image;

    if (!goodEventData) {
      logger.error(
        `required eventData not supplied to tool ${this.name}'s createNewMeasurement`
      );

      return;
    }

    const step = toolData ? this.getCurrentStep(toolData.data) : 1;
    const { x, y } = eventData.currentPoints.image;

    // add data of perpendicular handles if drawing second line
    const perpendicularHandles =
      step === 2 ?
        {
          perpendicularStart: {
            perpendicular: true,
            index: 2,
            locked: true
          },
          perpendicularEnd: {
            perpendicular: true,
            index: 3
          }
        } : {};

    const secondLine =
      step === 3 ? toolData.data.find(line => line.step === 2) : null;

    let start: Handle = { x, y };
    if (step === 2) {
      // starting handle will be at the center of the ellipse
      start = toolData.data[0].handles.start;
    } else if (step === 3) {
      start = getOppositePoint(secondLine, eventData.currentPoints.image);
    }

    return {
      step,
      visible: true,
      active: true,
      toolType: this.name,
      color: undefined,
      invalidated: true,
      handles: {
        ...perpendicularHandles,
        start: {
          index: step === 2 ? 0 : undefined,
          perpendicular: step === 2,
          x: start.x,
          y: start.y,
          highlight: true,
          active: false
        },
        end: {
          index: step === 2 ? 1 : undefined,
          perpendicular: step === 2,
          x,
          y,
          highlight: true,
          active: true
        },
        initialRotation: eventData.viewport.rotation,
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true
        }
      },
      longestDiameter: 0,
      shortestDiameter: 0
    };
  }

  addNewMeasurement (evt: any, interactionType: string): void {
    const element = evt.detail.element;
    const toolData = getToolState(element, this.name);
    const step = toolData ? this.getCurrentStep(toolData.data) : 1;

    switch (step) {
      case 1:
      case 3:
        return addMeasurementDefault.call(this, evt, interactionType);
      case 2:
        return addMeasurementPerpendicular.call(this, evt, interactionType);
      default:
    }
  }

  pointNearTool (element: HTMLElement, data: any, coords: any, interactionType: string): boolean {
    switch (data.step) {
      case 1:
        return this.circleRoiTool.pointNearTool(element, data, coords, interactionType);
      case 2:
        return (
          this.lengthTool.pointNearTool(element, data, coords) ||
          this.bidirectionalTool.pointNearTool(element, data, coords)
        );
      case 3:
        return this.lengthTool.pointNearTool(element, data, coords);
      default:
        return false;
    }
  }

  renderToolData (evt: any): void {
    const eventData = evt.detail;
    const toolData = getToolState(evt.currentTarget, this.name);

    if (!toolData) {
      return;
    }
    this.staticSync(toolData);
    this.dependencySync(toolData, eventData.element, this.name);
    // If 3 measurements have finished drawing or if TPLO has been calculated already
    const someActive = toolData.data.some(line => line.active);
    if ((toolData.data.length === 3 && !someActive) || !!this.tplo) {
      // If TPLO Angle is calculated but one of the measurements was deleted, set TPLO Angle to null again
      if (toolData.data.length < 3) {
        removeFromOverlay('tplo-result', eventData.element);
        this.tplo = null;
      } else {
        this.drawTplo(toolData.data, eventData.element, eventData.image);
      }
    }

    if (this.instructor) {
      const step = this.getCurrentStep(toolData.data);

      if (step && !someActive) this.instructor.setStage(step);
      else if (!step && !someActive) this.instructor.setStage(0);
    }

    // draw different shapes depending on where in the loop we are
    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];
      const step = data.step;

      switch (step) {
        case 1:
          renderDataCircle(eventData, data, this.configuration);
          break;

        case 2:
          renderDataLength(eventData, data, this.configuration, 'end');
          // only runs during initial drawing of the perpendicular line
          createPerpendicularLineHandles(eventData, data);
          data.invalidated = true;

          const perpendicularData: Measurement = {
            ...data,
            handles: {
              start: data.handles.perpendicularStart as Handle,
              end: data.handles.perpendicularEnd as Handle
            },
            static: false
          };
          const config = {
            ...this.configuration,
            drawHandlesOnHover: false
          };
          renderDataLength(eventData, perpendicularData, config);
          break;

        case 3:
          const secondLine = toolData.data.find(line => line.step === 2);
          // get coordinates of opposite point and assign it to data
          const start = getOppositePoint(secondLine, data.handles.end);
          const lineData = {
            ...data,
            handles: {
              ...data.handles,
              start: {
                ...data.handles.start,
                ...start
              }
            }
          };
          renderDataLength(eventData, lineData, this.configuration);
          break;
        default:
      }
    }
  }

  drawTplo (toolData: any, element: HTMLElement, image: any): void {
    removeAllFromOverlay(element);
    const lines = [
      toolData.find((line: any) => line.step === 1),
      toolData.find((line: any) => line.step === 2),
      toolData.find((line: any) => line.step === 3)
    ];
    this.tplo = calculateTplo(lines, image);
    attachToOverlay(
      `TPLO Angle: ${this.tplo}`,
      'tplo-result',
      element,
      20,
      240
    );
  }

  // cornerstone-tools shows a warning if we don't implement this method
  updateCachedStats (): void {
    return undefined;
  }

  handleSelectedCallback (evt: any, data: any, handle: any, interactionType = 'mouse'): void {
    if (data.static !== true || handle.perpendicular || handle.hasBoundingBox) {
      handle.perpendicular ?
        this.handleSelectedMouseCallbackPerpendicular(evt) :
        moveHandleNearImagePoint(evt, this, data, handle, interactionType);
    }
  }

  toolSelectedCallback (evt: any, data: Measurement, interactionType = 'mouse'): void {
    if (data.static !== true && data.step !== 2) {
      moveAnnotation(evt, this, data, interactionType);
    }
  }

  // Draw instructions when the tool activates
  activeCallback (element: HTMLElement): void {
    const canvas = Array.from(element.children).find(
      node => node.nodeName === 'CANVAS'
    );

    if (!this.instructor && canvas) {
      const text = [
        'Draw a circle covering the Centre of Talus',
        'Click on the base of intratrochanteric eminences,\r\n then drag the perpendicular line to the correct height',
        'Click on the cranial aspect tibial plateau, then adjust \r\n the line to go through both the cranial and caudal aspect plateaus'
      ];

      this.instructor = new Instructor(canvas as HTMLElement, text);
    }
    this.instructor.init();

    // attachement to overlay if needed
    const toolState = getToolState(element, this.name);
    if (toolState && canvas) {
      const toolData = toolState.data;
      if (toolData.length === 3) {
        const image = getImage(element);
        this.drawTplo(toolData, canvas as HTMLElement, image);
      }

      const nextStep = this.getCurrentStep(toolData);
      this.instructor.setStage(nextStep);
    }
  }

  passiveCallback (): void {
    if (this.instructor) {
      this.instructor.wipe();
    }
  }
}
