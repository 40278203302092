import Vue from 'vue';
import { VueAutosuggest } from 'vue-autosuggest';
import ColumnInputWrapper from '@/components/form/ColumnInputWrapper.vue';
import { IVueAutoSuggestResult } from '@/plugins/vueAutoSuggest/interfaces';

export const AutoSuggestBase = Vue.extend({
  components: {
    VueAutosuggest,
    ColumnInputWrapper
  },
  props: {
    value: {
      type: String,
      required: true
    }
  },
  methods: {
    getSuggestionValue (suggestion: IVueAutoSuggestResult<string>): string {
      return suggestion.item;
    },
    onInputChange (value: string): void {
      this.$emit('input', value);
    },
    onSuggestionSelected (suggestion: IVueAutoSuggestResult<string>): void {
      this.$emit('input', suggestion.item);
    },
    doesSuggestionIncludeInput (suggestion: string): boolean {
      const formattedInput = this.value.trim().toLowerCase();

      return suggestion.toLowerCase().includes(formattedInput);
    }
  }
});
