import { ActionContext, Module, Store } from 'vuex';
import { dispatch, make, commit } from 'vuex-pathify';
import { IRootState } from '..';
import { IDicomSendRequest, IDicomServerModel, IClinicModel, IImageModel } from '@/api/Models';
import FMSAxios from '@/api/FMSAxios';
import { once } from 'lodash';

export interface IDicomServerState {
  servers: IDicomServerModel[];
  requestsInProgress: number;
}

const _state: IDicomServerState = {
  servers: [],
  requestsInProgress: 0
};

const dicomServer: Module<IDicomServerState, IRootState> = {
  state: _state,
  mutations: {
    ...make.mutations(_state)
  },
  actions: {
    initialize: once(async (context: ActionContext<IDicomServerState, IRootState>, store: Store <IRootState>) => {
      await dispatch('dicomServer/fetchAll');

      store.watch((state) => {
        return {
          clinicId: state.clinic.clinic?.id
        };
      }, (newState, oldState) => {
        if (newState.clinicId !== oldState.clinicId) {
          dispatch('dicomServer/fetchAll');
        }
      });
    }),
    async fetchAll () {
      const clinic: IClinicModel = this.get('clinic/clinic');
      const response = await FMSAxios.get<IDicomServerModel[]>(`/dicomServer?ClinicId=${clinic.id}`);
      commit('dicomServer/setServers', response.data);
    },
    async postDicomAutoSend (context): Promise<void> {
      const autoSendServer = context.state.servers.find(server => !!server.autoSend);
      if (!autoSendServer) return;

      const images: IImageModel[] = this.get('study/images');
      const request: IDicomSendRequest = {
        dicomServerId: autoSendServer.id as number,
        imageIdList: images.map(image => image.id as number)
      };

      commit('dicomServer/setRequestsInProgress', context.state.requestsInProgress + 1);
      await FMSAxios.post('dicomServer/send/auto', request);
      commit('dicomServer/setRequestsInProgress', context.state.requestsInProgress - 1);
    },
    async postDicomSend (context, payload: IDicomSendRequest): Promise<void> {
      await FMSAxios.post('dicomServer/send', payload);
    },
    test (context, server: IDicomServerModel): Promise<void> {
      return FMSAxios.post('dicomServer/send/test', server);
    },
    async delete (context, serverId: number): Promise<void> {
      await FMSAxios.delete(`dicomServer/${serverId}`);
      return dispatch('dicomServer/fetchAll');
    },
    async update (context, newConfig: IDicomServerModel): Promise<void> {
      if (newConfig.id) {
        const currentConfig = context.state.servers.find(server => server.host === newConfig.host);
        const mixedConfig = { ...currentConfig, ...newConfig };
        await FMSAxios.put('/dicomServer', mixedConfig);
      } else {
        await FMSAxios.post('/dicomServer', newConfig);
      }
      return dispatch('dicomServer/fetchAll');
    }
  },
  namespaced: true
};

export default dicomServer;
