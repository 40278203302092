




import Vue from 'vue';
import { get } from 'vuex-pathify';
import { IImageModel } from '@/api/Models';

export default Vue.extend({
  computed: {
    images: get<IImageModel[]>('study/images')
  },
  methods: {
    onSelectAll (): void {
      this.$emit('input', this.images.map(image => image.id));
    }
  }
});
