export enum ConnectionStatus {
  CONNECTING,
  OPEN,
  CLOSED
}

export enum SwalTitles {
  CaptureInProgress = 'Capture In Progress',
  CancelXRay = 'Cancel X-Ray',
  EditComment = 'Edit comment',
  EditMarker = 'Edit marker',
  EnterComment = 'Enter comment',
  Error = 'Error',
  ExitApp = 'Are you sure you want to exit?',
  GeneratorConnectionError = 'Generator Connection Error',
  LogOut = 'Log Out?',
  ResetCrop = 'Reset Crop?',
  SensorConnectionError = 'Sensor Connection Error',
  XRayTimeOut = 'X-Ray has timed out',
  CreateReport = 'Create a report page',
  CreateXLogReport = 'Create a X-ray log report',
  BulkDownload = 'Do you want to download it?',
  RequestsDetectedOnExit = 'DICOM Send requests in progress detected. Are you sure you want to exit?'
}
