/* eslint-disable no-alert */
import Vue from 'vue';
import VTooltip from 'v-tooltip';
import vSelect from 'vue-select';
import Toasted from 'vue-toasted';
import VueCookies from 'vue-cookies';
import VueSweetalert2 from 'vue-sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import App from './App.vue';
import router from './router';
import store from './state';
import authSrc from '@/directives/authSrc';
import { formatDateShort, formatDateTime, formatDateVerbose } from '@/helpers/date';
import vueAuth from '@/plugins/vueAuth/vue-auth';
import createImageDescription from '@/helpers/createImageDescription';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import 'bootstrap/js/dist/tab.js';

import 'sweetalert2/dist/sweetalert2.min.css';
import { fireError } from './helpers/sweetAlert';
import AsyncButton from '@/components/AsyncButton.vue';

Vue.use(VueSweetalert2, {
  allowOutsideClick: false,
  allowEscapeKey: false,
  target: '#app'
});

Vue.use(VTooltip);
Vue.use(VueCookies);

Vue.use(Toasted, {
  position: 'bottom-right',
  type: 'info',
  duration: 3000
});
vueAuth();

Vue.component('AsyncButton', AsyncButton);
Vue.component('v-select', vSelect);
Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.config.productionTip = false;

Vue.directive('authSrc', authSrc);

Vue.filter('dateTimeShort', formatDateShort);
Vue.filter('dateTime', formatDateTime);
Vue.filter('dateVerbose', formatDateVerbose);
Vue.filter('toImageDescription', createImageDescription);

store.dispatch('auth/setUserIdFromToken').then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
});

Vue.config.errorHandler = err => {
  fireError(err.message);
};

window.onunhandledrejection = (event: PromiseRejectionEvent) => {
  if (event.reason.response?.data?.message) {
    fireError(event.reason.response?.data?.message);
    return;
  }
  fireError(event.reason);
};

window.onerror = message => {
  console.warn('Error thrown outside of Vue: ', message);
  fireError(message.toString());
};
