import { Module } from 'vuex';
import { commit, dispatch, make } from 'vuex-pathify';
import { once } from 'lodash';
import { IUserModel, IPermissionProfileModel } from '@/api/Models';
import vuexStore, { IRootState } from '../index';
import FMSAxios from '@/api/FMSAxios';

export interface IPermissionProfileState {
  profile?: IPermissionProfileModel;
}

const _state: IPermissionProfileState = {
  profile: undefined
};

const permissionProfile: Module<IPermissionProfileState, IRootState> = {
  state: _state,
  mutations: make.mutations(_state),
  getters: {
    getIsRoot (state): boolean {
      return !!state.profile?.root;
    }
  },
  actions: {
    initialize: once(async () => {
      const user: IUserModel = vuexStore.get('user/user');
      const profile: IPermissionProfileModel = await dispatch('permissionProfile/fetchProfile', user.permissionProfileId);
      commit('permissionProfile/setProfile', profile);
    }),
    async fetchProfile (context, profileId: number): Promise<IPermissionProfileModel> {
      const response = await FMSAxios.get<IPermissionProfileModel>(`permissionProfile/${profileId}`);
      return response.data;
    },
    async putProfile (contex, profile: IPermissionProfileModel) {
      await FMSAxios.put('permissionProfile', profile);
    }
  },
  namespaced: true
};

export default permissionProfile;
