import { Module, Store } from 'vuex';
import { commit, dispatch } from 'vuex-pathify';
import { once } from 'lodash';

import tools, { IToolsState } from './tools';
import metadata, { IMetadataState } from './metadata';
import { IRootState } from '../index';
import images, { IImagesState } from './images';
import { IStudieModel } from '@/api/Models';
import FMSAxios from '@/api/FMSAxios';

export interface ICaseViewerState {
  metadata: IMetadataState;
  tools: IToolsState;
  images: IImagesState;
}

const caseViewer: Module<ICaseViewerState, IRootState> = {
  modules: {
    metadata,
    tools,
    images
  },
  actions: {
    initialize: once(async (context, store: Store<IRootState>) => {
      await dispatch('viewer/metadata/initialize', store);
      dispatch('viewer/tools/initialize', store);
      await dispatch('viewer/images/initialize', store);
    }),
    flush (): void {
      commit('viewer/metadata/flush');
      commit('viewer/tools/flush');
      commit('viewer/images/flush');
    },
    async fetchState (): Promise<void> {
      const selectedStudy: IStudieModel = this.get('study/selectedStudy');

      const response = await FMSAxios.get(`viewerState/case/${selectedStudy.id}/state`);
      const { viewportState, cropState, toolState } = response.data;
      commit('viewer/images/setViewports', viewportState || {});
      commit('viewer/tools/setCrops', cropState || {});
      await dispatch('viewer/tools/restoreToolState', toolState);
    },
    async putState (): Promise<void> {
      //Prevent sending putstate for case!
    }
  },
  namespaced: true
};

export default caseViewer;
