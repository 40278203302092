









import Vue from 'vue';
import RadioButtons from '@/components/form/RadioButtons.vue';
import { dispatch, get } from 'vuex-pathify';
import { FocalSizes } from '@/api/Models';
import { GeneratorStatusKeys } from '@/state/generator/helpers';

export default Vue.extend({
  components: {
    RadioButtons
  },
  computed: {
    selectedFocal: get<number>(`generator/status@${GeneratorStatusKeys.FO}`),
    selectedSizeInitial (): string | undefined {
      if (this.selectedFocal === undefined) return undefined;

      return Object.values(FocalSizes)[this.selectedFocal][0].toUpperCase();
    },
    sizeInitials (): string[] {
      const sizes = Object.values(FocalSizes);
      const firstLetters = sizes.map(size => size[0].toUpperCase());
      return firstLetters;
    }
  },
  methods: {
    async onInput (sizeInitial: string): Promise<void> {
      const sizes = Object.values(FocalSizes);
      const size = sizes.find(s => s.startsWith(sizeInitial));

      await dispatch('generator/onFoChange', size);
    }
  }
});
