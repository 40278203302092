




































import Vue from 'vue';
import { get } from 'vuex-pathify';
import HighlightMap from '@/components/x-ray/map/HighlightMap.vue';
import AnnotationList from '@/components/x-ray/AnnotationList.vue';
import XRayPageTemplate from '@/components/x-ray/X-Ray-Page-Template.vue';
import StartXRayButton from '@/components/x-ray/StartXRayButton.vue';
import ImagePreview from '@/components/x-ray/images/ImagePreview.vue';
import Thumbnails from '@/components/x-ray/images/Thumbnails.vue';
import FinishButton from '@/components/x-ray/FinishButton.vue';
import DeviceStatusButton from '@/components/x-ray/DeviceStatusButton.vue';
import StudyInfo from '@/components/x-ray/StudyInfo.vue';
import SelectAllButton from './components/SelectAllButton.vue';
import { IAnnotation, IImageModel, Species } from '@/api/Models';
import TopBarContainer from '@/components/x-ray/TopBarContainer.vue';

export default Vue.extend({
  components: {
    TopBarContainer,
    HighlightMap,
    AnnotationList,
    XRayPageTemplate,
    StartXRayButton,
    ImagePreview,
    DeviceStatusButton,
    Thumbnails,
    FinishButton,
    StudyInfo,
    SelectAllButton
  },
  computed: {
    teeth: get('teeth/allTeeth'),
    annotations: get<IAnnotation[]>('xRay/annotations'),
    patientSpecies: get<Species>('patient/patient@speciesId'),
    selectedPart: get('xRay/selectedPart'),
    study: get('study/selectedStudy'),
    images: get<IImageModel[]>('study/currentModalityImages'),
    allSelectedParts (): string[] {
      return this.annotations.map(annotation => annotation.bodyPart as string);
    },
    showMap (): boolean {
      return !!this.mapName;
    },
    mapName (): string | undefined {
      switch (this.patientSpecies) {
        case Species.Feline:
          return 'CatTeethMap';
        case Species.Canine:
          return 'DogTeethMap';
        case Species.Equine:
          return 'HorseTeethMap';
        default:
          return undefined;
      }
    }
  },
  methods: {
    async onPartSelected (part: string): Promise<void> {
      this.$store.commit('xRay/setSelectedPart', part);
      await this.$store.dispatch('xRay/addAnnotation');
    },
    formatAnnotation (annotation: IAnnotation): string {
      const formattedAnnotation = annotation.bodyPart;
      if (!formattedAnnotation) {
        throw new Error('Attempting to format a non-existent tooth');
      }
      return formattedAnnotation;
    },
    // compare function for https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
    sortAnnotations (a: IAnnotation, b: IAnnotation): number {
      const formattedA = this.formatAnnotation(a);
      const formattedB = this.formatAnnotation(b);
      if (formattedA === formattedB) {
        throw new Error('Duplicate annotations found');
      }
      const leadingNumberA = parseInt(formattedA[0]);
      const leadingNumberB = parseInt(formattedB[0]);
      // lower index = higher ranking. leading characters that don't match any of the expected numbers will always be first
      const numberRankings = [1, 4, 2, 3];
      const rankingA = numberRankings.indexOf(leadingNumberA);
      const rankingB = numberRankings.indexOf(leadingNumberB);

      if (rankingA === rankingB) {
        return 0;
      }
      return rankingA < rankingB ? -1 : 1;
    }
  }
});
