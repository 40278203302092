






import Vue from 'vue';
import cornerstone from 'cornerstone-core';
import { IImageModel } from '@/api/Models';
import { createCornerstoneImageId } from '@/state/viewer/general-helpers';

export default Vue.extend({
  props: {
    image: {
      type: Object as () => IImageModel,
      required: true
    }
  },
  async mounted () {
    const canvas = this.$refs.canvas as HTMLElement;

    const cornerstoneImageId = createCornerstoneImageId(this.image.id as number);
    const image = await cornerstone.loadAndCacheImage(cornerstoneImageId);
    cornerstone.renderToCanvas(canvas, image); // using renderToCanvas allows us to render the image without enabling it
  }
});
