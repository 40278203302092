















































import Vue from 'vue';
import { commit, get } from 'vuex-pathify';
import { VueAutosuggest } from 'vue-autosuggest';
import { IVueAutoSuggestResult } from '@/plugins/vueAutoSuggest/interfaces';
import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { IPetOwnerModel } from '@/api/Models';
import eventBus from '@/eventBus';
import addOwnerModal from './AddOwnerModal.vue';

interface ISuggestions {
  data: IPetOwnerModel[];
}

export default Vue.extend({
  components: {
    VueAutosuggest
  },
  data () {
    return {
      input: '',
      addIcon: faPlus,
      editIcon: faPen
    };
  },
  computed: {
    owner: get<IPetOwnerModel | undefined>('owner/owner'),
    owners: get<IPetOwnerModel[]>('owner/owners'),
    suggestions (): ISuggestions[] {
      const filteredNames = this.owners.filter(owner => {
        const formattedInput = this.input.replace(/\s+/g, ' ').trim().toLowerCase();

        return owner.name.toLowerCase().includes(formattedInput);
      });

      return [{ data: filteredNames }];
    }
  },
  watch: {
    owner (newOwner: IPetOwnerModel | undefined, oldOwner: IPetOwnerModel | undefined) {
      if (newOwner?.id !== oldOwner?.id) {
        this.input = newOwner ? newOwner.name : '';
      }
    }
  },
  mounted () {
    this.input = this.owner ? this.owner.name : '';
  },
  methods: {
    onClick (buttonType: string) {
      eventBus.launchModal(addOwnerModal, { buttonType });
    },
    onSelected (selection: IVueAutoSuggestResult<IPetOwnerModel>) {
      const selectedOwner = this.owners.find((owner: IPetOwnerModel) => owner.id === selection?.item.id);
      commit('owner/setOwner', selectedOwner);
    },
    getSuggestionValue (suggestion: IVueAutoSuggestResult<IPetOwnerModel>) {
      return suggestion.item.name;
    }
  }
});
