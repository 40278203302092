




































import Vue from 'vue';
import { get } from 'vuex-pathify';
import dayjs from 'dayjs';
import { IImageModel } from '@/api/Models';
import { getImageIndex } from '@/state/viewer/general-helpers';
import images from '../../../../state/viewer/images/index';

export default Vue.extend({
  props: {
    image: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    getMetadata: get('viewer/metadata/imageMetadata'),
    images: get<IImageModel[]>('study/images'),
    getIsPixelSpacingFound: get('viewer/metadata/isPixelSpacingFound'),
    index (): number {
      return getImageIndex(this.images, this.image);
    },
    metadata (): any {
      return this.getMetadata(this.image?.imageId);
    },
    isPixelSpacingFound (): boolean {
      return this.getIsPixelSpacingFound(this.image?.imageId) as boolean;
    },
    studyTime (): string {
      const studyTime = this.metadata?.StudyTime;
      return studyTime ? dayjs(studyTime, 'HHmmss').format('HH:mm:ss') : '';
    }
  }
});
