






import Vue from 'vue';
import { dispatch } from 'vuex-pathify';
import { SwalTitles } from '@/state/enums';

export default Vue.extend({
  props: {
    onClickCallback: {
      type: Function,
      default: () => undefined
    }
  },
  methods: {
    async onClick (): Promise<void> {
      const { value: imagesCount, isConfirmed } = await this.$swal.fire({
        title: SwalTitles.CreateReport,
        input: 'radio',
        inputOptions: {
          0: 'Text Only',
          1: '1 Images',
          2: '2 Images',
          4: '4 Images'
        },
        inputValue: '0',
        showCancelButton: true
      });

      if (isConfirmed) {
        await dispatch('report/createPage', parseInt(imagesCount));
        await this.onClickCallback();
      }
    }
  }
});
