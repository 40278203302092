




















import Vue from 'vue';
import { faCopyright } from '@fortawesome/free-solid-svg-icons';

export default Vue.extend({
  props: {
    showSidebar: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      copyright: faCopyright
    };
  }
});
