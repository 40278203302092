























































import Vue from 'vue';
import { IClinicModel, IEmailConfigModel, IEmailSendRequest, IImageModel } from '@/api/Models';
import { get, dispatch } from 'vuex-pathify';
import isValidEmail from '@/helpers/validEmail';
// import getEmailTemplate from '@/helpers/emailTemplate';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import ImagesModalFrame from '@/components/modal/ImagesModalFrame.vue';
import ColumnInputWrapper from '@/components/form/ColumnInputWrapper.vue';
import eventBus, { ModalEventNames } from '@/eventBus';
import getEmailBody from '@/helpers/emailTemplate';

export default Vue.extend({
  el: '#txtarea',
  components: { ImagesModalFrame, ColumnInputWrapper },
  data () {
    return {
      recipient: undefined as undefined | string,
      emailSubject: 'Images',
      selectedImageIds: [] as number[],
      errorMessage: '',
      faUserFriends,
      EmailMsg: '',
      appendDownoadLinkCheckbox:false
    };
  },
  mounted () {
    this.emailBody();
  },
  computed: {
    emailConfig: get<IEmailConfigModel | undefined>('email/config'),
    isEmailConfigValid: get<boolean>('email/isConfigValid'),
    images: get<IImageModel[]>('study/images'),
    patientName: get('patient/patient@name'),
    studyDate: get('study/selectedStudy@createdOn'),
    clinic: get<IClinicModel | undefined>('clinic/clinic'),
    isDisabled (): boolean {
      return false;
    },
    isRecipientValid (): boolean {
      return isValidEmail(this.recipient);
    }
  },
  directives: {
    content (el, { value }) {
      if (el.innerHTML !== value) {
        el.innerHTML = value;
      }
    }
  },
  methods: {
    emailBody () {
      this.EmailMsg = getEmailBody(this.patientName, this.studyDate, this.clinic);
    },
    typing: function (ev: any) {
      this.EmailMsg = ev.target.innerHTML;
    },
    async onSend (): Promise<void> {
      this.errorMessage = '';
      if (!this.isRecipientValid) {
        this.errorMessage = `Invalid recipient: ${this.recipient}`;
      }
      if (this.errorMessage) return;
      try {
        const appendDownloadLinkCheckbox = document.getElementById('appendDownoadLinkCheckbox') as HTMLInputElement;
        const appendDownloadLinkChecked = appendDownloadLinkCheckbox.checked;

        const emailRequest: IEmailSendRequest = {
          emailConfigId: this.emailConfig?.id as number,
          imageIdList: this.selectedImageIds,
          emailReceiver: [this.recipient as string],
          emailSubject: this.emailSubject,
          emailBody: this.EmailMsg,
          appendDownoadLinkCheckbox: appendDownloadLinkChecked
        };
        if (appendDownloadLinkChecked && this.selectedImageIds.length == 0) {
          //var messageElement = document.getElementsByClassName('alert-danger');
          var messageElement = document.getElementById("appendDICOMlinkMessage")
          if (messageElement) {
            messageElement.setAttribute("style","display:block");
            messageElement.innerHTML = "Please select images before sending email!";
          }
          
        } else {
          await dispatch('email/postEmail', emailRequest);
          eventBus.$emit(ModalEventNames.closeModal);
          this.$toasted.success('Email Sent');
        }
        
      } catch (e) {
        this.errorMessage = e.message;
      }
    }
  }
});
