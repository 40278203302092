






import Vue from 'vue';
import { dispatch } from 'vuex-pathify';
import { IUserModel } from '@/api/Models';

export default Vue.extend({
  props: {
    caseId: {
      type: Number,
      required: true
    },
    onClickCallback: {
      type: Function,
      default: () => undefined
    }
  },
  methods: {
    async onClick (): Promise<void> {
      const radiologistUsers = await this.$store.dispatch('user/fetchAllRadiologistUser');
      let selectedRadiologistId: number | null = null;

      const { isConfirmed } = await this.$swal.fire({
        title: 'Assign Case',
        html: this.generateRadioHtml(radiologistUsers),
        showCancelButton: true,
        confirmButtonText: 'Submit',
        preConfirm: () => {
          const radio = document.querySelector('input[type="radio"]:checked') as HTMLInputElement;
          if (radio) {
            selectedRadiologistId = parseInt(radio.value);
          }
          return selectedRadiologistId;
        }
      });

      if (isConfirmed) {
        const formData = new FormData();
        formData.append('caseId', JSON.stringify(this.caseId));
        formData.append('ragiologistId', JSON.stringify(selectedRadiologistId));
        await dispatch('radcase/takeCase', formData);
        await this.onClickCallback();
      }
    },
    generateRadioHtml (radiologistUsers: IUserModel[]): string {
      const options = radiologistUsers.map(user => ({
        value: user.id?.toString() ?? 'Unknown',
        caption: user.firstName
      }));
      return `<div class="radio-container">${options.map(option => `
      <label><input type="radio" name="radiologist" value="${option.value}"> ${option.caption}</label><br>
    `).join('')}</div>`;
    }
  }
});
