




























































import Vue from 'vue';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import eventBus, { ModalEventNames } from '@/eventBus';
import SelectAllButton from './SelectAllButton.vue';
import ImageSelection from './ImageSelection.vue';
import ModalFrame from './ModalFrame.vue';

export default Vue.extend({
  components: {
    ImageSelection,
    SelectAllButton,
    ModalFrame
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    heading: {
      type: String,
      required: true
    },
    errorMessage: {
      type: String,
      default: ''
    },
    onSelection: {
      type: Function,
      default: () => undefined
    },
    isConfirmDisabled: {
      type: Boolean,
      default: false
    },
    onConfirm: {
      type: Function,
      required: true
    },
     resetHandler: {
      type: Function,
      required: true
    },
    confirmationButtonText: {
      type: String,
      default: 'Submit'
    },
    filePreviews: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      faTimes,
      localFilePreviews: [] as { name: string; src: string | ArrayBuffer | null; file: Blob }[],
      allowMulti: true
    };
  },
  watch: {
    filePreviews: {
      immediate: true,
      handler (newVal) {
        this.localFilePreviews = newVal;
      }
    }
  },
  methods: {
    onCloseModalClick (): void {
      eventBus.$emit(ModalEventNames.closeModal);
    },
    onInput (imageIds: number[]) {
      this.$emit('input', imageIds);
    },

    handleFileChange (event: Event) {
      const input = event.target as HTMLInputElement;
      const files = input.files;
      if (!files) return;
      this.localFilePreviews = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target) {
            const arrayBuffer = e.target.result as ArrayBuffer;
            const base64String = this.arrayBufferToBase64(arrayBuffer);

            this.localFilePreviews.push({ name: file.name, src: `data:${file.type};base64,${base64String}`, file });
            this.$emit('update:filePreviews', this.localFilePreviews);
          }
        };
        reader.readAsArrayBuffer(file);
      }
    },
    deleteFilePreview (index: number): void {
      this.localFilePreviews.splice(index, 1);
      this.$emit('update:filePreviews', this.localFilePreviews);
    },
    arrayBufferToBase64 (buffer: ArrayBuffer): string {
      let binary = '';
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    }
  }
});
