import { IConfigScales, IGeneratorStatus } from '.';

export enum GeneratorStatusKeys {
  MX = 'mx', MS = 'ms', MA = 'ma', KV = 'kv', EX = 'ex', PW = 'pw', FO = 'fo', WS = 'ws'
}

export function validateStatus (configScales: IConfigScales, key: string, value: number): boolean {
  // you know the key is part of GeneratorStatusKeys if it passed validateStatusKey
  if (!validateStatusKey(key) || !validateStatusValue(configScales, key as GeneratorStatusKeys, value)) {
    throw new Error('Configuration validation failed');
  }

  return true;
}

export function validateStatusKey (key: string): boolean {
  const keys = Object.values(GeneratorStatusKeys) as string[];
  return keys.includes(key);
}

export function validateStatusValue (configScales: IConfigScales, key: GeneratorStatusKeys, value: number): boolean {
  const scale = configScales[key];

  if (Array.isArray(scale)) {
    return scale.indexOf(value) !== -1;
  }

  return false;
}

export function incrementStatus (configScales: IConfigScales, config: GeneratorStatusKeys, currentValue: number): number {
  validateStatus(configScales, config, currentValue);

  const scale = configScales[config];

  if (Array.isArray(scale)) {
    const currentIndex = scale.indexOf(currentValue);
    return scale[currentIndex + 1] || scale[currentIndex];
  }

  return currentValue;
}

export function decrementStatus (configScales: IConfigScales, config: GeneratorStatusKeys, currentValue: number): number {
  validateStatus(configScales, config, currentValue);

  const scale = configScales[config];

  if (Array.isArray(scale)) {
    const currentIndex = scale.indexOf(currentValue);
    return scale[currentIndex - 1] || scale[currentIndex];
  }

  return currentValue;
}

export function calculateMx (configScales: IConfigScales, ma: number, ms: number): number {
  const calculatedValue = (ma * ms) / 1000;

  const closestValueInScales = configScales.mx.reduce((previousValue, currentValue) => {
    return Math.abs(currentValue - calculatedValue) < Math.abs(previousValue - calculatedValue) ? currentValue : previousValue;
  });

  return closestValueInScales;
}

export function calculateMaAndMs (configScales: IConfigScales, mx: number, currentMa: number, currentMs: number): Partial<IGeneratorStatus> {
  let newMa = currentMa;
  let newMs = currentMs;

  let isValidConfig = mx === (currentMa * currentMs) / 1000;
  let isIncrement = true;

  while (!isValidConfig) {
    let adjustedMa;

    if (isIncrement) {
      adjustedMa = incrementStatus(configScales, GeneratorStatusKeys.MA, newMa);
    } else {
      adjustedMa = decrementStatus(configScales, GeneratorStatusKeys.MA, newMa);
    }

    if (newMa === adjustedMa) {
      newMa = currentMa;
      isIncrement = !isIncrement;
      continue;
    }

    const adjustedMs = (mx * 1000) / adjustedMa;
    const isAdjustedMsValid = configScales[GeneratorStatusKeys.MS].some(value => value === adjustedMs);

    if (isAdjustedMsValid) {
      newMa = adjustedMa;
      newMs = adjustedMs;
      break;
    }

    newMa = adjustedMa;
    isValidConfig = false;
  }

  return { ma: newMa, ms: newMs };
}
