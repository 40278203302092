import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getConfig } from '@/helpers/config';
import * as Sentry from '@sentry/vue';
import Vue from 'vue';
import { dispatch } from 'vuex-pathify';

const FMSAxios = axios.create({
  baseURL: getConfig('API_SERVER')
});

FMSAxios.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
  const token: string|undefined = Vue.auth.token();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  config.headers['Cache-Control'] = 'no-store';
  config.headers.Pragma = 'no-cache';

  return config;
});

FMSAxios.interceptors.response.use((response: AxiosResponse): AxiosResponse => {
  const serverVersion = response.headers['x-server-version'];
  const serverName = response.headers['x-server-name'];
  const serverEnv = response.headers['x-server-env'];

  if (serverVersion) Sentry.setTag('server.version', serverVersion);
  if (serverName) Sentry.setTag('server.name', serverName);
  if (serverEnv) Sentry.setTag('environment', serverEnv);

  return response;
}, async function (error) {
  if (error?.response?.status === 401) {
    console.warn(`${error.response.url} returned 401. Logging user out.`);
    await dispatch('auth/logout');
  } else {
    throw error;
  }
});

export default FMSAxios;
