import { commit } from 'vuex-pathify';

export function openEventSource (url: string, sensorSourceMutation: string, readyStateMutation: string, onMessage: (this: EventSource, ev: MessageEvent) => any, onErrorCallback?: Function): void {
  console.log('opening SSE connection to ' + url);

  let errorReported = false;

  const eventSource = new EventSource(url);
  commit(sensorSourceMutation, eventSource);
  eventSource.onmessage = onMessage;

  function setReadyState (): void {
    commit(readyStateMutation, eventSource.readyState || EventSource.CLOSED);
  }

  function onError (e: Event): void {
    if (!errorReported) {
      console.error(`An error while listening to event stream ${url}`, e);
    } else {
      console.info(`An error while listening to event stream ${url}`, e);
    }
    errorReported = true;

    if (onErrorCallback) onErrorCallback();

    setReadyState();
  }

  eventSource.addEventListener('error', onError);
  eventSource.onerror = onError;
  eventSource.onopen = setReadyState;
  setReadyState();
}
