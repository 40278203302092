/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  importInternal,
  external,
  removeToolState,
  getToolState,
  EVENTS,
  Measurement
} from 'cornerstone-tools';

import moveHandle from './moveHandle/moveHandle';

const anyHandlesOutsideImage = importInternal(
  'manipulators/anyHandlesOutsideImage'
);
const getHandleNearImagePoint = importInternal(
  'manipulators/getHandleNearImagePoint'
);
const moveAllHandles = importInternal('manipulators/moveAllHandles');

export default function handleSelectedMouseCallbackPerpendicular (this: any, evt: any): boolean | undefined {
  const eventData = evt.detail;

  const { element } = eventData;
  let data: Measurement;

  const distanceThreshold = 6;

  const handleDoneMove = (handle?: any): void => {
    data.invalidated = true;
    if (anyHandlesOutsideImage(eventData, data.handles)) {
      // Delete the measurement
      removeToolState(element, this.name, data);
    }

    // Update the handles to keep selected state
    if (handle) {
      handle.moving = false;
      handle.selected = true;
    }

    // setToolCursor(this.element, this.svgCursor);

    external.cornerstone.updateImage(element);
    element.addEventListener(
      EVENTS.MOUSE_MOVE,
      this.mouseMoveCallbackPerpendicular
    );
    element.addEventListener(
      EVENTS.TOUCH_START,
      this.mouseMoveCallbackPerpendicular
    );
  };

  const coords = eventData.startPoints.canvas;
  const toolData = getToolState(evt.currentTarget, this.name);

  if (!toolData) {
    return;
  }

  // Handles movement of handles part of bi-directional tool
  for (let i = 0; i < toolData.data.length; i++) {
    data = toolData.data[i];
    const handleParams = [element, data.handles, coords, distanceThreshold];
    let handle = getHandleNearImagePoint(...handleParams);
    if (handle) {
      element.removeEventListener(
        EVENTS.MOUSE_MOVE,
        this.mouseMoveCallbackPerpendicular
      );
      element.removeEventListener(
        EVENTS.TOUCH_START,
        this.mouseMoveCallbackPerpendicular
      );

      data.active = true;

      unselectAllHandles(data.handles);
      handle.moving = true;

      // Invert handles if needed
      handle = invertHandles(eventData, data, handle);

      moveHandle(eventData, this.name, data, handle, () => handleDoneMove(handle)
      );

      preventPropagation(evt);
      return true;
    }
  }
  // Handle movement of all other handles
  for (let i = 0; i < toolData.data.length; i++) {
    data = toolData.data[i];
    if (this.pointNearTool(element, data, coords, 'mouse')) {
      element.removeEventListener(
        EVENTS.MOUSE_MOVE,
        this.mouseMoveCallbackPerpendicular
      );
      element.removeEventListener(
        EVENTS.TOUCH_START,
        this.mouseMoveCallbackPerpendicular
      );
      data.active = true;

      unselectAllHandles(data.handles);
      setHandlesMovingState(data.handles, true);

      const doneMovingCallback = getDoneMovingCallback(data.handles);

      moveAllHandles(
        eventData,
        this.name,
        data,
        null,
        {
          deleteIfHandleOutsideImage: true,
          preventHandleOutsideImage: false
        },
        'mouse',
        doneMovingCallback
      );

      preventPropagation(evt);
      console.log('Second Callback');
      return true;
    }
  }

  function getDoneMovingCallback (handles: any): any {
    return () => {
      setHandlesMovingState(handles, false);
      handleDoneMove();
    };
  }
}

function isHandle (handle: any): boolean {
  return handle.x && handle.y;
}

// Clear the selected state for the given handles object
function unselectAllHandles (handles: any): boolean {
  let imageNeedsUpdate = false;
  Object.keys(handles).forEach(handleKey => {
    if (handleKey === 'textBox' || !isHandle(handles[handleKey])) {
      return;
    }
    handles[handleKey].selected = false;
    imageNeedsUpdate = handles[handleKey].active || imageNeedsUpdate;
    handles[handleKey].active = false;
  });

  return imageNeedsUpdate;
}

function setHandlesMovingState (handles: any, state: any): any {
  Object.keys(handles).forEach(handleKey => {
    if (handleKey === 'textBox' || !isHandle(handles[handleKey])) {
      return;
    }
    handles[handleKey].moving = state;
  });
}

function preventPropagation (evt: any): void {
  evt.stopImmediatePropagation();
  evt.stopPropagation();
  evt.preventDefault();
}

function swapAttribute (a: any, b: any, attribute: any): void {
  const originalA = a[attribute];
  const originalB = b[attribute];

  a[attribute] = originalB;
  b[attribute] = originalA;
}

function swapHandles (a: any, b: any): void {
  swapAttribute(a, b, 'x');
  swapAttribute(a, b, 'y');
  swapAttribute(a, b, 'moving');
  swapAttribute(a, b, 'hover');
  swapAttribute(a, b, 'active');
  swapAttribute(a, b, 'selected');
}

function invertHandles (eventData: any, measurementData: any, handle: any): any {
  const { rowPixelSpacing, columnPixelSpacing } = eventData.image;
  const { handles } = measurementData;
  const { start, end, perpendicularStart, perpendicularEnd } = handles;

  // Calculate the long axis length
  const dx = (start.x - end.x) * (columnPixelSpacing || 1);
  const dy = (start.y - end.y) * (rowPixelSpacing || 1);
  const length = Math.sqrt(dx * dx + dy * dy);

  // Calculate the short axis length
  const wx =
    (perpendicularStart.x - perpendicularEnd.x) * (columnPixelSpacing || 1);
  const wy =
    (perpendicularStart.y - perpendicularEnd.y) * (rowPixelSpacing || 1);
  const width = Math.sqrt(wx * wx + wy * wy) || 0;

  if (width > length) {
    swapHandles(start, end);
    swapHandles(start, perpendicularStart);
    swapHandles(end, perpendicularEnd);

    return Object.values(handles).find((h: any) => h.moving === true);
  }

  return handle;
}
