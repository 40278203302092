






import mixins from 'vue-typed-mixins';
import { IImageModel } from '@/api/Models';
import { JpegImageLoader } from '../mixins/jpeg-image-loader';

export default mixins(JpegImageLoader).extend({
  props: {
    image: {
      type: Object as () => IImageModel,
      required: true
    }
  },
  async created () {
    this.imageSrc = await this.getImageJpegData(this.image.id as number);
  }
});
