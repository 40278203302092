









import Vue from 'vue';
import { commit, get } from 'vuex-pathify';
import { AnimalSizes } from '@/api/Models';
import RadioButtons from '@/components/form/RadioButtons.vue';

export default Vue.extend({
  components: {
    RadioButtons
  },
  computed: {
    selectedSize: get<AnimalSizes>('xRay/selectedSize'),
    selectedSizeInitial (): string | undefined {
      if (!this.selectedSize) return undefined;

      return this.selectedSize[0].toUpperCase();
    },
    sizes (): string[] {
      const sizes = Object.values(AnimalSizes);
      const firstLetters = sizes.map(size => size[0].toUpperCase());
      return firstLetters;
    }
  },
  methods: {
    onInput (sizeInitial: string): void {
      const sizes = Object.values(AnimalSizes);
      const size = sizes.find(s => s.startsWith(sizeInitial));

      commit('xRay/setSelectedSize', size);
    }
  }
});
