




















import Vue from 'vue';
import { get } from 'vuex-pathify';
import { Prop } from 'vue/types/options';

import { isAppLocal } from '@/helpers/config';
import { ClinicType } from '@/api/Models';
import { IDropdownToolGroup } from './toolLists';
import Tool from './Tool.vue';

export default Vue.extend({
  components: {
    Tool
  },
  props: {
    toolGroup: {
      type: Object as Prop<IDropdownToolGroup>,
      required: true
    }
  },
  data () {
    return {
      isOpen: false
    };
  },
  computed: {
    clinicType: get<ClinicType>('clinic/clinic@clinicType'),
    iconSize (): string {
      return window.innerWidth > 1800 || isAppLocal() ? 'lg' : 'sm';
    },
    showGroup (): boolean {
      return this.toolGroup.tools.some(tool => !tool.hidden && (tool.clinicType === undefined || tool.clinicType === this.clinicType));
    }
  },
  methods: {
    onClick () {
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        document.addEventListener('click', () => {
          this.isOpen = false;
        }, {
          once: true
        });
      }
    },
    onGroupClick () {
      this.isOpen = false;
    }
  }
});
