import { Measurement, toolColors } from 'cornerstone-tools';

function getColor (data: Measurement): string {
  if (!data.active && data.static) {
    return 'rgb(255, 191, 0)';
  }
  return toolColors.getColorIfActive(data);
}

export {
  getColor
};
