


























































































import Vue from 'vue';
import { dispatch, get } from 'vuex-pathify';
import FormRow from '@/components/form/FormRow.vue';

import ValidatedForm from '@/components/form/ValidatedForm.vue';
import { IDicomServerModel } from '@/api/Models';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { isAppLocal } from '@/helpers/config';

export default Vue.extend({
  components: {
    FormRow,
    ValidatedForm
  },
  data () {
    return {
      selectedServer: undefined as undefined | IDicomServerModel,
      form: {
        serverName: '',
        aeTitle: '',
        host: '',
        port: undefined as number | undefined,
        description: '',
        autoSend: false,
        tls: false
      },
      errorMessage: '',
      loading: false,
      faPlus,
      faTrashAlt
    };
  },
  computed: {
    servers: get<IDicomServerModel[]>('dicomServer/servers'),
    clinicId: get<number>('clinic/clinic@id')
  },
  watch: {
    selectedServer (newState: IDicomServerModel, oldState: IDicomServerModel) {
      if (newState?.serverName !== oldState?.serverName) {
        this.setForm();
      }
    }
  },
  created () {
    this.selectedServer = this.servers[0];
    this.setForm();
  },
  methods: {
    isAppLocal,
    setForm (): void {
      if (!this.selectedServer) {
        return;
      }
      this.form = { ...this.selectedServer };
    },
    onAddClick (): void {
      this.form = {
        serverName: '',
        aeTitle: '',
        host: '',
        port: undefined,
        description: '',
        autoSend: false,
        tls: false
      };
      this.selectedServer = undefined;
    },
    async onDeleteClick (): Promise<void> {
      this.errorMessage = '';

      if (!this.selectedServer) {
        this.errorMessage = 'Missing a selected server';
        return;
      }
      const result = await this.$swal.fire({
        title: `Delete ${this.selectedServer?.serverName}?`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Delete'
      });

      if (!result.isConfirmed) {
        return;
      }
      try {
        await dispatch('dicomServer/delete', this.selectedServer.id);
        this.selectedServer = this.servers[0];
      } catch (error) {
        this.errorMessage = error?.response?.data?.message ? error?.response?.data?.message : error.message;
      }
    },
    async onTestClick (): Promise<void> {
      this.errorMessage = '';
      try {
        await dispatch('dicomServer/test', this.form);
        this.$toasted.success('Test sent successfully');
      } catch (error) {
        const errorMessage = error?.response?.data?.message ? error?.response?.data?.message : error.message;
        this.errorMessage = errorMessage;
      }
    },
    async onSaveClick (): Promise<void> {
      this.errorMessage = '';
      this.loading = true;

      try {
        const dicomServer: IDicomServerModel = {
          ...this.form,
          id: this.selectedServer?.id,
          clinicId: this.clinicId,
          port: this.form.port as number
        };

        await dispatch('dicomServer/update', dicomServer);
        this.selectedServer = this.servers.find(server => server.host === this.form.host);
      } catch (error) {
        this.errorMessage = error?.response?.data?.message ? error.response.data.message : error.message;
      } finally {
        this.loading = false;
      }
    }
  }
});
