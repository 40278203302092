







import Vue from 'vue';
import { dispatch } from 'vuex-pathify';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export default Vue.extend({
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => value === 'WorkList' || value === 'StudyList'
    }
  },
  data () {
    return {
      searchIcon: faSearch
    };
  },
  methods: {
    async onSearchClick (): Promise<void> {
      const promises = [
        this.type === 'WorkList' ? dispatch('study/fetchWorkList') : dispatch('study/fetchStudyList'),
        dispatch('patient/fetchAllPatients'),
        dispatch('owner/fetchAllOwners'),
        dispatch('user/fetchAllUsers')
      ];
      await Promise.all(promises);
    }
  }
});
