import Vue from 'vue';

export const BaseMap = Vue.extend({
  props: {
    clickHandler: {
      type: Function,
      required: true
    },
    mouseoverHandler: {
      type: Function,
      required: true
    },
    mouseoutHandler: {
      type: Function,
      required: true
    }
  }
});
