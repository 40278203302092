




































import Vue from 'vue';
import { dispatch } from 'vuex-pathify';
import { Prop } from 'vue/types/options';
import ModalFrame from '@/components/modal/ModalFrame.vue';
import ValidatedForm from '@/components/form/ValidatedForm.vue';
import FormRow from '@/components/form/FormRow.vue';

import { IPermissionProfileModel } from '@/api/Models';
import eventBus, { ModalEventNames } from '@/eventBus';

export default Vue.extend({
  components: {
    ValidatedForm,
    FormRow,
    ModalFrame
  },
  props: {
    profile: {
      type: Object as Prop<IPermissionProfileModel>,
      required: true
    }
  },
  data () {
    return {
      form: {
        report: !!this.profile?.report,
        advancedTools: !!this.profile.advancedTools,
        dicomForms: !!this.profile.dicomForms,
        bulkDownload: !!this.profile.bulkDownload,
        radiologistCase: !!this.profile.radiologistCase,
        doctorCase: !!this.profile.doctorCase
      },
      errorMessage: '',
      loading: false
    };
  },
  methods: {
    async onSaveClick (): Promise<void> {
      this.errorMessage = '';
      this.loading = true;

      try {
        await dispatch('permissionProfile/putProfile', { ...this.profile, ...this.form });
      } catch (error) {
        this.errorMessage = error?.response?.data?.message ? error.response.data.message : error.message;
      } finally {
        this.loading = false;
      }

      if (!this.errorMessage) {
        eventBus.$emit(ModalEventNames.closeModal);
      }
    }
  }
});
