/* eslint-disable @typescript-eslint/no-explicit-any */
import { dispatch } from 'vuex-pathify';

import { faEyeSlash, faCircle,
  faRulerCombined, faRuler,
  faTrashAlt, IconDefinition,
  faDog } from '@fortawesome/free-solid-svg-icons';

import { faSquare as farSquare } from '@fortawesome/free-regular-svg-icons';
import vuexStore from '@/state';
import { isAppLocal } from '@/helpers/config';
import { ClinicType } from '@/api/Models';
import { configureTextMarker, toggleMeasurementsVisibility, clearMeasurements } from '@/state/viewer/tools/helpers';
import { getGlobalToolState } from '@/state/viewer/general-helpers';
import { IDisplay } from '@/state/viewer/images/images-types';

export interface ITool {
  id: string;
  label: string;
  icon?: IconDefinition | string;
  textIcon?: string;
  clinicType?: ClinicType;
  isSingleImageOnly?: boolean;
  hidden?: boolean;
  action: () => void;
}

export interface IDropdownToolGroup {
  tools: ITool[];
  icon: IconDefinition;
}

function createCornerstoneTool (id: string, label: string, toolName: string, icon?: IconDefinition | string): ITool {
  return {
    id,
    label,
    icon,
    action: () => {
      dispatch('viewer/tools/resetEnabledTools');
      dispatch('viewer/tools/activateTool', { toolName, id });

      const isSingleImage = vuexStore.get('viewer/images/isSingleImage');
      if (toolName === 'Zoom' && isSingleImage) {
        dispatch('viewer/tools/enableTool', 'ScaleOverlay');
      }
    }
  };
}

export function getDropdownGroupedTools (dicomForms = true, advancedTools = true, isDoctorCase = false): IDropdownToolGroup[] {
  return [
   
    {
      tools: advancedTools ? [
        { ...createCornerstoneTool('vhs', 'VHS', 'VHS'), textIcon: 'VHS', isSingleImageOnly: true, clinicType: ClinicType.Veterinary, hidden: isAppLocal() },
        { ...createCornerstoneTool('tplo', 'TPLO', 'TPLO'), textIcon: 'TPLO', isSingleImageOnly: true, clinicType: ClinicType.Veterinary, hidden: isAppLocal() },
        { ...createCornerstoneTool('tta', 'TTA', 'TTA'), textIcon: 'TTA', isSingleImageOnly: true, clinicType: ClinicType.Veterinary, hidden: isAppLocal() }
      ] : [],
      icon: faDog
    }
  ];
}

export function getToolbarTools (): ITool[][] {
  return [
    [
      createCornerstoneTool('angle', 'Angle', 'Angle', faRulerCombined),
      { ...createCornerstoneTool('bidirectional', 'Bidirectional', 'Bidirectional'), icon: require('@/assets/viewer/bi-directional.svg') },
      createCornerstoneTool('length', 'Length', 'Length', faRuler),
      createCornerstoneTool('elliptical', 'Elliptical ROI', 'EllipticalRoi', faCircle),
      createCornerstoneTool('rectangle', 'Rectangle ROI', 'RectangleRoi', farSquare),
      { ...createCornerstoneTool('arrow-annotate', 'Arrow Annotate', 'ArrowAnnotate'), icon: require('@/assets/viewer/arrow-annotate.svg') },
      {
        id: 'text-marker',
        label: 'Text Only',
        action: () => {
          dispatch('viewer/tools/activateTool', { toolName: 'TextMarker', id: 'text-marker' });
          const element: HTMLElement = vuexStore.get('viewer/images/activeDisplay@enabledHtmlElement');
          configureTextMarker(element);
        },
        textIcon: 'TEXT'
      },
      {
        id: 'text-marker-l',
        label: 'Left Marker',
        action: () => {
          dispatch('viewer/tools/activateTool', { toolName: 'TextMarker', id: 'text-marker-l' });
          const element: HTMLElement = vuexStore.get('viewer/images/activeDisplay@enabledHtmlElement');
          configureTextMarker(element, 'L');
        },
        textIcon: 'L'
      },
      {
        id: 'text-marker-r',
        label: 'Right Marker',
        action: () => {
          dispatch('viewer/tools/activateTool', { toolName: 'TextMarker', id: 'text-marker-r' });
          const element: HTMLElement = vuexStore.get('viewer/images/activeDisplay@enabledHtmlElement');
          configureTextMarker(element, 'R');
        },
        textIcon: 'R'
      },
      { ...createCornerstoneTool('cobb-angle', 'Cobb Angle', 'CobbAngle'), icon: require('@/assets/viewer/cobb-angle.svg') },
      { id: 'toggle',
        label: 'Hide Annotations',
        action: () => {
          const enabledHtmlElement: HTMLElement = vuexStore.get('viewer/images/activeDisplay@enabledHtmlElement');
          if (!enabledHtmlElement) throw new Error('Enabled element not found');
          toggleMeasurementsVisibility(getGlobalToolState(), enabledHtmlElement);
        },
        icon: faEyeSlash },
      {
        id: 'clear',
        label: 'Delete Annotations',
        action: () => {
          const display: IDisplay = vuexStore.get('viewer/images/activeDisplay');
          clearMeasurements(getGlobalToolState(), display);
        },
        icon: faTrashAlt
      }
    ]
  ];
}
