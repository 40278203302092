





























import Vue from 'vue';
import { get, commit } from 'vuex-pathify';
import { IClinicModel } from '@/api/Models';
import { formatDateShort } from '@/helpers/date';
import eventBus from '@/eventBus';
import TableList from './TableList.vue';
import EditClinicModal from './EditClinicModal.vue';

export default Vue.extend({
  components: {
    TableList
  },
  data () {
    return {
      columns: [
        { label: 'ID', field: 'id', hidden: true },
        { label: 'Name', field: 'name' },
        { label: 'Address', field: 'address' },
        { label: 'Registration Date', field: 'createdOn' },
        { label: 'Phone', field: 'phone' },
        { label: 'Email', field: 'email' }
      ],
      selectedClinic: undefined as undefined | IClinicModel
    };
  },
  computed: {
    clinics: get<IClinicModel[]>('clinic/clinics')
  },
  methods: {
    createRow (clinic: IClinicModel): any {
      return { createdOn: formatDateShort(clinic.createdOn as Date) };
    },
    onCreateClick (): void {
      eventBus.launchModal(EditClinicModal);
    },
    onEditClick (): void {
      eventBus.launchModal(EditClinicModal, { clinic: this.selectedClinic });
    },
    onSelectClinicClick (): void {
      commit('clinic/setClinic', this.selectedClinic);
    }
  }
});
