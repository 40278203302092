





































































































































import Vue from 'vue';
import DatePicker from 'vuejs-datepicker';
import ModalFrame from '@/components/modal/ModalFrame.vue';
import ValidatedForm from '@/components/form/ValidatedForm.vue';
import FormRow from '@/components/form/FormRow.vue';

import { EntityStatus, Genders, IBreedModel, IPatientModel, Species } from '@/api/Models';
import objectMapper from '@/helpers/objectMapper';
import { normalizeSpaces } from '@/helpers/string';
import { get, dispatch } from 'vuex-pathify';
import eventBus, { ModalEventNames } from '@/eventBus';
import { formatDateVerbose } from '@/helpers/date';
import * as Sentry from '@sentry/vue';

interface IState {
  form: Partial<IPatientModel>;
  genders: typeof Genders;
  loading: boolean;
  errorMessage: string;
  resetOnComplete: boolean;
}

export default Vue.extend({
  components: {
    ModalFrame,
    ValidatedForm,
    DatePicker,
    FormRow
  },
  props: {
    buttonType: {
      type: String,
      required: true,
      validator: (value: string) => value === 'Edit' || value === 'Create'
    }
  },
  data (): IState {
    return {
      form: {
        patientId: undefined,
        name: undefined,
        speciesId: undefined,
        sex: undefined,
        accessionCode: undefined,
        birthDate: undefined,
        neutered: false,
        breedId: undefined,
        description: undefined
      },
      genders: Genders,
      loading: false,
      errorMessage: '',
      resetOnComplete: false
    };
  },
  computed: {
    owner: get('owner/owner'),
    patient: get('patient/patient'),
    breeds: get('breed/breeds'),
    filteredBreeds (): IBreedModel[] {
      return this.breeds?.filter((breed: IBreedModel) => breed.speciesId === this.form.speciesId);
    },
    species (): string[] {
      return Object.keys(Species);
    }
  },
  created () {
    this.setFormData();
  },
  methods: {
    focusModal () {
      const element = this.$el as HTMLDivElement;
      return element.focus();
    },
    formatDate: formatDateVerbose,
    setSpecies (species: Species): void {
      this.form.speciesId = species;
      this.form.breedId = undefined;
    },
    async onSaveClick (): Promise<void> {
      this.errorMessage = '';
      this.loading = true;
      this.resetOnComplete = false;

      const patientPayload: Partial<IPatientModel> = {
        patientId: this.form.patientId ? normalizeSpaces(this.form.patientId) : undefined,
        name: this.form.name ? normalizeSpaces(this.form.name) : undefined,
        speciesId: this.form.speciesId,
        sex: this.form.sex,
        accessionCode: this.form.accessionCode ? normalizeSpaces(this.form.accessionCode) : undefined,
        birthDate: this.form.birthDate,
        neutered: this.form.neutered,
        breedId: this.form.breedId,
        description: this.form.description
      };

      try {
        if (this.buttonType === 'Create') {
          patientPayload.createdOn = new Date();
          patientPayload.status = EntityStatus.Active;
          patientPayload.ownerId = this.owner.id;

          await dispatch('patient/postPatient', patientPayload);
        } else {
          const editedPatient = objectMapper(patientPayload, this.patient);

          await dispatch('patient/putPatient', editedPatient);
        }
      } catch (error) {
        Sentry.captureException(error);
        if (error.response.status === 409) {
          const { fields, entity } = error.response.data;
          this.errorMessage = `A ${entity} already exists with that ${fields[0]}`;
        } else {
          this.errorMessage = error?.response?.data?.message ? error.response.data.message : error.message;
        }
      } finally {
        this.loading = false;
        this.resetOnComplete = true;
      }

      if (!this.errorMessage) {
        eventBus.$emit(ModalEventNames.closeModal);
      }
    },
    setFormData (): void {
      if (this.buttonType === 'Edit' && this.patient) {
        this.form = objectMapper(this.patient, this.form);

        this.form.speciesId = this.patient.speciesId;
      } else {
        this.form.patientId = Date.now().toString();
        this.form.accessionCode = Date.now().toString();
      }
    }
  }
});
