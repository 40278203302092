
















import mixins from 'vue-typed-mixins';
import { AutoSuggestBase } from '../../../mixins/auto-suggest-base';
import { get } from 'vuex-pathify';

export default mixins(AutoSuggestBase).extend({
  computed: {
    views: get<string[]>('xRay/generalViews'),
    suggestions (): any {
      const filteredViews = this.views.filter(this.doesSuggestionIncludeInput);
      return [{ data: filteredViews }];
    }
  }
});
