/* eslint-disable @typescript-eslint/no-explicit-any */
import { importInternal, store } from 'cornerstone-tools';

const BaseAnnotationTool = importInternal('base/BaseAnnotationTool');
const BaseBrushTool = importInternal('base/BaseBrushTool');

const { state, getters } = store;

function getActiveTool (
  element: HTMLElement,
  buttons: any,
  interactionType = 'mouse'
): any {
  let tools;

  if (interactionType === 'touch') {
    tools = getActiveToolsForElement(element, getters.touchTools());
    tools = tools.filter((tool: any) => tool.options.isTouchActive);
  } else {
    // Filter out disabled, enabled, and passive
    tools = getActiveToolsForElement(element, getters.mouseTools());

    // Filter out tools that do not match mouseButtonMask
    tools = tools.filter(
      (tool: any) => Array.isArray(tool.options.mouseButtonMask) &&
        buttons &&
        tool.options.mouseButtonMask.includes(buttons) &&
        tool.options.isMouseActive
    );

    if (state.isMultiPartToolActive) {
      tools = filterToolsUseableWithMultiPartTools(tools);
    }
  }

  if (tools.length === 0) {
    return;
  }

  return tools[0];
}

/**
 * Filters an array of tools, returning only tools which are active.
 * @export
 * @public
 * @method
 * @name getActiveToolsForElement
 *
 * @param  {HTMLElement} element The element.
 * @param  {Object[]} tools      The input tool array.
 * @param  {string} handlerType  The input type being queried.
 * @returns {Object[]}            The filtered array.
 */
function getActiveToolsForElement (element: HTMLElement, tools: any[], handlerType?: string): any {
  return tools.filter(
    tool => tool.element === element &&
      tool.mode === 'active' &&
      (handlerType === undefined || tool.options[`is${handlerType}Active`])
  );
}

/**
 * Filters an array of tools, returning only tools which are active or passive.
 * @export
 * @public
 * @method
 * @name filterToolsUseableWithMultiPartTools
 *
 * @param  {Object[]} tools      The input tool array.
 * @returns {Object[]}            The filtered array.
 */
function filterToolsUseableWithMultiPartTools (tools: any[]): any {
  return tools.filter(
    tool => !tool.isMultiPartTool &&
      !(tool instanceof BaseAnnotationTool) &&
      !(tool instanceof BaseBrushTool)
  );
}

export default getActiveTool;
