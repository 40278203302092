import { IBreedModel } from '@/api/Models';
import { once } from 'lodash';
import { Module } from 'vuex';
import { dispatch, make } from 'vuex-pathify';
import { IRootState } from '..';
import { getById, getAll } from '../restfulState';

export interface IBreedState {
  breed?: IBreedModel;
  breeds?: IBreedModel[];
}

const _state: IBreedState = {
  breed: undefined,
  breeds: []
};

const breed: Module<IBreedState, IRootState> = {
  state: _state,
  mutations: {
    ...make.mutations(_state)
  },
  actions: {
    fetchBreed: getById('/breed', 'breed/setBreed'),
    fetchAllBreeds: getAll('/breed', 'breed/setBreeds'),
    initialize: once(async () => {
      await dispatch('breed/fetchAllBreeds');
    })
  },
  namespaced: true
};

export default breed;
