














































































































































































































































































































import Vue from 'vue';
import dayjs from 'dayjs';
import { IClinicModel, IBreedModel, IImageModel, Species, IPetOwnerModel, IRadCaseModel, IUserModel, IPatientModel, IStudieModel } from '@/api/Models';
import { get, dispatch } from 'vuex-pathify';
import SelectDoctor from '../SelectDoctor.vue';
import DatePicker from 'vuejs-datepicker';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import CreateCaseModalFrame from '@/components/modal/CreateCaseModalFrame.vue';
import FMSAxios from '@/api/FMSAxios';
import eventBus, { ModalEventNames } from '@/eventBus';
import FormRowLight from '@/components/form/FormRowLight.vue';
import { getAge } from '../../../../state/viewer/tools/helpers';
import { IDisplay } from '@/state/viewer/images/images-types';

const StudyTypes: string[] = [
  'Extremities - Shoulder (Lateral and Craniocaudal)',
  'Extremities - Shoulder for biceps tendonitis',
  'Extremities - Carpus',
  'Extremities - Elbow (4 view)',
  'Extremities - Elbow (DP and Lateral)',
  'Extremities - Femur',
  'Extremities - Foot',
  'Extremities - Humerus',
  'Extremities - Joint survey',
  'Extremities - Metacarpus',
  'Extremities - Metatarsus',
  'Extremities - Pelvis Routine',
  'Extremities - Pelvis routine and frogleg',
  'Extremities - Radius/ulna',
  'Extremities - Scapula',
  'Extremities - Shoulder of OCD',
  'Extremities - Skeletal Survey',
  'Extremities - Stifle',
  'Extremities - Tarsus',
  'Extremities - Tiba/Fibul',
  'Head - Mandible',
  'Head - Pharynx/larynx',
  'Head - Sinuses and nasal cavity',
  'Head - Skull routine',
  'Head - Soft tissue neck',
  'Head - TM joints',
  'Head - Tympanic bullae',
  'Spine - Caudal spine',
  'Spine - Cervical spine',
  'Spine - Lumbar spine',
  'Spine - Sacral spine',
  'Spine - Thoracic spine',
  'Spine - Vertebral column survey',
  'Thorax - Trachea (inspiratory and expiratory)',
  'Thorax - Routine',
  'Thorax - Three view'
];

interface IForm {
  institutionName: string;
  speciesId: string | undefined;
  breedId: number | undefined;
  patientId: number | undefined;
  birthDate: string | undefined;
  referringVeterinarianId: number;
  additionalNotes: string;
  weight: number;
  weightUnit: string;
  patientName: string;
  sex: string;
  ownerName: string;
  address: string;
  phone: string;
  clinicHistory: string;
  modality: string;
  priority: string;
}
interface ITags {
  PatientWeight?: number;
  [key: string]: any;
}
interface IFilePreview {
  name: string;
  src: Blob | string | ArrayBuffer | null; // Adjusted to include Blob
  file: File;
}
type CreateCaseModalInstance = Vue & {
  form: IForm;
  breeds: IBreedModel[];
  patientDetails: IPatientModel;
  studyDetails: IStudieModel;
  ownerDetails: IPetOwnerModel | null;
  userId: number;
  selectedTypes: string[];
  studyTypes: string[];
  filePreviews: IFilePreview[];
  selectedImageIds: number[];
  errorMessage: string;

};

export default Vue.extend({
  components: { CreateCaseModalFrame, SelectDoctor, DatePicker, FormRowLight },
  directives: {
    content (el, { value }) {
      if (el.innerHTML !== value) {
        el.innerHTML = value;
      }
    }
  },
  data () {
    return {
      selectedImageIds: [] as number[],
      errorMessage: '',
      faUserFriends,
      userId: 0,
      clinicId: 0,
      form: {
        additionalNotes: '',
        institutionName: '',
        speciesId: undefined,
        patientId: undefined,
        birthDate: undefined,
        ownerName: '',
        address: '',
        phone: '',
        clinicHistory: '',
        priority: '',
        modality: '',
        referringVeterinarianId: 0,
        weight: 0,
        weightUnit: 'Kg',
        patientName: '',
        sex: ''
      } as IForm,
      ownerDetails: null as IPetOwnerModel | null,
      studyTypes: StudyTypes,
      selectedTypes: [] as string[],
      filePreviews: [] as IFilePreview[]
    };
  },
  computed: {
    patientAge (this: CreateCaseModalInstance) {
      return getAge(this.patientDetails.birthDate);
    },
    formatStudyDate (this: CreateCaseModalInstance) {
      const date = this.studyDetails.createdOn;
      if (!date) return '';
      return dayjs(date).format('YY-MM-DD HH:mm:ss');
    },
    user: get < IUserModel | undefined >('user/user'),
    images: get < IImageModel[] >('study/images'),
    patientName: get('patient/patient@name'),
    patientDetails: get('patient/patient'),
    studyDate: get('study/selectedStudy@createdOn'),
    studyDetails: get('study/selectedStudy'),
    clinic: get < IClinicModel | undefined >('clinic/clinic'),
    breeds: get('breed/breeds'),
    getMetadata: get('viewer/metadata/imageMetadata'),
    activeDisplay: get < IDisplay >('viewer/images/activeDisplay'),
    filteredBreeds (this: CreateCaseModalInstance): IBreedModel[] {
      return this.breeds?.filter((breed: IBreedModel) => breed.speciesId === this.form.speciesId);
    },
    species (): string[] {
      return Object.keys(Species);
    },
    isDisabled (): boolean {
      return false;
    },
    availableTypes (this: CreateCaseModalInstance): string[] {
      return this.studyTypes.filter(type => !this.selectedTypes.includes(type));
    }
  },
  async mounted () {
    await this.fetchOwnerDetails(this.patientDetails.ownerId);
    this.form.patientName = this.patientName;
    this.form.sex = this.patientDetails.sex;
    this.form.speciesId = this.patientDetails.speciesId;
    this.form.ownerName = this.ownerDetails?.name ?? '';
    this.form.phone = this.ownerDetails?.phone ?? '';
    this.form.address = this.ownerDetails?.address ?? '';
    this.form.breedId = this.patientDetails.breedId;
    this.userId = this.user?.id ?? 0;
    this.clinicId = this.studyDetails.clinicId;
    const tags: ITags | undefined = this.getMetadata(this.activeDisplay.image?.imageId);
    const patientWeight = tags?.PatientWeight !== undefined ? tags.PatientWeight : '';
    if (patientWeight) {
      this.form.weight = patientWeight;
      this.form.weightUnit = 'Kg';
    }
  },

  methods: {
    setSpecies (this: CreateCaseModalInstance, species: Species): void {
      this.form.speciesId = species;
      this.form.breedId = undefined;
    },
    async fetchOwnerDetails (ownerId: number): Promise<void> {
      try {
        const response = await FMSAxios.get(`/owner/getOwner/${ownerId}`);
        this.ownerDetails = response.data;
      } catch (error) {
        console.error('Failed to fetch owner details', error);
      }
    },
    updateFilePreviews (previews: IFilePreview[]) {
      this.filePreviews = previews;
    },
    async onSend (this: CreateCaseModalInstance): Promise<void> {
      try {
        const formData = new FormData();
        if (!this.form.referringVeterinarianId) {
          this.errorMessage = 'Referring Veterinarian is required.';
          return;
        }
        if (!this.form.patientName) {
          this.errorMessage = 'Patient Name is required.';
          return;
        }
        if (!this.form.breedId) {
          this.errorMessage = 'Breed is required.';
          return;
        }
        if (!this.form.speciesId) {
          this.errorMessage = 'Species is required.';
          return;
        }

        if (!this.form.modality) {
          this.errorMessage = 'Modality is required.';
          return;
        }

        if (!this.patientDetails.birthDate) {
          this.errorMessage = 'birthDate is required.';
          return;
        }
        const createCaseRequest: IRadCaseModel = {
          status: 1,
          studyId: this.studyDetails?.id ?? 0,
          clinicId: this.studyDetails.clinicId,
          referringVeterinarianId: this.form.referringVeterinarianId,
          requesterId: this.userId,
          patientId: this.studyDetails.patientId,
          patientName: this.form.patientName,
          sex: this.form.sex === 'Male',
          neutered: false,
          breedId: this.form.breedId ?? 0,
          speciesId: this.form.speciesId ?? '',
          weight: this.form.weight,
          weightUnit: this.form.weightUnit,
          ownerId: this.patientDetails?.ownerId ?? 0,
          ownerName: this.form.ownerName,
          address: this.form.address,
          phone: this.form.phone,
          clinicHistory: this.form.clinicHistory,
          additionalNotes: this.form.additionalNotes,
          modality: this.form.modality,
          studyTypes: this.selectedTypes.join(', '),
          priority: this.form.priority,
          createdOn: new Date(),
          submitOn: new Date(),
          birthDate: this.patientDetails.birthDate,
          findings: '',
          internalMedicineRecommandations: '',
          interpretation: '',
          signature: '',
          notes: ''
        };
        formData.append('model', JSON.stringify(createCaseRequest));

        for (const filePreview of this.filePreviews) {
          // const blob = new Blob([filePreview.src as ArrayBuffer], { type: filePreview.file.type });
          let blob;
          if (filePreview.src instanceof ArrayBuffer) {
            blob = new Blob([filePreview.src], { type: filePreview.file.type });
          } else if (filePreview.src instanceof Blob) {
            blob = filePreview.src;
          } else if (typeof filePreview.src === 'string') {
            const response = await fetch(filePreview.src);
            const arrayBuffer = await response.arrayBuffer();
            blob = new Blob([arrayBuffer], { type: filePreview.file.type });
          } else {
            throw new Error('Unsupported file source type.');
          }
          console.log(`Sending file: ${filePreview.name}, Size: ${blob.size} bytes`);

          formData.append('files', blob, filePreview.name);
        }
        if (this.selectedImageIds) {
          formData.append('imageIds', this.selectedImageIds.join(','));
        }
        try {
          await dispatch('radcase/postCase', formData);
        } catch (error) {
          this.errorMessage = error;
        } finally {
          eventBus.$emit(ModalEventNames.closeModal);
        }
        this.$toasted.success('Case created successfully!');
      } catch (e) {
        console.log('AMMAR>>onSend:ERROR', e);
      }
    },

    Validate (): boolean {
      return true;
    },
    updateReferringVeterinarianId (doctorId: number) {
      this.form.referringVeterinarianId = doctorId;
    },
    toggleSelection (this: CreateCaseModalInstance, type: string, isSelectedFromAvailable: boolean) {
      if (isSelectedFromAvailable) {
        this.selectedTypes.push(type);
      } else {
        const index = this.selectedTypes.indexOf(type);
        if (index > -1) {
          this.selectedTypes.splice(index, 1);
        }
      }
    },
    isSelected (this: CreateCaseModalInstance, type: string): boolean {
      return this.selectedTypes.includes(type);
    },
    resetForm (this: CreateCaseModalInstance): void{
      this.form.address='';
    }
  }
});
