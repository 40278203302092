











import Vue from 'vue';
import { get } from 'vuex-pathify';
import { IClinicModel, IClinicProfilesModel } from '@/api/Models';
import TableList from './TableList.vue';

export default Vue.extend({
  components: {
    TableList
  },
  data () {
    return {
      columns: [
        { label: 'ID', field: 'id', hidden: true },
        { label: 'FirstName', field: 'firstName' },
        { label: 'LastName', field: 'lastName' },

      ],
      selectedClinic: undefined as undefined | IClinicModel
    };
  },
  computed: {
    clinicProfiles: get<IClinicProfilesModel[]>('clinic/clinicProfiles')
  },
  methods: {
    createRow (clinicProfile: IClinicProfilesModel): any {
      return { ...clinicProfile };
    },
    
  }
});
