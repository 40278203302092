import humanizeString from 'humanize-string';
import { IStudieModel, IImageModel, Modality, IAnnotation, SensorState } from '@/api/Models';
import { IXRayState } from './index';
import { CaptureStatus } from './enums';

export function createGeneralAnnotation (state: IXRayState, study: IStudieModel): IAnnotation | undefined {
  const { selectedPart, selectedView, selectedSize } = state;
  if (!selectedPart || !selectedView || !study) {
    return;
  }
  const annotation: IAnnotation = {
    bodyPartView: selectedView,
    animalSize: selectedSize,
    bodyPart: selectedPart
  };

  return annotation;
}

export function createDentalAnnotation (selectedPart: string, study: IStudieModel): IAnnotation | undefined {
  if (!selectedPart || !study) {
    return;
  }

  const annotation: IAnnotation = {
    bodyPart: selectedPart
  };
  return annotation;
}

export function doesGeneralAnnotationExist (annotations: IAnnotation[], annotation: IAnnotation): boolean {
  return annotations.some(existingAnnotation => existingAnnotation.bodyPartView === annotation.bodyPartView && existingAnnotation.bodyPart === annotation.bodyPart);
}

export function doesDentalAnnotationExist (annotations: IAnnotation[], annotation: IAnnotation): boolean {
  return annotations.some(existingAnnotation => existingAnnotation.bodyPart === annotation.bodyPart);
}

export function generalAnnotationToString (annotation: IAnnotation): string {
  const humanized = humanizeString(`${annotation.bodyPart} ${annotation.bodyPartView}`);
  // humanized string turns the view into lower case so we have to bring it back to uppercase
  const lastSpaceIndex = humanized.lastIndexOf(' ');
  const part = humanized.slice(0, lastSpaceIndex);
  const view = humanized.slice(lastSpaceIndex).toUpperCase();
  return `${part} ${view}`;
}

export function validateXRayModality (value: Modality): boolean {
  return Object.values(Modality).indexOf(value) !== -1;
}

export function imageHasAnnotation (image: IImageModel, annotation: IAnnotation): boolean {
  if (image.bodyPart && !image.bodyPartView) {
    return image.bodyPart === annotation.bodyPart;
  } else if (image.bodyPart && image.bodyPartView) {
    return image.bodyPart === annotation.bodyPart && image.bodyPartView === annotation.bodyPartView && image.animalSize === annotation.animalSize;
  }

  return false;
}

export function doesAnnotationExist (annotations: IAnnotation[], annotation: IAnnotation): boolean {
  if (!annotation.bodyPartView) {
    return doesDentalAnnotationExist(annotations, annotation);
  }
  return doesGeneralAnnotationExist(annotations, annotation);
}

export function generateProcessingButtonProperties (sensorState: SensorState): { buttonText: string; buttonClass: string } {
  let buttonText = '';
  let buttonIcon = '';
  let buttonClass = '';

  switch (sensorState) {
    case SensorState.RadiationReceived:
    case SensorState.LoadingImage:
      buttonText = CaptureStatus.CapturingImage;
      buttonIcon = '<div class="spinner-border text-primary mr-2" role="status"></div>';
      buttonClass = 'btn-warning';
      break;
    case SensorState.PostAcquisitionCleanup:
      buttonText = CaptureStatus.CleaningUp;
      buttonIcon = '<div class="spinner-border text-primary mr-2" role="status"></div>';
      buttonClass = 'btn-secondary';
      break;
    default:
      break;
  }

  const defaultClasses = 'btn btn-lg capture-modal-button';

  const buttonProperties = {
    buttonText: `${buttonIcon}<span>${buttonText}</span>`,
    buttonClass: `${defaultClasses} ${buttonClass}`
  };

  return buttonProperties;
}
