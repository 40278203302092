
















import Vue from 'vue';
import { Prop } from 'vue/types/options';
import ImageContainer from './ImageContainer.vue';
import { IReportImage, IReportPage } from '@/state/report';

export default Vue.extend({
  components: {
    ImageContainer
  },
  props: {
    page: {
      type: Object as Prop<IReportPage>,
      required: true
    },
    pageIndex: {
      type: Number,
      required: true
    },
    onImageClick: {
      type: Function,
      required: true
    },
    isThumbnailSize: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    images (): (IReportImage | undefined)[] {
      const numberOfImages = this.page.imageCount;

      const images = [];

      for (let index = 0; index < numberOfImages; index++) {
        const foundImage = this.page.images.filter(image => image.index === index);

        if (foundImage.length) images.push(foundImage[0]);
        else images.push(undefined);
      }

      return images;
    },
    imageContainerClass (): string {
      const imageCount = this.images.length;
      switch (imageCount) {
        case 0:
          return 'images-0';
        case 1:
          return 'images-1';
        case 2:
          return 'images-2';
        case 4:
          return 'images-4';
        default:
          throw new Error(`An image count of ${imageCount} is not allowed`);
      }
    }
  },
  methods: {
    generateImageClass (): string | undefined { // required to apply styles to PDF generation
      if (this.isThumbnailSize) return;

      return 'full-sized-image-' + this.pageIndex;
    }
  }
});
