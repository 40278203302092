/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  external,
  EVENTS,
  importInternal,
  addToolState,
  removeToolState
} from 'cornerstone-tools';

import createPerpendicularLineHandles from '../utils/createPerpendicularLineHandles';

const moveNewHandle = importInternal('manipulators/moveNewHandle');
const anyHandlesOutsideImage = importInternal(
  'manipulators/anyHandlesOutsideImage'
);

export default function addMeasurementPerpendicular (this: any, evt: any, interactionType: string): void {
  const eventData = evt.detail;
  const { element, image } = eventData;

  const config = this.configuration;

  if (validatePixelSpacing(image)) {
    return;
  }

  const measurementData = this.createNewMeasurement(eventData);

  function doneCallback (): void {
    measurementData.active = false;
    external.cornerstone.updateImage(element);
  }

  // Associate this data with this imageId so we can render it and manipulate it
  addToolState(element, this.name, measurementData);
  external.cornerstone.updateImage(element);

  const timestamp = new Date().getTime();
  const { end, perpendicularStart } = measurementData.handles;

  moveNewHandle(
    eventData,
    this.name,
    measurementData,
    end,
    {},
    interactionType,
    (success: any) => {
      if (!success) {
        removeToolState(element, this.name, measurementData);

        return;
      }
      const { handles } = measurementData;
      const hasHandlesOutside = anyHandlesOutsideImage(eventData, handles);
      const isTooFast = new Date().getTime() - timestamp < 150;

      if (hasHandlesOutside || isTooFast) {
        // Delete the measurement
        measurementData.cancelled = true;
        removeToolState(element, this.name, measurementData);
      } else {
        // Set lesionMeasurementData Session
        config.getMeasurementLocationCallback(
          measurementData,
          eventData,
          doneCallback
        );
      }

      // Update perpendicular line and disconnect it from the long-line
      createPerpendicularLineHandles(eventData, measurementData);
      perpendicularStart.locked = false;

      measurementData.invalidated = true;

      external.cornerstone.updateImage(element);

      const modifiedEventData = {
        toolName: this.name,
        toolType: this.name, // Deprecation notice: toolType will be replaced by toolName
        element,
        measurementData
      };

      external.cornerstone.triggerEvent(
        element,
        EVENTS.MEASUREMENT_MODIFIED,
        modifiedEventData
      );
      external.cornerstone.triggerEvent(
        element,
        EVENTS.MEASUREMENT_COMPLETED,
        modifiedEventData
      );
    }
  );
}

function validatePixelSpacing (image: any): boolean {
  const imagePlane = external.cornerstone.metaData.get(
    'imagePlaneModule',
    image.imageId
  );
  let rowPixelSpacing = image.rowPixelSpacing;
  let colPixelSpacing = image.columnPixelSpacing;

  if (imagePlane) {
    rowPixelSpacing =
      imagePlane.rowPixelSpacing || imagePlane.rowImagePixelSpacing;
    colPixelSpacing =
      imagePlane.columnPixelSpacing || imagePlane.colImagePixelSpacing;
  }

  // LT-29 Disable Target Measurements when pixel spacing is not available
  return !rowPixelSpacing || !colPixelSpacing;
}
